import { isEmpty } from 'lodash';
import { decode } from '../flexpolyline';

const getGeoJSONfromAreaPolylines = (polylines, areaIdentifiers) => {
  const coordinates = [];
  for (let i = 0; i < polylines.length; i++) {
    const temp = [];
    for (let j = 0; j < polylines[i].length; j++) {
      temp.push(polylines[i][j].reverse());
      if (j === polylines[i].length - 1) temp.push(polylines[i][0]);
    }
    coordinates.push(temp);
  }
  const allCoordinates = coordinates.flat(1);
  const bbox = [
    Math.min(...allCoordinates.map((stop) => stop[0])),
    Math.min(...allCoordinates.map((stop) => stop[1])),
    Math.max(...allCoordinates.map((stop) => stop[0])),
    Math.max(...allCoordinates.map((stop) => stop[1])),
  ];

  const areaFeatures = coordinates.map((coords, index) => ({
    type: 'Feature',
    bbox,
    geometry: {
      coordinates: [coords],
      type: 'Polygon',
    },
    properties: areaIdentifiers[index],
  }));
  return areaFeatures;
};

export const getAreaPolygonFormat = (areaDetails) => {
  if (isEmpty(areaDetails)) return null;

  const areaIdentifier = areaDetails.map((a) => a.properties);
  const polylines = areaDetails
    .map((a) => a.geometry.polygons[0])
    .map((p) => p.outer)
    .map((o) => decode(o.polyline))
    .map((po) => po.polyline);

  return { features: getGeoJSONfromAreaPolylines(polylines, areaIdentifier) };
};
