import { concat, filter, first, includes, isEmpty, last, size, some } from 'lodash';
import { getSafeValue, setSafeValue } from './security';

export const isBreakOrReload = (type) =>
  ['break', 'reload', 'drivingRestTime', 'workingRestTime'].includes(type);

export const isBreak = (type) => ['break', 'drivingRestTime', 'workingRestTime'].includes(type);

export const isReload = (type) => type === 'reload';

export const isDepartureOrArrival = (type) => ['departure', 'arrival'].includes(type);

const isValidIteration = (iteration) =>
  iteration.response && iteration.request && iteration.geoJSON;

export function getLastValidIteration(solution) {
  const valid =
    solution &&
    solution.iterations &&
    solution.iterations.filter((iteration) => isValidIteration(iteration));
  return valid && valid.length > 0 ? getSafeValue(valid, valid.length - 1) : null;
}

export function getSequenceNumbersOfJobs(plannedJobs = []) {
  const result = {};
  plannedJobs.forEach((job, index) => {
    setSafeValue(result, job.id, index);
  });
  return result;
}

export function getStopLocation(stop, stopsNoLocation, stopIndex, tourIndex) {
  if (
    !stop.location &&
    (stop.activities[0].type === 'drivingRestTime' || stop.activities[0].type === 'workingRestTime')
  ) {
    return stopsNoLocation[tourIndex][stopIndex];
  }
  return stop.location;
}

export function getToursWithLocations(tours, stopsNoLocation) {
  return tours.map((tour, tourIndex) => {
    const stops = tour.stops.map((stop, index) => {
      return { ...stop, location: getStopLocation(stop, stopsNoLocation, index, tourIndex) };
    });

    return { ...tour, stops };
  });
}

export function createStopsNoLocation(tours) {
  return tours.map((tour) =>
    tour.stops.map((stop, index, original) => {
      if (
        !stop.location &&
        (stop.activities[0].type === 'drivingRestTime' ||
          stop.activities[0].type === 'workingRestTime')
      ) {
        const lastLocation = last(
          original.slice(0, index).filter((el) => el.location !== undefined),
        );
        if (lastLocation) return { ...lastLocation.location };
        return { ...first(original.filter((el) => el.location !== undefined)).location };
      }
      return {};
    }),
  );
}

export function getToursDataFromIteration(iteration) {
  return iteration && iteration.response
    ? {
        ...iteration.response,
        ...iteration.request.json,
        problemUploaded: !!iteration.request.uploaded,
        timestamp: iteration.request.timestamp,
        tours: getToursWithLocations(iteration.response.tours, iteration.stopsNoLocation),
        jobNumbers: iteration.request.json
          ? getSequenceNumbersOfJobs(iteration.request.json.plan.jobs)
          : {},
      }
    : null;
}

export function getVehicleTypeById(typeId, fleet) {
  return !isEmpty(fleet) ? fleet.types.find((type) => type.id === typeId) : null;
}

export function getJobIdsForStops(stops) {
  return stops.reduce(
    (acc, stop) => acc.concat(stop.activities.map((activity) => `${activity.jobId}`)),
    [],
  );
}

export function getDemand({ tour, plan, orders }) {
  const stops = tour?.stops;

  if ((!stops || !plan) && !isEmpty(orders)) {
    return orders
      .filter((o) => !isBreakOrReload(o.Activity))
      .reduce((acc, curr) => {
        return acc + curr.Demand?.reduce((acc2, curr2) => acc2 + curr2) || 1;
      }, 0);
  }
  if (!stops || !plan) return null;

  const jobIds = getJobIdsForStops(stops);

  const totalDemand = [];
  const multiJobs = plan.jobs.filter((job) => job.demand === undefined);
  const justJobs = plan.jobs.filter((job) => job.demand !== undefined);

  justJobs.push(
    ...multiJobs.reduce((jobs, multiJob) => {
      const flatJobs = concat(multiJob.tasks.pickups, multiJob.tasks.deliveries)
        .filter((task) => !isEmpty(task))
        .map((task) => ({
          id: multiJob.id,
          tag: task.tag,
          demand: task.demand,
        }));

      return jobs.concat(flatJobs);
    }, []),
  );

  justJobs.forEach((job) => {
    if (includes(jobIds, `${job.id}`) && job.demand.length) {
      job.demand.forEach((demand, index) => {
        setSafeValue(totalDemand, index, getSafeValue(totalDemand, index) || 0);
        setSafeValue(totalDemand, index, getSafeValue(totalDemand, index) + demand);
      });
    }
  });

  return totalDemand;
}

export function getTourStart(vehicleId, tours) {
  const tour = tours.find((t) => t.vehicleId === vehicleId);
  if (!tour) {
    return undefined;
  }
  const departure = tour.stops.find((s) => s.activities.some((i) => i.jobId === 'departure'));
  return (departure || tour.stops[0]).time.departure;
}

export function getTourEnd(vehicleId, tours) {
  const tour = tours.find((t) => t.vehicleId === vehicleId);
  if (!tour) {
    return undefined;
  }
  const arrival = tour.stops.find((s) => s.activities.some((i) => i.jobId === 'arrival'));
  return (arrival && arrival.time.arrival) || last(tour.stops).time.departure;
}

export const getNumberOfDepotStops = (stops = []) =>
  size(
    filter(stops, ({ activities }) =>
      some(activities, ({ jobId }) => jobId === 'arrival' || jobId === 'departure'),
    ),
  );

export function getLoadPercentage(tourDemand, vehicleType) {
  if (!tourDemand || !vehicleType) return null;

  return vehicleType.capacity.map((item, index) => {
    return item === 0
      ? `${item}%`
      : `${(Math.min(getSafeValue(tourDemand, index) / item, 1) * 100).toFixed(0)}%`;
  });
}

export function getTotalJobsInStop(stop) {
  return stop.activities.filter((a) => includes(['delivery', 'pickup'], a.type)).length;
}

export function getTotalJobsInTour(stops) {
  return stops.reduce((total, stop) => {
    return total + getTotalJobsInStop(stop);
  }, 0);
}

export function getTotalJobsInTours(tours) {
  return tours && tours.reduce((total, tour) => total + getTotalJobsInTour(tour.stops), 0);
}

export function isDemandVisible(orders, tourPlanner) {
  if (isEmpty(orders)) return false;
  if (tourPlanner && !isEmpty(tourPlanner.lastDemandLabel)) return true;
  const totalDemand = orders.reduce((t, o) => t + o.Demand, 0);
  return totalDemand > 0 && size(orders) !== totalDemand;
}

export const isIterationSolution = (iteration) => {
  if (!iteration || isEmpty(iteration)) return false;
  return !!(iteration.response && iteration.response.statistic);
};

export const getNumberOfJobs = (jobs) =>
  jobs.reduce((acc, job) => {
    const deliveries = job.tasks.deliveries?.length ?? 0;
    const pickups = job.tasks.pickups?.length ?? 0;
    return acc + deliveries + pickups;
  }, 0);

export const hasOngoingAsyncRequest = (requests) =>
  requests.some((req) => req.statusHref && !req.href);
