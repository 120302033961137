import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { isNil, last } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import SimpleButton from 'components/Form/SimpleButton';
import withTranslation from 'hoc/withTranslation';
import { clearAll } from 'utils/apiFilesUploadHelpers';
import {
  cancelSolution,
  deleteSolution,
  getRoutingTourInfo,
  onSetShowSolution,
  selectTourById,
  setSolutionJson,
} from '../../../actions';
import trashCan from '../../../global/img/trash.svg';
import cancelIcon from '../../../global/img/cancel.svg';
import { tourToRoutingRequest } from '../../../utils/RoutingConverter';
import { getLastValidIteration, getToursDataFromIteration } from '../../../utils/SolutionHelpers';

export const RequestStatus = {
  PENDING: 'pending',
  IN_PROGRESS: 'inProgress',
  SUCCESS: 'success',
  CANCELED: 'canceled',
  CANCELING: 'canceling...',
  FAILURE: 'failure',
};

const StatusColors = {
  YELLOW: '#FCE83A',
  GREEN: '#56F000',
  RED: '#FF3838',
  ORANGE: '#FFA500',
};

const FileColors = {
  CSV: 'rgba(146, 161, 207, 0.2)',
  JSON: 'rgba(135, 206, 250, 0.2)',
  OTHER: 'rgba(0, 0, 0, 0.1)',
};

// TODO adjust for mobile
export const StyledTour = styled.div(({ isSelected, noHover, fileType, disabled }) => ({
  opacity: disabled && '70%',
  backgroundColor: isSelected
    ? 'rgba(0, 0, 0, 0.06)'
    : fileType === 'csv'
    ? FileColors.CSV
    : fileType === 'json'
    ? FileColors.JSON
    : FileColors.OTHER,
  padding: '0.5rem 0.5rem',
  fontWeight: isSelected && 420,
  display: 'flex',
  alignItems: 'center',
  cursor: (noHover || disabled) ?? 'pointer',
  width: '22rem',
  gap: '0.5rem',
  borderRadius: '0.25rem',
  '&:hover': {
    backgroundColor: noHover ?? 'rgba(0, 0, 0, 0.08)',
  },
}));

const StyledTourName = styled.div`
  display: flex;
  flex: 1 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

const StyledTourStatus = styled.div(({ color, disabled }) => ({
  display: 'flex',
  flex: '0 0 auto',
  backgroundColor: color,
  padding: '0.25rem',
  borderRadius: '2rem',
  minWidth: '5rem',
  justifyContent: 'center',
  color: 'white',
  cursor: !disabled && 'pointer',
}));

function getStatusColor(status) {
  switch (status) {
    case RequestStatus.PENDING:
    case RequestStatus.IN_PROGRESS:
      return StatusColors.YELLOW;
    case RequestStatus.SUCCESS:
      return StatusColors.GREEN;
    case RequestStatus.FAILURE:
    case RequestStatus.CANCELED:
      return StatusColors.RED;
    case RequestStatus.CANCELING:
      return StatusColors.ORANGE;
    default:
      return '';
  }
}

const Tour = ({
  request,
  isSelected,
  index,
  apiKey,
  tpaVersion,
  oAuth,
  isRememberLastState,
  isProdEnv,
  translations: {
    solutionsStatusPanel: { deleteSolutionTitle, cancelRequestTitle },
  },
}) => {
  const dispatch = useDispatch();
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[index],
  );
  const isProcessing =
    last(request.iterations).isProcessing || last(request.iterations).isProcessing === undefined;
  const isOngoingRequest = (request.statusHref && !request.href) || isProcessing;
  const hasError = request.error;
  const disabled = isOngoingRequest || hasError;

  const handleSetShowSolution = useCallback(
    (show) => dispatch(onSetShowSolution(show)),
    [dispatch],
  );
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const handleCancelSolution = useCallback(
    () =>
      dispatch(cancelSolution(tpaVersion, isProdEnv && apiKey, oAuth, request, index, solution)),
    [dispatch, request],
  );
  const handleDeleteSolution = useCallback((idx) => dispatch(deleteSolution(idx)), [dispatch]);
  const handleSelectTour = useCallback(() => dispatch(selectTourById(0)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest(
            { ...tour, routeId: 0, solutionId: index },
            tourPlanner,
          ),
        }),
      ),
    [dispatch, oAuth, tourPlanner, index],
  );

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation();

      const toEmptyLast = solution.show === index && solution.requests.length === index + 1;
      handleDeleteSolution({ index: [index], current: solution.show, toEmptyLast });
      if (solution.requests.length === 1) clearAll(dispatch, true, false, solution.show);
    },
    [solution, handleDeleteSolution, index, dispatch, solution],
  );

  const handleOnTourClick = useCallback(() => {
    if (isProcessing) {
      handleSetSolutionJson({ jsonUserChange: 'draft' });
    }
    if (!request.status || request.status === RequestStatus.SUCCESS) {
      handleSetShowSolution(index);
      handleSelectTour();

      const filtered = getLastValidIteration(solution.requests[index]);
      const tours = getToursDataFromIteration(filtered).tours;

      handleGetRoutingTourInfo(tours[0]);
    }
  }, [
    request,
    handleSetShowSolution,
    handleSelectTour,
    handleGetRoutingTourInfo,
    index,
    solution,
    isProcessing,
    handleSetSolutionJson,
  ]);

  const canDeleteSolution =
    isRememberLastState &&
    (request.status === RequestStatus.SUCCESS ||
      request.status === RequestStatus.CANCELED ||
      isNil(request.status));
  const canCancelSolution =
    request.status !== RequestStatus.SUCCESS &&
    request.status !== RequestStatus.CANCELED &&
    !isNil(request.status);

  return (
    <StyledTour
      isSelected={isSelected}
      onClick={handleOnTourClick}
      disabled={disabled}
      fileType={request.name?.split('.').pop()}
    >
      <StyledTourName title={request.name}>{request.name}</StyledTourName>
      {request.status && (
        <StyledTourStatus color={getStatusColor(request.status)} disabled={isOngoingRequest}>
          {request.status}
        </StyledTourStatus>
      )}
      <StyledWrapper>
        {canCancelSolution && (
          <SimpleButton
            title={cancelRequestTitle}
            onClick={handleCancelSolution}
            icon={cancelIcon}
            smaller
          />
        )}
        {canDeleteSolution && (
          <SimpleButton
            type="button"
            title={deleteSolutionTitle}
            onClick={handleDelete}
            icon={trashCan}
            smaller
          />
        )}
      </StyledWrapper>
    </StyledTour>
  );
};

export default withTranslation(Tour);
