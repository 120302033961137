import React, { useState, useCallback } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { clearLocalStorage } from 'utils/localStorageHelpers';
import { Swipeable } from '../Form/Swipeable';
import HereLogo from '../Presentation/HereLogo';
import { fontSize, colors } from '../../global/variables';
import { isMobileDevice, isProdEnv } from '../../utils/helpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../utils/amplitude';
import menu from '../../global/img/menu.svg';
import mapHandler from '../../global/img/map-handler.svg';
import chevronDown from '../../global/img/chevron-down.svg';
import chevronRight from '../../global/img/chevron-right.svg';
import clearIcon from '../../global/img/clear-icon.svg';

const { medium } = fontSize;
const { black } = colors;

const headerHeight = '3rem';

const isProd = isProdEnv();

const StyledTopBar = styled.div(() => ({
  display: 'flex',
  height: headerHeight,
  alignItems: 'center',
  justifyContent: 'flex-end',
  '@media screen and (max-width: 850px)': {
    flexDirection: 'row-reverse',
  },
}));

const StyledTopBarContainer = styled.div(({ showTransparent, isVisible }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: headerHeight,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: 'rgba(250, 250, 250, 0.1)',
  boxSizing: 'border-box',
  zIndex: '10000',
  paddingLeft: '2rem',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: '0',
  textAlign: 'left',
  transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  div: {
    lineHeight: headerHeight,
    verticalAlign: 'middle',
    transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  },
  '@media screen and (max-width: 850px)': {
    textAlign: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    backgroundColor: showTransparent ? 'transparent' : 'rgba(250, 250, 250, 1)',
    backdropFilter: 'blur(2px)',
    top: isVisible ? '0' : 'calc(100% - 2rem)',
    div: {
      position: 'absolute',
      top: !isVisible ? '-2rem' : '0.5rem',
      transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
    },
  },
}));

const StyledTopBarTitle = styled.div({
  marginLeft: '1.1rem',
  fontWeight: 'bold',
  color: black,
  opacity: '0.8',
  fontSize: medium,
  display: 'inline-block',
  '@media screen and (max-width: 850px)': {
    display: 'none',
  },
});

const StyledToggleButton = styled.button(({ icon }) => ({
  display: 'flex',
  top: '0.7rem',
  backgroundColor: 'transparent',
  backgroundImage: `url(${icon})`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  opacity: 1,
  width: '1.5rem',
  height: '1.5rem',
  cursor: 'pointer',
  zIndex: 10002,
  transition: 'all 0.1s ease-in-out',
  '@media screen and (max-width: 850px)': {
    marginLeft: '0.7rem',
  },
  '@media screen and (min-width: 851px)': {
    marginRight: '1.4rem',
  },
}));

const StyledBarHandle = styled.div(({ isVisible }) => ({
  width: '3rem',
  height: '1rem',
  marginTop: isVisible ? '-1.6rem' : '2.4rem',
  backgroundColor: '#f8f9f8',
  borderRadius: '0.2rem',
  transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  cursor: 'pointer',
  backgroundImage: `url(${mapHandler})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  '@media screen and (min-width: 851px)': {
    display: 'none',
    width: 0,
    height: 0,
  },
}));

const StyledToggleButtons = styled.div(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 'auto',
}));

const TopBar = ({
  onClickMenu,
  onToggleMobileView,
  isVisible,
  onClickTourPanel,
  isTourPanelOpen,
}) => {
  const [showMapMobile, setShowMapMobile] = useState(false);

  const onClickBar = useCallback(() => {
    if (!isMobileDevice()) return;

    const component = showMapMobile ? 'map' : 'panel';
    AmplitudeService.log(AMPLITUDE_EVENTS.TOP_BAR_CLICK, { component });
    onToggleMobileView();
    setShowMapMobile(!showMapMobile);
  }, [onToggleMobileView, setShowMapMobile, showMapMobile]);

  const onClickMenuButton = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      AmplitudeService.log(AMPLITUDE_EVENTS.TOP_BAR_CLICK, { component: 'Menu' });
      onClickMenu();
    },
    [onClickMenu],
  );

  return (
    <StyledTopBar>
      <Swipeable onSwipedUp={onClickBar} onSwipedDown={onClickBar}>
        <StyledTopBarContainer
          onClick={onClickBar}
          showTransparent={!showMapMobile}
          isVisible={isVisible}
        >
          <HereLogo />
          <StyledTopBarTitle>HERE Tour Planning</StyledTopBarTitle>
          <StyledBarHandle isVisible={!isVisible} />
          <StyledToggleButtons>
            {!isProd && (
              <StyledToggleButton
                type="button"
                onClick={clearLocalStorage}
                icon={clearIcon}
                title="clear local storage"
              />
            )}
            <StyledToggleButton
              type="button"
              onClick={onClickTourPanel}
              icon={isTourPanelOpen ? chevronDown : chevronRight}
            />
            <StyledToggleButton
              type="button"
              onClick={onClickMenuButton}
              icon={menu}
              data-testid="menuBtn"
            />
          </StyledToggleButtons>
        </StyledTopBarContainer>
      </Swipeable>
    </StyledTopBar>
  );
};

export default withTranslation(TopBar);
