import { getSafeValue } from '../utils/security';

export const tourColors = [
  {
    delivery: { inner: '#2e8b57', outer: '#15723E', text: '#ffffff' },
    pickup: { inner: '#7BD8A4', outer: '#61BE8A', text: '#000000' },
  },
  {
    delivery: { inner: '#000080', outer: '#000067', text: '#ffffff' },
    pickup: { inner: '#4D4DCD', outer: '#3333B3', text: '#ffffff' },
  },
  {
    delivery: { inner: '#9acd32', outer: '#81B419', text: '#ffffff' },
    pickup: { inner: '#E7FF7F', outer: '#CDFF65', text: '#000000' },
  },
  {
    delivery: { inner: '#ff8c00', outer: '#E67300', text: '#ffffff' },
    pickup: { inner: '#FFD94D', outer: '#FFBF33', text: '#000000' },
  },
  {
    delivery: { inner: '#ba55d3', outer: '#A13CBA', text: '#ffffff' },
    pickup: { inner: '#FFA2FF', outer: '#ED88FF', text: '#000000' },
  },
  {
    delivery: { inner: '#00fa9a', outer: '#00E181', text: '#ffffff' },
    pickup: { inner: '#4DFFE7', outer: '#33FFCD', text: '#000000' },
  },
  {
    delivery: { inner: '#0000ff', outer: '#0000E6', text: '#ffffff' },
    pickup: { inner: '#4D4DFF', outer: '#3333FF', text: '#ffffff' },
  },
  {
    delivery: { inner: '#1e90ff', outer: '#0577E6', text: '#ffffff' },
    pickup: { inner: '#6BDDFF', outer: '#51C3FF', text: '#000000' },
  },
  {
    delivery: { inner: '#eee8aa', outer: '#D5CF91', text: '#000000' },
    pickup: { inner: '#FFFFDD', outer: '#FFFFC4', text: '#000000' },
  },
  {
    delivery: { inner: '#dda0dd', outer: '#C487C4', text: '#ffffff' },
    pickup: { inner: '#FFEDFF', outer: '#FFD3FF', text: '#000000' },
  },
  {
    delivery: { inner: '#ff1493', outer: '#E6007A', text: '#ffffff' },
    pickup: { inner: '#FF61E0', outer: '#FF47C6', text: '#000000' },
  },
  {
    delivery: { inner: '#ffa07a', outer: '#E68761', text: '#ffffff' },
    pickup: { inner: '#FFEDC7', outer: '#FFD3AD', text: '#000000' },
  },
  {
    delivery: { inner: '#87cefa', outer: '#6EB5E1', text: '#ffffff' },
    pickup: { inner: '#D4FFFF', outer: '#BAFFFF', text: '#000000' },
  },
];

export const colors = {
  primaryColor: '#22CDC8',
  primaryColorHovered: '#2BDACD',
  secondaryColor: '#80B2FC',
  primaryBorderColor: 'rgba(15, 22, 33, .2)',
  secondaryBorderColor: 'rgba(15, 22, 33, .05)',
  placeholderTextColor: 'rgba(15, 22, 33, .4)',
  primaryTextColor: 'rgba(15, 22, 33, .5)',
  secondaryTextColor: 'rgba(15, 22, 33, .8)',
  thirdTextColor: 'rgba(15, 22, 33, .8)',
  primaryTextColorDarkBg: '#F1F5FF',
  secondaryTextColorDarkBg: 'rgba(227,236,254,0.7)',
  secondaryTextColorDarkBgEnabled: 'rgba(227,236,254,1)',
  secondaryTextColorDarkBgDisabled: 'rgba(227,236,254,0.2)',
  deletingTextColor: '#FF6666',
  negativeTextColor: '#FF6666',
  primaryMarkerColor: '#6076B6',
  primaryMarkerColorStop: '#7C72F3',
  secondaryMarkerColor: 'rgba(68, 68, 153, 0.9)',
  clusterColor: 'rgba(68, 68, 153, 0.0)',
  unusedMarkerFill: 'rgba(140, 140, 140, 0.95)',
  unusedMarkerStroke: 'rgba(76, 76, 76, .5)',
  transparentWhiteColor: 'rgba(256, 256, 256, 0.9)',
  transparentGreyColor: 'rgba(0,0,0,0.02)',
  transparentYellowColor: 'rgba(245, 190, 69, 0.3)',
  transparentBlackColor: 'rgba(15, 22, 33, .5)',
  transparentDarkerColor: 'rgba(15, 22, 33, .75)',
  darkBackground: 'rgb(27, 34, 44, 0.9)',
  darkSolidBackground: '#394049',
  darkerSolidBackground: '#2F353C',
  disabledBackground: 'rgba(200, 200, 200, .3)',

  black: '#0F1621',
  white: '#FFFFFF',
  yellow: '#F5BE45',
  aqua: '#22CDC8',
  animations: ['#80b2fc', '#7fc1f6', '#7ecff0', '#7ddbea', '#7ce4e2'],
  clusters: ['rgba(68, 68, 153, 0)', 'rgba(68, 68, 153, 0)', 'rgba(68, 68, 153, 0)'],
  switchTextColor: '#bee8f5',
  errorBackgroundColor: 'rgba(255, 0, 0, 0.9)',
  loaderBackgroundColor: '#7ce4e2',
  avoidArea: '#FF0000',
  areas: [
    '#22CDC8',
    '#6C838E',
    '#29ABE2',
    '#80AAFF',
    '#7560AA',
    '#AC2077',
    '#2D5584',
    '#7DE6E1',
    '#EA0B8C',
    '#9DACB9',
    '#831C4A',
    '#08A8A4',
    '#333D47',
    '#33D9C1',
  ],
};

export const fontSize = {
  big: '1.2rem',
  normal: '1rem',
  medium: '0.9rem',
  small: '0.8rem',
  smallish: '0.75rem',
  smaller: '0.7rem',
  mini: '0.5rem',
};

export const USE_ALTERNATIVE_THEME = false;
export const currentTheme = 'xmas';
export const themeVars = {
  normal: {
    mapTourLineColor: 'rgba(68, 68, 153, 0.9)',
    animationsColor: ['#80b2fc', '#7fc1f6', '#7ecff0', '#7ddbea', '#7ce4e2'],
    animationsWeight: 2,
  },
  xmas: {
    mapTourLineColor: '#A93232',
    animationsColor: ['#ED4E4E', '#D9F8FF', '#ED4E4E', '#D9F8FF', '#ED4E4E', '#D9F8FF'],
    animationsWeight: 2,
  },
};

export const getThemeProp = (prop) => {
  const toReturn = USE_ALTERNATIVE_THEME ? currentTheme : 'normal';
  return getSafeValue(getSafeValue(themeVars, toReturn), prop);
};

export const OCEAN_THEME = {
  scheme: 'Ocean',
  author: 'Chris Kempson (http://chriskempson.com)',
  base00: '#2b303b',
  base01: '#343d46',
  base02: '#4f5b66',
  base03: '#65737e',
  base04: '#a7adba',
  base05: '#c0c5ce',
  base06: '#dfe1e8',
  base07: '#eff1f5',
  base08: '#bf616a',
  base09: '#d08770',
  base0A: '#ebcb8b',
  base0B: '#a3be8c',
  base0C: '#96b5b4',
  base0D: '#8fa1b3',
  base0E: '#b48ead',
  base0F: '#ab7967',
};
