import { first, last, size } from 'lodash';
import { decode } from './flexpolyline';
import { FLEET_PROFILES } from '../actions';
import { AVOID_TYPES } from './territories/TerritoriesHelpers';

export const getGeoJSONfromRoutingResponse = (responses, tourId) => {
  const features = [];
  responses.forEach((response) => {
    response.polylines.forEach((polyline, index) => {
      const coordinates = polyline.map((coord) => coord.reverse());

      const bbox = coordinates.reduce(
        ([minX, minY, maxX, maxY], [x, y]) => [
          Math.min(minX, x),
          Math.min(minY, y),
          Math.max(maxX, x),
          Math.max(maxY, y),
        ],
        [180, 90, -180, -90],
      );

      features.push({
        bbox,
        geometry: {
          coordinates,
          type: 'LineString',
        },
        type: 'Feature',
        properties: {
          routeId: parseInt(tourId, 10),
          vehicle: {},
          isRouting: true,
          mode: response.mode,
          isStopsRequest: response.stopsRequest,
          fromStop: index,
          totalStops: response.polylines.length,
        },
      });
    });
  });

  return features;
};

export const getAvoidAreasFormatted = (areas) => {
  return areas
    .map((area) => {
      if (area.type === AVOID_TYPES.BOUNDING_BOX)
        return `bbox:${area.east},${area.south},${area.west},${area.north}`;

      if (area.type === AVOID_TYPES.POLYGON)
        return `polygon:${area.outer.map(({ lat, lng }) => `${lat},${lng}`).join(';')}`;

      if (area.type === AVOID_TYPES.ENCODED_POLYGON) return `polygon:${area.outer}`;
      return '';
    })
    .join('|');
};

export const getStopDuration = (departure, arrival) => {
  const departureDate = new Date(departure);
  const arrivalDate = new Date(arrival);
  return (departureDate - arrivalDate) / 1000;
};

export const isSameLocation = (locationA, locationB) =>
  locationA.lat === locationB.lat && locationA.lng === locationB.lng;

export const getAllVias = (stop) => {
  return stop.activities.reduce((activityAcc, activity) => {
    const location = activity.location || stop.location;
    if (isSameLocation(stop.location, location) && !activity.time) return activityAcc;

    activityAcc.push({
      location,
      duration: activity.time ? getStopDuration(activity.time.end, activity.time.start) : undefined,
    });

    return activityAcc;
  }, []);
};

export const getActivityRoutingRequest = (request, stop) => {
  const vias = getAllVias(stop).map((via) => {
    const stopDuration = via.duration ? `!stopDuration=${via.duration}` : '';
    return `${via.location.lat},${via.location.lng}${stopDuration}`;
  });

  return {
    ...request,
    departureTime: stop.time.arrival,
    origin: `${stop.location.lat},${stop.location.lng}`,
    destination: `${stop.location.lat},${stop.location.lng}`,
    transportMode: 'pedestrian',
    ...(vias && vias.length > 0 && { via: vias.join('&via=') }),
  };
};

export const createRoutingRequests = (request, vehicleOptions, tour, mode) => {
  const requests = [];
  const polylines = [];
  const stopsWithPolylines = tour.stops.filter((stop) => stop.routeDetails?.polyline);

  if (stopsWithPolylines.length > 0) {
    polylines.push(
      ...stopsWithPolylines
        .map((stop) => decode(stop.routeDetails.polyline))
        .map((r) => r.polyline),
    );
  } else {
    const stopsRequest = {
      ...request,
      ...vehicleOptions,
      departureTime: `${first(tour.stops).time.departure}`,
      origin: `${first(tour.stops).location.lat},${first(tour.stops).location.lng}`,
      destination: `${last(tour.stops).location.lat},${last(tour.stops).location.lng}`,
      transportMode: mode,
    };

    if (size(tour.stops) > 2) {
      stopsRequest.via = tour.stops
        .slice(1, tour.stops.length - 1)
        .map(
          (stop) =>
            `${stop.location.lat},${stop.location.lng}!stopDuration=${getStopDuration(
              stop.time.departure,
              stop.time.arrival,
            )}`,
        )
        .join('&via=');
    }

    requests.push({ request: stopsRequest, stopsRequest: true });
  }

  tour.stops.forEach((stop) =>
    requests.push({ request: getActivityRoutingRequest(request, stop) }),
  );

  return { requests, stopPolylines: { polylines, mode } };
};

export const tourToRoutingRequest = (tour, tourPlanner) => {
  const vehicle = tourPlanner.vehicles.find((vehicleToFind) => vehicleToFind.id === tour.typeId);
  const profileName =
    vehicle?.id === tour.typeId ? vehicle.profile : tourPlanner.vehicleProfiles[0].name;
  const vehicleProfile = tourPlanner.vehicleProfiles.find(
    (profile) => profile.name === profileName,
  );
  const mode = tourPlanner && vehicleProfile.fleetType;
  const avoid = vehicleProfile.avoid;
  const options = vehicleProfile.options;
  const exclude = vehicleProfile.exclude;
  const traffic = tourPlanner.traffic;

  const avoidOptions = avoid && {
    ...(avoid.segments && { 'avoid[segments]': `${avoid.segments.join()}` }),
    ...(avoid.zoneIdentifiers && { 'avoid[zoneIdentifiers]': `${avoid.zoneIdentifiers.join()}` }),
    ...(avoid.areas && { 'avoid[areas]': getAvoidAreasFormatted(avoid.areas) }),
    ...(avoid.zoneCategories && {
      'avoid[zoneCategories]': `${avoid.zoneCategories.categories.join()};exceptZoneIds=${avoid.zoneCategories.exceptZoneIds.join()}`,
    }),
  };

  const excludeOptions = exclude && {
    ...{
      'exclude[countries]': `${exclude.countries.join()}`,
    },
  };

  const vehicleOptions = options && {
    ...(FLEET_PROFILES.SCOOTER &&
      options.allowHighway && { 'scooter[allowHighway]': `${options.allowHighway}` }),
    ...(FLEET_PROFILES.TRUCK &&
      options.shippedHazardousGoods && {
        'vehicle[shippedHazardousGoods]': `${options.shippedHazardousGoods.join()}`,
      }),
    ...(FLEET_PROFILES.TRUCK &&
      options.grossWeight && { 'vehicle[grossWeight]': `${options.grossWeight}` }),
    ...(FLEET_PROFILES.TRUCK &&
      options.weightPerAxle && { 'vehicle[weightPerAxle]': `${options.weightPerAxle}` }),
    ...(FLEET_PROFILES.TRUCK && options.height && { 'vehicle[height]': `${options.height}` }),
    ...(FLEET_PROFILES.TRUCK && options.width && { 'vehicle[width]': `${options.width}` }),
    ...(FLEET_PROFILES.TRUCK && options.length && { 'vehicle[length]': `${options.length}` }),
    ...(FLEET_PROFILES.TRUCK &&
      options.tunnelCategory && { 'vehicle[tunnelCategory]': `${options.tunnelCategory}` }),
  };

  const request = {
    return: 'polyline',
    tourId: tour.routeId,
    solutionId: tour.solutionId,
    ...avoidOptions,
    ...excludeOptions,
    ...(traffic && { traffic }),
  };

  return createRoutingRequests(request, vehicleOptions, tour, mode);
};

export const createFeaturesFromRoutingResponseLocations = (sections, tourId) => {
  return sections.map((section, index) => {
    const place = section.arrival.place;
    return {
      geometry: {
        coordinates: [
          [place.location.lng, place.location.lat],
          place.originalLocation
            ? [place.originalLocation.lng, place.originalLocation.lat]
            : undefined,
        ],
        type: 'LineString',
      },
      type: 'Feature',
      properties: {
        isOriginalLocationLine: true,
        routeId: tourId,
        fromStop: index,
        toStop: index + 1,
      },
    };
  });
};

export const RoutingResponseToSolverFormat = ({
  payload: { routingResponse, stopPolylines, tourId, solutionId },
}) => {
  const responses = [];
  if (stopPolylines) responses.push({ ...stopPolylines });

  const originalLocation = [];
  let polylineCount = 0;

  if (routingResponse && routingResponse[0] && routingResponse[0].response) {
    responses.push(
      ...routingResponse.map(({ response, stopsRequest }) => {
        if (!response || !response.routes || !response.routes[0]) return null;
        const sections = response.routes[0].sections || [];
        const mode = sections[0].transport.mode;

        originalLocation.push(...createFeaturesFromRoutingResponseLocations(sections, tourId));

        const polylines = sections.map((s) => decode(s.polyline)).map((r) => r.polyline);

        polylineCount += polylines.flat(1).length;

        return { polylines, mode, stopsRequest };
      }),
    );
  }

  if (responses.length === 0 || responses.every((response) => response === null)) return null;

  return {
    geo: getGeoJSONfromRoutingResponse(responses, tourId),
    originalLocation,
    tourId,
    solutionId,
    polylineCount,
  };
};
