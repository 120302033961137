import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Modal from '../../Presentation/Modal';
import { setUserParam } from '../../../actions';
import withTranslation from '../../../hoc/withTranslation';
import {
  StyledContrastPanel,
  StyledContrastPanelColumn,
  StyledContrastPanelColumnsContainer,
  StyledContrastPanelFooter,
  StyledContrastPanelHeader,
} from '../../Wizard/Global/WizardStyledContrastPanel';
import { StyledInfoEllipsis, StyledText } from '../../Wizard/Global/WizardStyled';
import Section from '../../Form/Section';
import { StyledAccessContainer, StyledAction } from '../StyledComponents';
import mapSettings from '../../../global/img/map-settings.svg';
import { getSafeValue } from '../../../utils/security';
import Checkbox from '../../Form/Checkbox';
import { PANELS } from '../../../reducers/user';
import Button from '../../Form/Button';

const MAP_SETTINGS = {
  SHOW_STOPS: 'showStops',
  SHOW_CLUSTERS: 'showClusters',
  GRADIENT_ROUTES: 'gradientRoutes',
  SHOW_STRAIGHT_ROUTE_LINES: 'showStraightRouteLines',
  SHOW_TOUR_DIRECTION_ANIMATION: 'showTourDirectionAnimation',
};

const StyledContainer = styled.div({
  padding: '1rem',
});

const MapSettingsPanel = ({ user, translations: { mapSettingsPanel, solverConfigLabels } }) => {
  const dispatch = useDispatch();
  const [showPanel, setShowPanel] = useState(false);
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);
  const settingsArray = Object.entries(MAP_SETTINGS).map(([key, value]) => ({ key, value }));
  const [mapSettingsObj, setMapSettingsObj] = useState({ ...user.mapSettings });

  const togglePanel = useCallback(() => {
    handleSetUserParam({ openPanel: showPanel ? undefined : PANELS.MAP_SETTINGS });
    setShowPanel(!showPanel);
  }, [showPanel, handleSetUserParam]);

  const handleOnSwitchChange = useCallback(
    (id, value) => {
      setMapSettingsObj((prev) => ({ ...prev, [id]: value }));
    },
    [setMapSettingsObj],
  );

  const handleOnSubmit = useCallback(() => {
    handleSetUserParam({ mapSettings: mapSettingsObj });
  }, [handleSetUserParam, mapSettingsObj]);

  useEffect(() => {
    setShowPanel(user.openPanel === PANELS.MAP_SETTINGS);
  }, [user.openPanel, setShowPanel]);

  return (
    <>
      <Modal isDark isVisible={showPanel} isFull fromTop>
        <StyledContrastPanel fullDisplay>
          <StyledContrastPanelHeader size="0">
            <StyledText bold contrast>
              {mapSettingsPanel.mapSettingsTitle}
            </StyledText>
          </StyledContrastPanelHeader>
          <StyledContainer>
            <Section>
              <StyledContrastPanelColumnsContainer>
                {settingsArray.map((setting) => (
                  <StyledContrastPanelColumn
                    id={setting.value}
                    key={setting.value}
                    actionsCount={2}
                    inputSize="XS"
                  >
                    <StyledInfoEllipsis
                      alt={getSafeValue(mapSettingsPanel, setting.value)}
                      title={getSafeValue(mapSettingsPanel, setting.value)}
                      hasMarginTop
                    >
                      {getSafeValue(mapSettingsPanel, setting.value)}
                    </StyledInfoEllipsis>
                    <StyledAction>
                      <Checkbox
                        id={`mapSettingsCheckbox-${setting.value}`}
                        value={getSafeValue(mapSettingsObj, setting.value)}
                        contrast
                        onChange={(value) => handleOnSwitchChange(setting.value, value)}
                      />
                    </StyledAction>
                  </StyledContrastPanelColumn>
                ))}
              </StyledContrastPanelColumnsContainer>
            </Section>
          </StyledContainer>

          <StyledContrastPanelFooter isDouble isFullWidth>
            <Button
              text={solverConfigLabels.buttonCancelLabel}
              inverted
              contrast
              onClick={() => togglePanel()}
            />
            <div />
            <Button text={solverConfigLabels.buttonSubmitLabel} onClick={handleOnSubmit} />
          </StyledContrastPanelFooter>
        </StyledContrastPanel>
      </Modal>
      <StyledAccessContainer
        data-testid="mapSettingsBtn"
        id="map-settings-entry-point"
        onClick={() => togglePanel()}
        order={1}
        icon={mapSettings}
        title={mapSettingsPanel.mapSettingsTitle}
      />
    </>
  );
};

export default withTranslation(MapSettingsPanel);
