import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, keys, reduce, size } from 'lodash';
import { canDownloadCSV, getAsset } from 'utils/helpers';
import { getLastValidIteration, getTotalJobsInTours } from 'utils/SolutionHelpers';
import { convertCostsFromTourPlanner } from 'utils/cost';
import jobIcon from 'global/img/mini-order.svg';
import unassignedJobIcon from 'global/img/mini-order-unassigned.svg';
import Popup from 'components/Popup/Popup';
import { StyledButtonSmall } from '../Global/WizardStyled';
import { saveUnassignedToCSVFile } from '../../../utils/SolutionExportToCsv';
import downloadIcon from '../../../global/img/download.svg';
import viewIcon from '../../../global/img/details.svg';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { increaseUserUsage } from '../../../actions';
import { exportToursToCSV } from '../../../utils/SolutionExport';
import { getUrlInitialTourIndex } from '../../../utils/urlHelpers';
import { getFleetTypeIcon } from '../../../utils/FleetHelpers';
import { getSafeValue, setSafeValue } from '../../../utils/security';
import { formatPriceWithCurrency } from '../../../utils/formatter';

const { small, normal } = fontSize;
const initialTourIndex = getUrlInitialTourIndex();

const StyledSummary = styled.div`
  margin-bottom: 0.5rem;
`;

const StyledGroup = styled.div(({ gridValue }) => ({
  display: 'grid',
  gridTemplateColumns: gridValue,
  justifyContent: 'space-between',
}));

const StyledTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin: 0 0.6rem 0 0;
  font-size: ${small};
  opacity: 0.75;
  display: inline-block;
`;

const StyledText = styled.strong`
  font-size: ${normal};
  font-weight: ${(props) => props.normal && '400'};
  line-height: 2rem;
  display: block;
  border-bottom: ${(props) => (props.borderBottom ? '0.06rem dotted black' : 'none')};
  margin-bottom: ${(props) => (props.borderBottom ? '0.6rem' : 'auto')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledSpan = styled.span`
  font-size: ${small};
  cursor: pointer;
`;
const StyledVehicleIconSize = '1.25rem';

const jobIconStyle = css`
  background-image: url(${getAsset(jobIcon)});
  width: 14px;
  height: 14px;
  background-size: 12px;
  cursor: pointer;
`;

const unassignedJobIconStyle = css`
  background-image: url(${getAsset(unassignedJobIcon)});
  width: 14px;
  height: 14px;
  background-size: 12px;
  cursor: pointer;
`;

const StyledIcon = styled.div(
  {
    width: '.9rem',
    height: '.9rem',
    backgroundSize: '.8rem .8rem',
    display: 'inline-block',
    backgroundRepeat: 'no-repeat',
    marginRight: '0',
  },
  ({ icon }) => ({
    ...icon,
  }),
);

const StyledDetailContainer = styled.div(({ noMarginBottom }) => ({
  marginRight: '1rem',
  marginTop: '-0.8rem',
  marginBottom: !noMarginBottom && '2rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const StyledDownloadContainer = styled.div(({ noMarginTop }) => ({
  marginTop: !noMarginTop && '0.8rem',
}));

const StyledReview = styled(StyledButtonSmall)`
  margin-right: 7px;
  text-transform: uppercase;
`;

const DownloadIconStyle = css`
  background-image: url(${getAsset(downloadIcon)});
  width: 1rem !important;
  height: 1rem !important;
  background-size: 0.85rem !important;
  background-position-y: 0px;
  float: right;
  margin-left: 11px;
`;

const ViewIconStyle = css`
  background-image: url(${getAsset(viewIcon)});
  width: 1rem !important;
  height: 1rem !important;
  background-size: 1.05rem !important;
  background-position-y: 1px;
  float: right;
  margin-left: 11px;
`;

const SummaryWithDownload = ({
  orders,
  tourPlanner,
  user,
  solution,
  lastIterationTourData: { fleet, tours, unassigned, uploaded, statistic, plan },
  onChangeView,
  isToursView,
  helpers: { formatDuration },
  translations: {
    tourSolution: {
      costTrans,
      distanceTrans,
      durationTrans,
      jobTrans,
      vehicleTypes,
      preposition,
      clickForInfo,
      units: {
        distance: { kilo, mile },
      },
    },
    wizard: {
      download,
      tours: { unassignedDownloadTitle, unassignedView, toursView },
    },
  },
}) => {
  const isImperial = user.distance === 'imperial';
  const formattedDuration = statistic.duration
    ? formatDuration(Math.floor(statistic.duration / 60))
    : 'Unknown';
  const totalDetails = { time: formattedDuration, cost: statistic.cost };

  const dispatch = useDispatch();
  const handleIncreaseUsage = useCallback((data) => dispatch(increaseUserUsage(data)), [dispatch]);
  const [numberOfVehicles, setNumberOfVehicles] = useState(0);
  const [totalNumberOfVehicles, setTotalNumberOfVehicles] = useState(0);
  const [numberOfUnassigned, setNumberOfUnassigned] = useState(0);
  const [numberOfAssigned, setNumberOfAssigned] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [totalCost] = useState(totalDetails.cost);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalTime] = useState(totalDetails.time);

  const showTotalVehicles = isEmpty(fleet);

  const request = solution && getLastValidIteration(solution.requests[0]).request;
  const typeCount = Object.entries(
    tours.reduce((acc, cur) => ({ ...acc, [cur.typeId]: (acc[cur.typeId] || 0) + 1 }), {}),
  ).map(([name, count]) => ({ name, count }));

  const vehicleTypeCount = tourPlanner.vehicleProfiles
    .flatMap((vp) =>
      tourPlanner.vehicles.flatMap((v) =>
        typeCount.map((v1) => {
          return {
            name: v1.name,
            count: v1.count,
            amount: v.id === v1.name ? v.amount : 0,
            fleetType: vp.name === v.profile ? vp.fleetType : '',
          };
        }),
      ),
    )
    .filter((f) => f.amount && f.fleetType);

  useEffect(() => {
    const vehicles = size(
      keys(
        reduce(
          tours,
          (acc, { vehicleId }) => {
            setSafeValue(acc, vehicleId, true);
            return acc;
          },
          {},
        ),
      ),
    );

    const definedVehicles =
      uploaded || isEmpty(fleet) ? 0 : fleet.types.reduce((total, type) => total + type.amount, 0);
    const totalVehicles = showTotalVehicles ? vehicles : definedVehicles;
    const unassignedJobs = size(keys(unassigned));

    setNumberOfVehicles(vehicles);
    setNumberOfUnassigned(unassignedJobs);
    const assignedJobs = isEmpty(fleet) ? plan.jobs.length : plan.jobs.length - unassignedJobs;
    setNumberOfAssigned(assignedJobs);
    setTotalNumberOfVehicles(totalVehicles);
  }, [
    setNumberOfAssigned,
    setNumberOfUnassigned,
    tours,
    unassigned,
    fleet,
    uploaded,
    tourPlanner,
    plan,
  ]);

  const onClickDownloadAssigned = useCallback(() => {
    const ordersDispatched = getTotalJobsInTours(tours);
    const info = {
      dispatchMode: 'csv-all',
      origin: 'solution details',
      tours: size(tours),
      ordersInTour: ordersDispatched,
      isDemo: user.isLastOrdersAddedDemo,
    };

    AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DISPATCH, info);
    handleIncreaseUsage({ ordersDispatched, toursDispatched: size(tours), mode: 'csv-all' });

    exportToursToCSV(tours, request, tourPlanner, user, initialTourIndex);
  }, [tours, tourPlanner, user, request, handleIncreaseUsage]);

  const onClickDownloadUnassigned = useCallback(() => {
    const info = {
      assignedOrders: numberOfAssigned,
      unassignedOrders: numberOfUnassigned,
    };

    if (!isEmpty(unassigned)) {
      const grouped = {};
      unassigned.forEach((item) => {
        item.reasons.forEach((reason) => {
          if (!getSafeValue(grouped, reason.code)) setSafeValue(grouped, reason.code, []);
          getSafeValue(grouped, reason.code).push(item);
        });
      });
      keys(grouped).forEach((key) => {
        setSafeValue(info, `unassignedOrdersCode${key}`, size(getSafeValue(grouped, key)));
      });
    }

    AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DOWNLOAD_UNASSIGNED, info);
    saveUnassignedToCSVFile(unassigned, orders, user);
  }, [orders, unassigned, user, numberOfUnassigned, numberOfAssigned]);

  const handleOnClickView = useCallback(
    (forceValue) => {
      if (!onChangeView) return;
      const newValue = forceValue !== undefined ? forceValue : !isToursView;
      onChangeView(newValue);
    },
    [isToursView],
  );

  const vehiclesText = showTotalVehicles
    ? numberOfVehicles
    : `${numberOfVehicles} ${preposition} ${totalNumberOfVehicles}`;

  const vehiclePopupIconStyle = (type) => css`
    position: relative;
    width: ${StyledVehicleIconSize};
    height: ${StyledVehicleIconSize};
    background-size: ${StyledVehicleIconSize} ${StyledVehicleIconSize};
    background-image: url(${getFleetTypeIcon(type)});
    margin-right: 0.3rem;
    margin-left: 0.5rem;
    top: 0.4rem;
  `;

  useEffect(() => {
    setTotalDistance(
      parseInt(
        convertCostsFromTourPlanner({ distance: statistic.distance, isImperial }).distance,
        10,
      ),
    );
  }, [setTotalDistance, user.distance]);

  return (
    <StyledSummary>
      <StyledGroup gridValue="1fr 1fr 1fr">
        <StyledDetailContainer>
          <StyledTitle>{distanceTrans}</StyledTitle>
          <StyledText>
            {totalDistance} {isImperial ? mile : kilo}
          </StyledText>
        </StyledDetailContainer>
        <StyledDetailContainer>
          <StyledTitle>{durationTrans}</StyledTitle>
          <StyledText>{totalTime}</StyledText>
        </StyledDetailContainer>

        <StyledDetailContainer>
          <StyledTitle>{costTrans}</StyledTitle>
          <StyledText>
            {formatPriceWithCurrency(Math.round(totalCost), user.currency, 0)}
          </StyledText>
        </StyledDetailContainer>
      </StyledGroup>
      <StyledGroup gridValue="0.7fr 1.4fr">
        {numberOfAssigned > 0 && (
          <StyledDetailContainer noMarginBottom>
            <div>
              <StyledTitle>{vehicleTypes}</StyledTitle>
              {showPopup && (
                <Popup
                  h2={vehicleTypes}
                  text={vehiclesText}
                  items={vehicleTypeCount}
                  icon={vehiclePopupIconStyle}
                  setShowPopup={setShowPopup}
                />
              )}
              <>
                <StyledText>{vehiclesText}</StyledText>
                {typeCount.slice(0, 2).map((ul, idx) => (
                  <StyledText key={idx}>
                    <StyledSpan
                      title={ul.name}
                    >{`${ul.count} ${preposition} ${ul.name}`}</StyledSpan>
                    <br />
                    <StyledSpan
                      id="info"
                      title={clickForInfo}
                      onClick={() => setShowPopup(!showPopup)}
                    >
                      {typeCount.length > 2 && idx === 1 ? ' more...' : ''}
                    </StyledSpan>
                  </StyledText>
                ))}
              </>
            </div>
          </StyledDetailContainer>
        )}
        {numberOfAssigned > 0 && (
          <StyledDetailContainer noMarginBottom>
            <div>
              <StyledTitle>{jobTrans}</StyledTitle>
              <StyledText>
                {numberOfAssigned} {preposition} {numberOfAssigned + numberOfUnassigned}
              </StyledText>
            </div>
            {canDownloadCSV() && (
              <StyledDownloadContainer>
                <StyledReview
                  id="button-download-assigned"
                  title={`${download} CSV`}
                  onClick={onClickDownloadAssigned}
                >
                  <StyledIcon icon={DownloadIconStyle} mini />
                  <StyledIcon icon={jobIconStyle} mini />
                </StyledReview>
                {numberOfUnassigned > 0 && (
                  <>
                    <StyledReview
                      id="button-download-unassigned"
                      title={unassignedDownloadTitle}
                      onClick={onClickDownloadUnassigned}
                    >
                      <StyledIcon icon={DownloadIconStyle} mini />
                      <StyledIcon icon={unassignedJobIconStyle} mini />
                    </StyledReview>
                    <StyledReview
                      id="button-view-unassigned"
                      title={isToursView ? unassignedView : toursView}
                      onClick={() => handleOnClickView()}
                    >
                      <StyledIcon icon={ViewIconStyle} mini />
                      <StyledIcon icon={isToursView ? unassignedJobIconStyle : jobIconStyle} mini />
                    </StyledReview>
                  </>
                )}
              </StyledDownloadContainer>
            )}
          </StyledDetailContainer>
        )}
      </StyledGroup>
    </StyledSummary>
  );
};

export default withTranslation(SummaryWithDownload);
