import {
  SET_TERRITORY_PARAMETER,
  AREA_CATEGORY,
  GET_AREAS,
  SET_TERRITORIES_FROM_PROBLEM_FILE,
  CLEAR_SOLUTION,
  DELETE_SOLUTION,
  GET_SOLUTION,
  CLEAR_ORDERS,
} from 'actions';
import { cloneDeep, assign } from 'lodash';
import { STORAGE_IDS, setStorageValue } from '../utils/localStorageHelpers';
import { getStateFromMemory } from '../utils/MemoryHelpers';

const defaultAreaCategory = AREA_CATEGORY.POSTAL;
const defaultTerritories = {
  isEnabled: false,
  bbox: null,
  areaCategory: defaultAreaCategory,
  areaDetails: null,
  selectedAreas: null,
  strict: true,
  fromUploadedFile: false,
};
const defaultGroupAreas = {
  areaDetails: null,
};
const defaultAvoidAreas = {
  areaDetails: null,
};
const defaultInitialState = {
  territories: defaultTerritories,
  groupAreas: defaultGroupAreas,
  avoidAreas: defaultAvoidAreas,
};

const stored = getStateFromMemory(STORAGE_IDS.areas, defaultInitialState);
const initialState = assign({}, cloneDeep(defaultInitialState), stored);

export default (state = cloneDeep(initialState), { payload = {}, type }) => {
  switch (type) {
    case SET_TERRITORY_PARAMETER: {
      const newState = { ...state };
      newState.territories = { ...state.territories, ...payload };

      setStorageValue(STORAGE_IDS.areas, newState);
      return newState;
    }
    case GET_AREAS: {
      const newState = { ...state };
      newState.territories = { ...state.territories, ...payload };
      setStorageValue(STORAGE_IDS.areas, newState);
      return newState;
    }
    case CLEAR_SOLUTION: {
      return state.territories.fromUploadedFile ? defaultInitialState : state;
    }
    case DELETE_SOLUTION: {
      return state.territories.fromUploadedFile ? defaultInitialState : state;
    }
    case CLEAR_ORDERS: {
      return state.territories.fromUploadedFile ? defaultInitialState : state;
    }
    case GET_SOLUTION: {
      const newState = payload.error ? initialState : state;
      setStorageValue(STORAGE_IDS.areas, newState);
      return newState;
    }
    case SET_TERRITORIES_FROM_PROBLEM_FILE: {
      const newState = { ...state };
      Object.keys(payload).forEach((key) => {
        newState[key] = {
          ...state[key],
          isEnabled: true,
          bbox: null,
          areaCategory: defaultAreaCategory,
          areaDetails: payload[key],
          selectedAreas: null,
          strict: false,
          fromUploadedFile: true,
        };
      });
      return newState;
    }
    default:
      return state;
  }
};
