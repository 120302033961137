import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { camelCase, map, includes } from 'lodash';
import Stop from './Stop';
import { isStopArrival } from '../../../utils/OrdersHelpers';
import { getSafeValue } from '../../../utils/security';

const { primaryTextColor, primaryBorderColor } = colors;
const { small } = fontSize;

const StyledGridContainer = styled.div({
  width: '100%',
  height: 'calc(100% - 12rem)',
  overflowY: 'auto',
  position: 'absolute',
  top: '12rem',
  marginTop: '1rem',
});

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 4rem 2.7fr 0.8fr 1.3fr 0.3fr 0.3fr;

  div:nth-last-of-type(-n + 6) {
    border-bottom: none;
  }
  div {
    vertical-align: text-bottom;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 3.2rem 2fr 0.6fr 1fr 0.3fr 0.1fr;
  }
`;

const StyledHeader = styled.div`
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  padding: 0.5rem 0.2rem 0.1rem;
  border-bottom: 1px solid ${primaryBorderColor};

  & > span {
    text-transform: uppercase;
    font-size: ${small};
    color: ${primaryTextColor};
  }
`;

const Stops = ({
  stops,
  request,
  tourPlanner,
  user,
  demandLabel,
  isDemandVisible,
  tourId,
  typeId,
  translations: {
    tourSolution: { stopHeaders },
  },
}) => {
  const [activeStop, setActiveStop] = useState(1);
  const gridContentRef = useRef(null);

  useEffect(() => {
    gridContentRef.current.scrollTo(0, 0);
  }, [stops]);

  const tableHeader = useMemo(
    () => (
      <>
        {map(stopHeaders.concat(''), (header, index) => (
          <StyledHeader left={includes([1, 2], index)} key={camelCase(`${header}-${index}`)}>
            <span>{index === 2 ? demandLabel : header}</span>
          </StyledHeader>
        ))}
      </>
    ),
    [stopHeaders, demandLabel],
  );

  const onStopClick = useCallback(
    (index) => {
      if (activeStop === index) {
        setActiveStop(-1);
      } else {
        setActiveStop(index);
      }
    },
    [setActiveStop, activeStop],
  );

  return (
    <StyledGridContainer data-test-id="tourDetails" ref={gridContentRef}>
      <StyledGrid key="stops-grid">
        {tableHeader}
        {stops.map((stop, index) => (
          <Stop
            onStopClick={onStopClick}
            index={index}
            key={`stop-${index}`}
            stop={stop}
            request={request}
            prevStop={getSafeValue(stops, index - 1)}
            isDepot={index === 0}
            isStopActive={index === activeStop}
            isReturnLocation={
              index === stops.length - 1 &&
              (!tourPlanner ||
                tourPlanner.returnLocation.value !== null ||
                (tourPlanner.fileType === 'json' && isStopArrival(stop)))
            }
            isLastStop={index === stops.length - 1}
            tourStartDate={stops[0].time.arrival}
            tourPlanner={tourPlanner}
            user={user}
            demandLabel={demandLabel}
            isDemandVisible={isDemandVisible}
            tourId={tourId}
            typeId={typeId}
          />
        ))}
      </StyledGrid>
    </StyledGridContainer>
  );
};

export default withTranslation(Stops);
