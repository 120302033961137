import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearTourParameter,
  recordUsageEvent,
  setOrders,
  setTourParameter,
  USAGE_EVENTS,
} from 'actions';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, assign, isNumber, size } from 'lodash';
import styled from '@emotion/styled';
import { v4 } from 'uuid';
import Label from '../../Form/Label';
import InputLocation from '../Global/InputLocation';
import Button from '../../Form/Button';
import directions from '../../../global/img/plus.svg';
import iconName from '../../../global/img/customer-name.svg';
import iconAddress from '../../../global/img/delivery-address.svg';
import {
  StyledSelectBigInput,
  StyledSelectedInputLabel,
  StyledTextInput,
} from '../Global/WizardStyled';
import config from '../../../config';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { ORDER_ACTIVITIES, ORDER_PRIORITY } from '../../../utils/csv/config';

const StyledManualContainer = styled.div({
  position: 'relative',
  padding: '0',
  marginTop: '-1rem',
});

const StyledButtonContainer = styled.div({
  position: 'absolute',
  bottom: '-2.4rem',
  textAlign: 'center',
  width: '100%',
  div: {
    div: {
      backgroundPosition: 'center',
      backgroundSize: '1rem',
    },
    margin: 'auto',
  },
});

export const StyledDoubleContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '2.8fr 1.2fr',
  justifyContent: 'space-around',
  width: '100%',
  padding: '0 0 0.8rem 0',
  'div:nth-of-type(2)': {
    paddingLeft: '15%',
  },
});

const StyledCheckboxContainer = styled.div({
  paddingTop: '2rem',
});

const { maxValues } = config;

const emptyOrder = {
  Address: '',
  Name: '',
  Demand: [1],
  'Service time (min)': 5,
  Latitude: null,
  Longitude: null,
  isEditing: true,
  InternalID: v4(),
  Phone: '',
  Activity: ORDER_ACTIVITIES.DELIVERY,
};

const OrdersManual = ({
  oAuth,
  user,
  tourPlanner,
  orders,
  solution,
  translations: {
    csv: { addressTrans, nameTrans, demandTrans },
    tourPlanner: { depotPlaceholderTrans, namePlaceholderTrans },
    error: { depotNotFound },
    wizard: {
      orders: { editedOrderPriorityHigh, orderActivityTitle, orderActivityLabels },
    },
  },
}) => {
  const dispatch = useDispatch();
  const handleSetTourParameter = useCallback(
    (p) => dispatch(setTourParameter({ ...p, index: solution.show })),
    [dispatch, solution.show],
  );
  const handleRecordUsage = useCallback((data) => dispatch(recordUsageEvent(data)), [dispatch]);
  const handleSetOrder = useCallback(
    (allOrders, isDemo, index) => dispatch(setOrders(allOrders, isDemo, index)),
    [dispatch],
  );
  const handleClearTourParameter = useCallback(
    (parameter) => dispatch(clearTourParameter(parameter, solution.show)),
    [dispatch, solution.show],
  );

  const [editedOrder, setEditedOrder] = useState(emptyOrder);
  const [noAddressFound, setNoAddressFound] = useState(false);

  useEffect(() => {
    handleSetTourParameter({ editedOrder: { ...emptyOrder } });
  }, [handleSetTourParameter]);

  const onLocationFound = useCallback(
    (loc) => {
      setNoAddressFound(!loc.value && !isEmpty(loc.label));
      const newOrder = assign({}, editedOrder, {
        Address: loc.label,
        Latitude: loc.value && loc.value.lat,
        Longitude: loc.value && loc.value.lng,
      });
      setEditedOrder(newOrder);
      handleSetTourParameter({
        editedOrder: { ...newOrder, customJobAddress: !!tourPlanner.customJobAddress },
        index: solution.show,
      });
    },
    [
      setNoAddressFound,
      setEditedOrder,
      editedOrder,
      handleSetTourParameter,
      tourPlanner.customJobAddress,
      solution.show,
    ],
  );

  const onChangeName = useCallback(
    (e) => {
      const value = e.target.value;
      const newOrder = assign({}, editedOrder, { Name: value });
      setEditedOrder(newOrder);
    },
    [setEditedOrder, editedOrder],
  );

  const onBlurName = useCallback(() => {
    handleSetTourParameter({ editedOrder: { ...editedOrder } });
  }, [editedOrder, handleSetTourParameter]);

  const onChangeDemand = useCallback(
    (e) => {
      const value = e.target.value && parseInt(e.target.value, 10);
      const newOrder = assign({}, editedOrder, { Demand: [value] });
      setEditedOrder(newOrder);
      e.target.focus();
    },
    [setEditedOrder, editedOrder],
  );

  const onBlurDemand = useCallback(
    (e) => {
      const value = e.target.value && parseInt(e.target.value, 10);
      if (!Number.isNaN(value) && value >= 1 && value <= maxValues.demand) {
        const newOrder = assign({}, editedOrder, { Demand: [value] });
        handleSetTourParameter({ editedOrder: { ...newOrder } });
      } else {
        const newOrder = assign({}, editedOrder, { Demand: [tourPlanner.editedOrder.Demand] });
        setEditedOrder(newOrder);
      }
    },
    [setEditedOrder, editedOrder, handleSetTourParameter, tourPlanner],
  );

  const handleSwitchOrderPriority = useCallback(
    (e) => {
      const value = e.target.checked ? ORDER_PRIORITY.HIGH : undefined;
      const newOrder = assign({}, editedOrder, { Priority: value });
      setEditedOrder(newOrder);
      handleSetTourParameter({ editedOrder: { ...newOrder } });
    },
    [editedOrder, setEditedOrder, handleSetTourParameter],
  );

  const handleOnChangeOrderActivity = useCallback(
    (e) => {
      const value = e.target.value;
      const newOrder = assign({}, editedOrder, { Activity: value });
      setEditedOrder(newOrder);
      handleSetTourParameter({ editedOrder: { ...newOrder } });
    },
    [editedOrder, setEditedOrder, handleSetTourParameter],
  );

  const onSave = useCallback(() => {
    handleClearTourParameter(['customJobAddress']);
    handleSetOrder([...orders, editedOrder], false, solution.show);
    emptyOrder.InternalID = v4();
    setEditedOrder(emptyOrder);
    handleSetTourParameter({ editedOrder: { ...emptyOrder } });
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD, {
      addOrdersMode: 'manual',
      addOrdersAmount: 1,
    });
    handleRecordUsage({ event: USAGE_EVENTS.ORDERS_ADD, mode: 'manual' });
  }, [
    setEditedOrder,
    handleSetOrder,
    editedOrder,
    handleSetTourParameter,
    handleRecordUsage,
    handleClearTourParameter,
    orders,
    solution.show,
  ]);

  useEffect(() => {
    if (tourPlanner.customJobAddress) {
      setEditedOrder((prev) => ({
        ...prev,
        Address: tourPlanner.customJobAddress,
      }));
    }
  }, [tourPlanner.customJobAddress]);
  const isButtonDisabled = !isNumber(editedOrder.Latitude) || size(orders) >= maxValues.orders;

  const onFocus = (e) => {
    if (e.target instanceof HTMLInputElement) e.target.removeAttribute('readonly');
  };

  return (
    <StyledManualContainer>
      <StyledDoubleContainer>
        <StyledCheckboxContainer>
          <input
            id="input-edit-order-priority"
            type="checkbox"
            checked={editedOrder.Priority === ORDER_PRIORITY.HIGH}
            onChange={handleSwitchOrderPriority}
          />{' '}
          <label
            className="custom-switcher"
            htmlFor="input-edit-order-priority"
            data-testid="priorityOrder"
          >
            T
          </label>
          <span className="label-custom-switcher big"> {editedOrderPriorityHigh}</span>
        </StyledCheckboxContainer>
        <div>
          <StyledSelectedInputLabel asBlock>{orderActivityTitle}</StyledSelectedInputLabel>
          <StyledSelectBigInput
            fullWidth
            onChange={handleOnChangeOrderActivity}
            value={editedOrder.Activity}
            id="input-edit-order-activity-type"
          >
            <option value={ORDER_ACTIVITIES.DELIVERY}>{orderActivityLabels.delivery}</option>
            <option value={ORDER_ACTIVITIES.PICKUP}>{orderActivityLabels.pickup}</option>
          </StyledSelectBigInput>
        </div>
      </StyledDoubleContainer>
      <StyledDoubleContainer>
        <div>
          <Label>{nameTrans}</Label>
          <StyledTextInput
            id="input-edit-order-name"
            placeholder={namePlaceholderTrans}
            value={editedOrder.Name}
            onChange={onChangeName}
            onFocus={onFocus}
            readOnly
            onBlur={onBlurName}
            icon={iconName}
            type="new-password"
            spellCheck={false}
            autoComplete="off"
          />
        </div>
        <div>
          <Label>{demandTrans}</Label>
          <StyledTextInput
            id="input-edit-order-demand"
            value={editedOrder.Demand}
            type="Number"
            pattern="[0-9]*"
            inputmode="numeric"
            onChange={onChangeDemand}
            onBlur={onBlurDemand}
            max={maxValues.demand}
            min="1"
            autoComplete="off"
          />
        </div>
      </StyledDoubleContainer>
      <>
        {!noAddressFound && <Label>{addressTrans}</Label>}
        {noAddressFound && <Label error>{depotNotFound}</Label>}
        <InputLocation
          id="input-edit-order-add"
          placeholder={depotPlaceholderTrans}
          value={editedOrder.Address}
          oAuth={oAuth}
          onLocationFound={onLocationFound}
          user={user}
          tourPlanner={tourPlanner}
          icon={iconAddress}
        />
      </>
      <StyledButtonContainer data-testid="saveManualOrder">
        <Button
          icon={directions}
          onClick={onSave}
          disabled={isButtonDisabled}
          id="button-add-order-manual"
        />
      </StyledButtonContainer>
    </StyledManualContainer>
  );
};

export default withTranslation(OrdersManual);
