import React, { useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { MapContainer, TileLayer, ZoomControl, ScaleControl } from 'react-leaflet';
import withTranslation from 'hoc/withTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { getAreaPolygonFormat } from 'utils/territories/TerritoryAreaConverter';
import { useCookies } from 'react-cookie';
import { isEqual } from 'lodash';
import { getBounds, toBounds } from '../../utils/map/MapCalculations';
import config from '../../config';
import MapMarkers from './MapMarkers/MapMarkers';
import MapTourLines from './MapTourLines';
import { fitMapBounds, getMapSettings } from '../../utils/map/MapUtils';
import { getGeoInfoFromObjects, getOriginalLines } from '../../utils/map/MapTourLineUtils';
import {
  getRoutingTourInfo,
  selectTourById,
  selectUnassigned,
  setTerritoryParameter,
} from '../../actions';
import { tourToRoutingRequest } from '../../utils/RoutingConverter';
import MapAreas from './MapAreas';
import { APP_MODES, getAppMode } from '../../utils/urlHelpers';
import { getTerritoriesGeoJSON } from '../../utils/territories/TerritoriesHelpers';
import LocationSelector from './LocationSelector';
import { isProdEnv } from '../../utils/helpers';
import { getGroupsPerTour } from '../../utils/MapHelpers';

const {
  map: { url },
} = config;
const appMode = getAppMode();
const isProd = isProdEnv();
const avoidAreasFF = appMode.includes(APP_MODES.AVOID_AREAS);
const groupsFF = appMode.includes(APP_MODES.GROUPS);

const MAX_MAP_ZOOM = 18;
const MIN_MAP_ZOOM = 2;

const StyledMap = styled.div({
  width: '100%',
  overflow: 'hidden',
  position: 'fixed',
});

const Map = ({
  mapData,
  oAuth,
  user,
  display,
  routingData,
  isSolution,
  tourData,
  translations,
  tourPlanner,
  solutionId,
}) => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.usageContext.currentStep);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const [territories, groupAreas, avoidAreas] = useSelector(({ areas: stateAreas }) => [
    stateAreas.territories,
    stateAreas.groupAreas,
    stateAreas.avoidAreas,
  ]);
  const handleSetTerritoryParameter = useCallback(
    (parameter) => dispatch(setTerritoryParameter(parameter)),
    [dispatch],
  );
  const handleSelectTourById = useCallback((index) => dispatch(selectTourById(index)), [dispatch]);
  const handleSelectUnassigned = useCallback(() => dispatch(selectUnassigned(true)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest(
            { ...tour, routeId: index, solutionId },
            tourPlanner,
          ),
        }),
      ),
    [dispatch, oAuth, tourPlanner, solutionId],
  );
  const userDistance = user.distance;
  const [areaPolygon, setAreaPolygon] = useState(null);
  const [selectedAreasList, setSelectedAreasList] = useState(null);
  const [routingRoutes, setRoutingRoutes] = useState(null);
  const [originalLocation, setOriginalLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState();
  const [cookies] = useCookies(['apikey']);
  const hasApiKey = cookies.apikey && cookies.apikey !== '';
  const markerRestrictions = tourPlanner.ordersMode === 'manual' && currentStep === 2;
  const allowMarkers = isProd ? markerRestrictions && hasApiKey : markerRestrictions;
  const getMapUrl = useCallback(() => `${url}&lg=${translations.map.language}`);
  const groupsToShow = groupsFF && getGroupsPerTour(tourData, display.routeIds, solution);

  const showUnassigned = useCallback(() => {
    handleSelectUnassigned();
  });

  const activateTour = useCallback(
    (index, tour) => {
      handleSelectTourById(index);
      handleGetRoutingTourInfo(index, tour);
    },
    [handleSelectTourById, handleGetRoutingTourInfo],
  );

  useEffect(() => {
    if (appMode === APP_MODES.TERRA) {
      setAreaPolygon(getAreaPolygonFormat(territories.areaDetails));
    } else if (groupsFF)
      setAreaPolygon(getTerritoriesGeoJSON(territories.areaDetails, groupAreas.areaDetails));
    else if (avoidAreasFF)
      setAreaPolygon(getTerritoriesGeoJSON(territories.areaDetails, avoidAreas.areaDetails));
    else setAreaPolygon(getTerritoriesGeoJSON(territories.areaDetails));
  }, [territories, groupAreas]);

  useEffect(() => {
    const newBounds = getBounds(mapData, user, display, routingRoutes);
    if (map && !tourPlanner.editedOrder?.customJobAddress && !isEqual(newBounds, bounds)) {
      setBounds(newBounds);
      fitMapBounds(map, newBounds);
    }
  }, [
    mapData,
    user,
    display.showProblem,
    display.showUnassigned,
    routingRoutes,
    bounds,
    tourPlanner.editedOrder,
    display,
    map,
  ]);

  useEffect(() => {
    const routeRequestError = routingData && routingData.requestError;
    const routes =
      routeRequestError || user.mapSettings.showStraightRouteLines
        ? null
        : getGeoInfoFromObjects(routingData);
    setRoutingRoutes(routes);
    const lines = routeRequestError ? null : getOriginalLines(routingData);
    setOriginalLocation(lines);
  }, [routingData, setRoutingRoutes, user.mapSettings.showStraightRouteLines]);

  const handleOnSelect = useCallback(
    (value) => {
      const selectedAreaValue = value.postal_code;
      const newSelectedAreas = { value: selectedAreaValue };
      setSelectedAreasList(newSelectedAreas);
      handleSetTerritoryParameter({ selectedAreas: newSelectedAreas });
    },
    [selectedAreasList, territories, handleSetTerritoryParameter],
  );

  return (
    <StyledMap user={user} translations={translations}>
      <MapContainer
        attributionControl={false}
        zoomControl={false}
        bounds={toBounds(getBounds(mapData, user, display, routingRoutes))}
        boundsOptions={getMapSettings()}
        useFly
        maxZoom={MAX_MAP_ZOOM}
        minZoom={MIN_MAP_ZOOM}
        whenCreated={setMap}
      >
        {allowMarkers && oAuth && <LocationSelector oAuth={oAuth} />}
        {territories.isEnabled && areaPolygon != null ? (
          <MapAreas
            areaPolygon={areaPolygon}
            onClick={handleOnSelect}
            groupsToShow={groupsToShow}
          />
        ) : null}
        <TileLayer attribution="Here Maps" url={getMapUrl()} />
        <ZoomControl position="topright" zoomInTitle="" zoomOutTitle="" />
        <ScaleControl
          position="bottomright"
          metric={userDistance === 'metric'}
          imperial={userDistance === 'imperial'}
        />
        {mapData && oAuth && (
          <MapMarkers
            mapData={mapData}
            map={map}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            tourData={tourData}
            isSolution={isSolution}
            activateTour={activateTour}
            showUnassigned={showUnassigned}
            oAuth={oAuth}
            tourPlanner={tourPlanner}
          />
        )}
        {isSolution && (
          <MapTourLines
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            tourData={tourData}
            activateTour={activateTour}
            originalLocation={originalLocation}
          />
        )}
      </MapContainer>
    </StyledMap>
  );
};

export default withTranslation(Map);
