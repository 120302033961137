import styled from '@emotion/styled';
import { getAsset } from 'utils/helpers';
import { css } from '@emotion/react';
import { colors, fontSize } from '../../../global/variables';
import check from '../../../global/img/checkmark-fill.svg';
import exclamation from '../../../global/img/exclamation-fill.svg';
import priorityHighIcon from '../../../global/img/priority-high-white.svg';
import priorityNormalIcon from '../../../global/img/priority-normal-white.svg';
import { getSafeValue } from '../../../utils/security';

const {
  darkSolidBackground,
  darkerSolidBackground,
  white,
  secondaryTextColorDarkBg,
  secondaryTextColorDarkBgDisabled,
  secondaryTextColorDarkBgEnabled,
  placeholderTextColor,
  aqua,
} = colors;
const { small, smaller } = fontSize;

export const StyledContrastPanelContainer = styled.div({
  width: '100%',
  height: '100%',
  backgroundColor: white,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  textAlign: 'center',
  zIndex: 999,
});

export const StyledContrastPanel = styled.div(({ fullDisplay }) => ({
  borderRadius: '0.2rem',
  backgroundColor: darkSolidBackground,
  width: fullDisplay ? '100%' : 'calc(100% - 2rem)',
  height: fullDisplay ? '100%' : 'calc(100% - 0.4rem)',
  margin: 'auto',
  textAlign: 'left',
  boxSizing: 'border-box',
}));

export const StyledContrastPanelHeader = styled.div(({ size }) => ({
  padding: '1rem 1rem 0.6rem 1rem',
  boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.3)',
  height: size === 'S' ? '5.4rem' : size === '0' ? 'auto' : '8.2rem',
  position: 'relative',
}));

export const StyledContrastPanelHeaderText = styled.div(
  ({ darker, paddingBottom, found, icon, jsonTitle }) => ({
    backgroundImage: icon ? `url( ${found ? getAsset(check) : getAsset(exclamation)})` : '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 5px',
    padding: paddingBottom
      ? `0.2rem 0 ${jsonTitle ? '0.7rem' : '1.4rem'} ${icon ? '16px' : '0'}`
      : `0.2rem 0 0 ${icon ? '16px' : '0'}`,
    color: found ? aqua : darker ? white : secondaryTextColorDarkBg,
    fontSize: small,
    fontWeight: found ? 700 : 400,
    opacity: '1',
    transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  }),
);

export const StyledContrastPanelTableHeader = styled.div(({ hasAction }) => ({
  display: 'grid',
  gridTemplateColumns: hasAction ? '1fr 1.5fr 1.6rem' : '1fr 1.5fr',
  div: {
    textTransform: 'uppercase',
    fontSize: smaller,
    color: secondaryTextColorDarkBg,
  },
  position: 'absolute',
  bottom: '0.4rem',
  left: '1rem',
  right: hasAction ? '0.5rem' : '1rem',
}));

export const StyledContrastPanelFooter = styled.div(({ isDouble, isFullWidth, jsonViewer }) => ({
  display: isDouble && 'grid',
  gridTemplateColumns: jsonViewer ? '1fr 1fr 1fr' : '1fr 1rem 1fr',
  position: 'absolute',
  bottom: jsonViewer ? '0.8rem' : '1.4rem',
  left: isFullWidth ? '1rem' : '2rem',
  right: isFullWidth ? '1rem' : '2rem',
  backgroundColor: jsonViewer ? 'none' : darkSolidBackground,
}));

export const StyledContrastPanelColumnsContainer = styled.div(({ height }) => ({
  backgroundColor: darkerSolidBackground,
  height: height || 'calc(100% - 14.4rem)',
  overflowY: 'scroll',
  padding: '0',
}));

const getTextColor = (isEnabled, isDisabled) => {
  if (isEnabled) return secondaryTextColorDarkBgEnabled;
  if (isDisabled) return secondaryTextColorDarkBgDisabled;
  return secondaryTextColorDarkBg;
};

const inputSizes = {
  L: '1.5fr',
  M: '0.8fr',
  S: '0.4fr',
  XS: '0.1fr',
  Zero: '0.5rem',
};
const getGridTemplateColumbsForContrastPanelColumn = (
  hasAction,
  actionsCount = 1,
  inputSize = 'L',
) => {
  const inputSizeUnits = getSafeValue(inputSizes, inputSize);
  if (!hasAction) return `1fr ${inputSizeUnits}`;
  const requiredWidth = 1.4 * actionsCount + 0.2;
  return `1fr ${inputSizeUnits} ${requiredWidth}rem`;
};

export const StyledContrastPanelColumn = styled.div(
  ({ hasAction, isDisabled, isEnabled, actionsCount, inputSize }) => ({
    display: 'grid',
    gridTemplateColumns: getGridTemplateColumbsForContrastPanelColumn(
      hasAction,
      actionsCount,
      inputSize,
    ),
    lineHeight: '3rem',
    verticalAlign: 'middle',
    borderBottom: `solid 1px ${placeholderTextColor}`,
    fontSize: small,
    fontWeight: isEnabled ? 500 : 300,
    color: getTextColor(isEnabled, isDisabled),
    padding: hasAction ? '0.25rem 0.5rem 0.25rem 1rem' : '0.25rem 1rem',
  }),
);

export const StyledCategory = styled.div({
  width: '100%',
});

export const StyledPriorityHighIconContrast = css`
  background-image: url(${getAsset(priorityHighIcon)});
  background-position: top;
  opacity: 0.3;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  cursor: pointer;
  margin: 1.2rem 0 0.2rem 0.4rem;

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledPriorityNormalIconContrast = css`
  background-image: url(${getAsset(priorityNormalIcon)});
  background-position: bottom;
  opacity: 0.3;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  cursor: pointer;
  margin: 1.2rem 0 0.2rem 0.4rem;

  &:hover {
    opacity: 0.8;
  }
`;
