import { GeoJSON } from 'react-leaflet';
import React, { useCallback } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { mapDataKey } from '../../../utils/map/MapCalculations';
import {
  featureFilterCluster,
  filterDepot,
  isActivityToDisplay,
  isRouteToDisplay,
} from '../../../utils/map/MapFeatureFilters';
import { getStyle } from '../../../utils/map/MapUtils';
import {
  CLUSTER_BREAKPOINT_MAP_ZOOM,
  DEFAULT_CLUSTER_RADIUS,
  MIN_CLUSTER_RADIUS,
} from './MapMarkers';

const StopJobMarkers = ({
  mapData,
  user,
  display,
  routingRoutes,
  highlightStop,
  makeMapMarkers,
  createClusterIcon,
  isSolution,
  routingData,
}) => {
  const filterMarkersClusterFeatures = useCallback(
    (feature) => {
      return featureFilterCluster(
        feature,
        display,
        routingData,
        user.mapSettings.showStraightRouteLines,
      );
    },
    [display, routingData, user.mapSettings.showStraightRouteLines],
  );
  const clusteredData = useCallback(() => {
    if (!display.showProblem && !user.mapSettings.showStops)
      return mapData.geo.features.filter((f) => f.geometry.type === 'LineString');

    const start = filterDepot(mapData, false);
    return isSolution
      ? start.filter((f) => !isActivityToDisplay(f) && isRouteToDisplay(f, display))
      : start.filter((f) => !isActivityToDisplay(f));
  }, [isSolution, mapData, display, user.mapSettings.showStops]);

  const Markers = () => (
    <GeoJSON
      key={mapDataKey(mapData, user, display, routingRoutes, highlightStop)}
      data={clusteredData()}
      pointToLayer={makeMapMarkers}
      style={getStyle}
      filter={filterMarkersClusterFeatures}
    />
  );

  return user.mapSettings.showClusters ? (
    <MarkerClusterGroup
      key={`${mapDataKey(mapData, user, display, routingRoutes)}-stop-cluster`}
      iconCreateFunction={createClusterIcon}
      showCoverageOnHover={false}
      maxClusterRadius={(level) =>
        level > CLUSTER_BREAKPOINT_MAP_ZOOM ? MIN_CLUSTER_RADIUS : DEFAULT_CLUSTER_RADIUS
      }
    >
      <Markers />
    </MarkerClusterGroup>
  ) : (
    <Markers />
  );
};

export default StopJobMarkers;
