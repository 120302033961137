import { isMobileDevice } from '../helpers';
import { colors, tourColors } from '../../global/variables';
import { toBounds } from './MapCalculations';

const { unusedMarkerStroke } = colors;

export const getMapSettings = () =>
  isMobileDevice()
    ? { paddingTopLeft: [50, 0], paddingBottomRight: [80, 0] }
    : { paddingTopLeft: [450, 100], paddingBottomRight: [40, 40] };

export const animateFlyTo = {
  animation: true,
  duration: 1.111,
  easeLinearity: 0.69,
};

export const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

export const rgbToHsl = ({ r, g, b }) => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h;
  let s;
  let l;

  if (max === min) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / (max - min)) % 6;
  } else if (max === g) {
    h = (b - r) / (max - min) + 2;
  } else {
    h = (r - g) / (max - min) + 4;
  }

  h = Math.round(h * 60);
  if (h < 0) {
    h += 360;
  }

  l = (max + min) / 2;

  if (max === min) {
    s = 0;
  } else if (l <= 0.5) {
    s = (max - min) / (max + min);
  } else {
    s = (max - min) / (2 - max - min);
  }

  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
};

export const getGradientTourColor = (color, index, total) => {
  const { h, s } = rgbToHsl(hexToRgb(color));
  const l = 100 - ((index / total) * 85 + 10);
  return `hsl(${h},${s}%,${l}%)`;
};

export const getStyle = (feature, gradientRoutes) => {
  const {
    properties: { routeId, mode, isStopsRequest, fromStop, totalStops },
  } = feature;
  const isPedestrian = mode === 'pedestrian';
  const weight = isPedestrian ? 2 : 4;
  const dashArray = isPedestrian ? '2 6' : undefined;
  const color = tourColors[routeId % tourColors.length].delivery.inner;
  const routeColor =
    gradientRoutes && isStopsRequest ? getGradientTourColor(color, fromStop, totalStops) : color;

  return {
    opacity: 1,
    weight,
    color: routeColor,
    dashArray,
  };
};
export const getStyleUnselected = () => ({ opacity: 0.8, weight: 2, color: unusedMarkerStroke });
export const getStyleOriginalLocationLine = () => ({
  opacity: 0.8,
  weight: 2,
  color: unusedMarkerStroke,
  dashArray: '4',
});
export const fitMapBounds = (map, bounds) => {
  map.fitBounds(toBounds(bounds), getMapSettings(), animateFlyTo);
};
