import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getRoutingTourInfo, selectTourById } from 'actions';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { camelCase, includes } from 'lodash';
import { getDemand, getLastValidIteration } from 'utils/SolutionHelpers';
import { tourToRoutingRequest } from 'utils/RoutingConverter';
import Tour from './Tour';
import { isMobileDevice } from '../../../utils/helpers';

const { primaryBorderColor } = colors;
const { small } = fontSize;

const gridSizes = (isMobile) => {
  if (!isMobile) return '0.6rem 0.1fr 0.2fr 0.6fr 1fr 1fr 1fr 0.3fr 0.1fr 0.6rem';
  return '0.5rem 0.5fr 0.1fr 0.8fr 1.2fr 1.1fr 1.1fr 0.1fr 0.1fr 0.5rem';
};

const StyledGrid = styled.div(({ isMobile }) => ({
  display: 'grid',
  gridTemplateColumns: gridSizes(isMobile),
  div: {
    textAlign: 'center',
    verticalAlign: 'text-bottom',
  },
  'div:nth-last-of-type(-n + 9)': {
    borderBottom: 'none',
  },
}));

const StyledHeader = styled.div`
  text-transform: uppercase;
  padding: 0 0.2rem;
  text-align: center;
  border-bottom: 1px solid ${primaryBorderColor};
  text-rendering: geometricPrecision;

  & > span {
    font-size: ${small};
    opacity: 0.75;
    position: relative;
    top: -2px;
  }
`;

const Tours = ({
  oAuth,
  id,
  lastIterationTourData: { fleet, plan, tours = [], uploaded },
  onTourChange,
  selectedTours,
  solution,
  tourPlanner,
  translations: {
    tourSolution: { tourHeaders },
  },
  user,
}) => {
  const dispatch = useDispatch();
  const handleSelectTourById = useCallback((index) => dispatch(selectTourById(index)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest(
            { ...tour, routeId: index, solutionId: solution.show },
            tourPlanner,
          ),
        }),
      ),
    [dispatch, oAuth, tourPlanner, solution.show],
  );

  const onClick = useCallback(
    (index, tour) => () => {
      if (!includes(selectedTours, index)) {
        handleSelectTourById(index);
        handleGetRoutingTourInfo(index, tour);
      }
    },
    [handleGetRoutingTourInfo, handleSelectTourById, selectedTours],
  );

  const onClickViewTour = useCallback(
    (index, tour) => () => {
      onClick(index, tour);
      onTourChange(tour);
    },
    [onClick, onTourChange],
  );

  useEffect(() => {
    handleSelectTourById(0);
    handleGetRoutingTourInfo(0, tours[0]);
  }, []);

  const tableHeader = useMemo(
    () => (
      <>
        {['', '#', ...tourHeaders, '', ''].map((header, index) => (
          <StyledHeader key={camelCase(`${header}-${index}`)}>
            <span>{header}</span>
          </StyledHeader>
        ))}
      </>
    ),
    [tourHeaders],
  );

  const request = getLastValidIteration(solution.requests[0]).request;

  return (
    <>
      <StyledGrid id={id} isMobile={isMobileDevice()}>
        {tableHeader}
        {tours.map((tour, index) => (
          <Tour
            demand={!uploaded ? getDemand({ tour, plan }) : null}
            fleet={fleet}
            index={index}
            isActive={includes(selectedTours, index)}
            key={`tour-${index}`}
            onClickTour={onClick(index, tour)}
            onTourChange={onClickViewTour(index, tour)}
            request={request}
            tour={tour}
            user={user}
            tourPlanner={tourPlanner}
          />
        ))}
      </StyledGrid>
    </>
  );
};

export default withTranslation(Tours);
