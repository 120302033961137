import { size } from 'lodash';
import { divIcon, marker } from 'leaflet';
import moment from 'moment';
import { formatTime } from '../formatter';
import {
  isActivityToDisplay,
  isPointDepot,
  isPointReturnLocation,
  isRouteToDisplay,
  isUnassignedJob,
} from './MapFeatureFilters';
import { isMobileDevice } from '../helpers';
import { getSafeValue, setSafeValue } from '../security';
import {
  createDepotSvg,
  createMarkerSvg,
  createOrderUnassignedSvg,
  createTourUnselectedSvg,
} from '../../icons/marker';

const getTagsRow = (jobTags) =>
  size(jobTags) > 0
    ? `<tr>
    <td>Tags:</td>
    <td>${jobTags && jobTags.join(', ')}</td>
  </tr>`
    : '';

const getTimeWindowRows = (tw) =>
  (tw || [])
    .map((t) => `<tr><td>TW:</td><td>${formatTime(t[0])} - ${formatTime(t[1])}</td></tr>`)
    .join('');

export const getTitle = (
  translations,
  labels = undefined,
  typesObj = {},
  highPriority = false,
  address = undefined,
) => {
  const {
    wizard: {
      orders: { editedOrderPriorityHigh },
    },
    global: {
      breakLabel,
      reloadLabel,
      departureLabel,
      arrivalLabel,
      workingRestTimeLabel,
      drivingRestTimeLabel,
    },
  } = translations;

  if (Object.keys(typesObj).length === 1) {
    if (typesObj.break) return `<div class="labels">${breakLabel}<br/></div>`;
    if (typesObj.workingRestTime) return `<div class="labels">${workingRestTimeLabel}<br/></div>`;
    if (typesObj.drivingRestTime) return `<div class="labels">${drivingRestTimeLabel}<br/></div>`;
    if (typesObj.reload) return `<div class="labels">${reloadLabel}<br/></div>`;
    if (typesObj.departure) return `<div class="labels">${departureLabel}<br/></div>`;
    if (typesObj.arrival) return `<div class="labels">${arrivalLabel}<br/></div>`;
  }
  if (labels && highPriority && address)
    return `<div class="labels"><div class="high-priority">${editedOrderPriorityHigh}</div>${address}<br/>${labels.join(
      '<br />',
    )}</div>`;
  if (labels) return `<div class="labels">${labels.join('<br />')}</div>`;
  return '';
};

const createTablePopup = (
  title,
  jobTags,
  arrivalJobMarkerTrans,
  arrival,
  departureJobMarkerTrans,
  departure,
  territoryName,
  tw,
  hourOffset,
) =>
  `${title}
    <table>${getTagsRow(jobTags)}
    <tr>
      <td>${arrivalJobMarkerTrans}:&nbsp;&nbsp;</td>
      <td>${moment(arrival).utc().utcOffset(hourOffset).format('LT')}</td>
    </tr>
    <tr>
      <td>${departureJobMarkerTrans}:&nbsp;&nbsp;</td>
      <td>${moment(departure).utc().utcOffset(hourOffset).format('LT')}</td>
    </tr>${territoryName}${getTimeWindowRows(tw)}</table>
`;

export const makeDepotMarker = (
  feature,
  latlng,
  onMarkerClick,
  translations,
  onMarkerDragEnd,
  isSolution,
  highlight,
) => {
  const {
    map: { depotMarkerTrans },
  } = translations;
  const hover = true;
  const address =
    feature.properties && feature.properties.address ? `${feature.properties.address}<br/>` : '';
  const labels = feature.properties && feature.properties.labels ? feature.properties.labels : [];
  const title =
    labels.length !== 0 || address.length !== 0
      ? `<div class="labels">${address}${labels.join('<br />')}</div>`
      : '';

  const options = {
    className: 'leaflet-marker-icon-job depot-icon',
    html: L.Util.template(createDepotSvg(highlight)),
    iconSize: [64, 64],
    iconAnchor: [32, 64],
  };

  const m = marker(latlng, {
    icon: divIcon(options),
    draggable: !isSolution,
    zIndexOffset: -1000,
  }).bindPopup(`${title}${depotMarkerTrans}`, { className: 'custom-depot', autoPan: false });
  m.on('mouseover', () => {
    if (hover) m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  m.on('dragend', () => {
    onMarkerDragEnd(m, feature);
  });
  return m;
};

export const makeReturnLocationMarker = (
  feature,
  latlng,
  onMarkerClick,
  translations,
  onMarkerDragEnd,
  isSolution,
) => {
  const {
    map: { returnLocationMarkerTrans },
  } = translations;
  const address =
    feature.properties && feature.properties.address ? `${feature.properties.address}<br/>` : '';
  const title = `<div class="labels">${address}</div>`;

  const m = marker(latlng, {
    icon: divIcon({
      className: `leaflet-marker-icon-job return-location-icon`,
      html: '<div></div>',
      iconSize: [64, 64],
      iconAnchor: [32, 64],
    }),
    draggable: !isSolution,
  }).bindPopup(`${title}${returnLocationMarkerTrans}`, {
    className: 'custom-depot',
    autoPan: false,
  });

  m.on('mouseover', () => {
    m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  m.on('dragend', () => {
    onMarkerDragEnd(m, feature);
  });
  return m;
};

const getColor = (typesClass, jobMarkerColor, highlight) => {
  if (highlight)
    return {
      outer: 'yellow',
      inner:
        typesClass === 'tour-icon-pickup'
          ? jobMarkerColor.pickup.inner
          : jobMarkerColor.delivery.inner,
      text: 'yellow',
    };
  return typesClass === 'tour-icon-pickup'
    ? jobMarkerColor.pickup
    : typesClass === 'tour-icon-delivery'
    ? jobMarkerColor?.delivery
    : { inner: 'grey', outer: 'grey', text: 'white' };
};

const getJobMarkerIcon = (
  name,
  jobMarkerColor,
  contentOfIcon,
  isHighPriority,
  typesClass,
  isBreak,
  isReload,
  highlight,
  highlightUnassigned,
) => {
  switch (name) {
    case 'unassigned-icon':
      return createOrderUnassignedSvg(10, 11, highlightUnassigned);
    case 'tour-unselected': {
      const color = getColor(typesClass, jobMarkerColor, highlight);
      return createTourUnselectedSvg(color?.inner, color?.outer);
    }
    case 'tour-selected': {
      const color = getColor(typesClass, jobMarkerColor, highlight);
      return createMarkerSvg(
        color.inner,
        color.outer,
        27,
        40,
        contentOfIcon,
        isHighPriority,
        isReload,
        isBreak,
        color.text,
        highlight,
      );
    }
    default:
      return '';
  }
};

export const makeJobMarker = (
  feature,
  latlng,
  isSelected,
  onMarkerClick,
  translations,
  hourOffset,
  highlight,
  highlightUnassigned,
) => {
  const {
    properties: {
      arr_time: arrival,
      dep_time: departure,
      jobOrder,
      jobTags,
      routeId,
      types,
      tw,
      address,
      labels,
      highPriority,
      reason,
      territory,
      jobMarkerColor,
    },
  } = feature;

  const {
    map: { arrivalJobMarkerTrans, departureJobMarkerTrans, territoryMarkerTrans },
    wizard: {
      tours: { unassignedReasonsTrans, unassignedCodes },
    },
  } = translations;

  const typesObj = (types || []).reduce((t, item) => {
    if (!getSafeValue(t, item)) setSafeValue(t, item, 0);
    setSafeValue(t, item, getSafeValue(t, item) + 1);
    return t;
  }, {});

  let typesClass = '';
  let isBreak = false;
  let isReload = false;

  if (Object.keys(typesObj).length === 1) {
    typesClass = typesObj.pickup ? 'tour-icon-pickup' : 'tour-icon-delivery';
    isBreak = typesObj.break || typesObj.drivingRestTime || typesObj.workingRestTime;
    isReload = typesObj.reload;
  } else if (!isUnassignedJob(feature)) {
    typesClass = 'tour-icon-mixed';
  }

  const iconSize = isUnassignedJob(feature) ? [7, 7] : [10, 10];
  const selectedClass = isSelected
    ? 'tour-selected'
    : isUnassignedJob(feature)
    ? 'unassigned-icon'
    : 'tour-unselected';
  const title = getTitle(translations, labels, typesObj, highPriority, address);
  const reasons = reason ? reason.map((r) => getSafeValue(unassignedCodes, r.code)) : null;
  const reasonLabel = reasons && `${unassignedReasonsTrans}: <br/>-&nbsp;${reasons.join('<br/>-')}`;
  const territoryName = territory
    ? `<tr><td>${territoryMarkerTrans}:</td><td>${territory.name}</td></tr>`
    : '';
  const contentOfIcon = isSelected && !isBreak && !isReload ? jobOrder : '';

  const options = {
    className: `leaflet-marker-icon-job tour-icon ${selectedClass}`,
    html: L.Util.template(
      getJobMarkerIcon(
        selectedClass,
        jobMarkerColor,
        contentOfIcon,
        highPriority,
        typesClass,
        isBreak,
        isReload,
        highlight,
        highlightUnassigned,
      ),
    ),
    iconSize: isSelected ? [35, 35] : iconSize,
    iconAnchor: isSelected ? [12, 35] : [5, 5],
  };

  const m = marker(latlng, {
    icon: new L.DivIcon(options),
  }).bindPopup(
    routeId === undefined
      ? `${title}<br/>${reasonLabel}`
      : createTablePopup(
          title,
          jobTags,
          arrivalJobMarkerTrans,
          arrival,
          departureJobMarkerTrans,
          departure,
          territoryName,
          tw,
          hourOffset,
        ),
    { className: `custom-job ${selectedClass}`, autoPan: false },
  );

  m.on('mouseover', () => {
    m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  return m;
};

export const makePlanMarker = (feature, latlng, onMarkerClick, translations) => {
  const {
    map: { typeJobDeliveryMarkerTrans, typeJobPickupMarkerTrans, territoryMarkerTrans },
    wizard: {
      orders: { editedOrderPriorityHigh },
    },
  } = translations;
  const {
    properties: { address, labels, isEditing, highPriority, isPickup, territory },
  } = feature;

  const addressLabel = address ? `${address}<br/>` : '';
  const priorityLabel = highPriority
    ? `<div class="high-priority">${editedOrderPriorityHigh}</div>`
    : '';
  const title = labels
    ? `<div class="labels">${priorityLabel}${addressLabel}${labels.join('<br />')}</div>`
    : '';
  const basicIcon = highPriority ? 'plan-icon-high-priority' : 'plan-icon';
  const basicIconClass = isPickup ? `${basicIcon}-pickup` : basicIcon;
  const iconClass = isEditing ? 'plan-editing-icon' : basicIconClass;
  const typeString = isPickup ? typeJobPickupMarkerTrans : typeJobDeliveryMarkerTrans;
  const territoryName = territory
    ? `<div><br/>${territoryMarkerTrans}: ${territory.name}</div>`
    : '';
  const m = marker(latlng, {
    icon: divIcon({
      className: `leaflet-marker-icon-job ${iconClass}`,
      html: '<div></div>',
      iconSize: isEditing ? [35, 35] : [24, 24],
      iconAnchor: isEditing ? [12, 35] : [12, 24],
    }),
  }).bindPopup(`${title}${typeString}${territoryName}`, {
    className: 'custom-job',
    autoPan: false,
  });

  m.on('mouseover', () => {
    m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  return m;
};

export const makeActivityMarker = (
  feature,
  latlng,
  translations,
  hourOffset,
  onMarkerClick,
  highlight,
) => {
  const {
    properties: {
      types,
      jobMarkerColor,
      arr_time: arrival,
      dep_time: departure,
      jobTags,
      tw,
      address,
      labels,
      highPriority,
      territory,
    },
  } = feature;

  const {
    map: { arrivalJobMarkerTrans, departureJobMarkerTrans, territoryMarkerTrans },
  } = translations;

  const typesObj = (types || []).reduce((t, item) => {
    if (!getSafeValue(t, item)) setSafeValue(t, item, 0);
    setSafeValue(t, item, getSafeValue(t, item) + 1);
    return t;
  }, {});

  const typesClass = typesObj.pickup ? 'tour-icon-pickup' : 'tour-icon-delivery';

  const highlightColor = {
    outer: 'yellow',
    inner:
      typesClass === 'tour-icon-pickup'
        ? jobMarkerColor.pickup.inner
        : jobMarkerColor.delivery.inner,
  };

  const color = highlight
    ? highlightColor
    : typesClass === 'tour-icon-pickup'
    ? jobMarkerColor.pickup
    : jobMarkerColor.delivery;

  const options = {
    className: `leaflet-marker-icon-job tour-icon`,
    html: L.Util.template(createTourUnselectedSvg(color.inner, color.outer, highlight)),
    iconSize: [10, 10],
    iconAnchor: [5, 9],
  };

  const title = getTitle(translations, labels, typesObj, highPriority, address);

  const territoryName = territory
    ? `<tr><td>${territoryMarkerTrans}:</td><td>${territory.name}</td></tr>`
    : '';

  const m = marker(latlng, {
    icon: new L.DivIcon(options),
  }).bindPopup(
    createTablePopup(
      title,
      jobTags,
      arrivalJobMarkerTrans,
      arrival,
      departureJobMarkerTrans,
      departure,
      territoryName,
      tw,
      hourOffset,
    ),
    { className: 'custom-job tour-selected', autoPan: false },
  );

  m.on('mouseover', () => {
    m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });

  return m;
};

export const makeMarker = (
  feature,
  latlng,
  display,
  onMarkerClick,
  translations,
  onMarkerDragEnd,
  isSolution,
  hourOffset,
  highlight,
  highlightUnassigned,
) => {
  if (isActivityToDisplay(feature))
    return makeActivityMarker(feature, latlng, translations, hourOffset, onMarkerClick, highlight);
  if (isPointDepot(feature))
    return makeDepotMarker(
      feature,
      latlng,
      onMarkerClick,
      translations,
      onMarkerDragEnd,
      isSolution,
      highlight,
    );
  if (isPointReturnLocation(feature))
    return makeReturnLocationMarker(
      feature,
      latlng,
      onMarkerClick,
      translations,
      onMarkerDragEnd,
      isSolution,
    );
  if (isRouteToDisplay(feature, display))
    return makeJobMarker(
      feature,
      latlng,
      true,
      onMarkerClick,
      translations,
      hourOffset,
      highlight,
      highlightUnassigned,
    );
  return !display.showProblem
    ? makeJobMarker(
        feature,
        latlng,
        false,
        onMarkerClick,
        translations,
        hourOffset,
        highlight,
        highlightUnassigned,
      )
    : makePlanMarker(feature, latlng, onMarkerClick, translations);
};
