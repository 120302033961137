import { cloneDeep, concat, flatten, isEmpty } from 'lodash';
import moment from 'moment';
import config from 'config';
import {
  USAGE_EVENTS,
  clearMapData,
  clearOrders,
  clearSolution,
  clearVehicleProfiles,
  recordUsageEvent,
  setError,
  setMapData,
  setOrders,
  setProblem,
  setSolution,
  setSolutionJson,
  setTerritoriesFromProblemFile,
  setTourParameter,
  submitProblem,
} from 'actions';
import { ORDER_ACTIVITIES, ORDER_PRIORITY } from './csv/config';
import { convertProblemToGeoJSON, convertToGeoJSON } from './GeoJSONConverter';
import { generatePolygonsFromFileProblem } from './territories/TerritoriesFromJson';
import { APP_MODES, getAppMode } from './urlHelpers';
import {
  getStopLocation,
  createStopsNoLocation,
  isDepartureOrArrival,
  isBreakOrReload,
} from './SolutionHelpers';
import { isProdEnv } from './helpers';
import { convertCostsToTourPlanner } from './cost';

const {
  defaults: {
    limits: { maxDistance, shiftTime },
  },
} = config;

const addActivityToJobs = (tasks, activityType) => {
  if (!tasks || isEmpty(tasks)) return [];
  return tasks.map((task) => {
    const newTask = cloneDeep(task);
    newTask.activity = activityType;
    return newTask;
  });
};

export const generateOrdersFromFileProblem = (file) => {
  if (!file || !file.plan || !file.plan.jobs || isEmpty(file.plan.jobs)) return [];

  return concat(
    flatten(
      file.plan.jobs.map(({ id, tasks, priority }) => {
        const deliveries = addActivityToJobs(tasks?.deliveries, ORDER_ACTIVITIES.DELIVERY);
        const pickups = addActivityToJobs(tasks?.pickups, ORDER_ACTIVITIES.PICKUP);
        return flatten(
          concat(deliveries, pickups)
            .filter((task) => !isEmpty(task))
            .map(({ places, activity, demand }) =>
              places
                .filter(({ location }) => !isEmpty(location))
                .map((task) => ({
                  Latitude: task.location.lat,
                  Longitude: task.location.lng,
                  Name: `${id} - ${activity}`,
                  Address: task.tag || id,
                  Demand: demand,
                  ID: id,
                  InternalID: id,
                  Priority: priority,
                  Activity: activity,
                  Group: task.groupId,
                })),
            ),
        );
      }),
    ),
    [],
  );
};

const activitiesToFilter = ['departure', 'arrival', 'break', 'drivingRestTime', 'workingRestTime'];
export const generateOrdersFromFileSolution = (file, stopsNoLocation) => {
  if (!file || !file.tours || isEmpty(file.tours)) return [];

  const validOrders = concat(
    flatten(
      file.tours.map(({ stops }, tourIndex) =>
        flatten(
          concat(stops).map((stop, stopIndex) =>
            stop.activities.map((activity) => {
              const location =
                activity.location || getStopLocation(stop, stopsNoLocation, stopIndex, tourIndex);
              return {
                Latitude: location.lat,
                Longitude: location.lng,
                Name: `${activity.jobId} - ${activity.type}`,
                Address: activity.jobTag || activity.jobId,
                Demand: [0],
                ID: activity.jobId,
                InternalID: activity.jobId,
                Priority: ORDER_PRIORITY.NORMAL,
                Activity: activity.type,
              };
            }),
          ),
        ),
      ),
    ),
    [],
  ).filter((order) => !activitiesToFilter.includes(order.InternalID));

  const unassignedOrders = file.unassigned
    ? file.unassigned.map((job) => ({
        Latitude: 0,
        Longitude: 0,
        Name: job.jobId,
        Address: job.jobId,
        Demand: [0],
        ID: job.jobId,
        InternalID: job.jobId,
        Priority: ORDER_PRIORITY.NORMAL,
      }))
    : [];

  return concat(validOrders, unassignedOrders);
};

export const generateOrdersFromFileJSON = (file, stopsNoLocation = undefined) => {
  if (!file) return [];
  return file.tours && stopsNoLocation
    ? generateOrdersFromFileSolution(file, stopsNoLocation)
    : generateOrdersFromFileProblem(file);
};

export const generateJobsFromSolution = (file) => {
  if (!file || !file.tours || isEmpty(file.tours)) return [];

  const allActivities = file.tours.flatMap((tour) =>
    tour.stops.flatMap((stop) =>
      stop.activities.map((activity) => ({ ...activity, stopLocation: stop.location })),
    ),
  );

  const filteredActivities = allActivities.filter(
    (activity) => !isDepartureOrArrival(activity.jobId) && !isBreakOrReload(activity.jobId),
  );

  const dividedActivities = filteredActivities.reduce(
    ((hash) => (acc, curr) => {
      if (!hash[curr.jobId]) {
        hash[curr.jobId] = [];
        acc.push(hash[curr.jobId]);
      }
      hash[curr.jobId].push(curr);
      return acc;
    })(Object.create(null)),
    [],
  );

  return dividedActivities.map((job) => {
    const jobObject = {
      id: job[0].jobId,
      tasks: {},
    };

    const deliveries = job
      .filter((activity) => activity.type === 'delivery')
      .map((delivery) => {
        const location = delivery.location || delivery.stopLocation;
        return {
          places: [
            {
              duration: 1,
              location,
            },
          ],
          demand: [0],
        };
      });

    const pickups = job
      .filter((activity) => activity.type === 'pickup')
      .map((pickup) => {
        const location = pickup.location || pickup.stopLocation;
        return {
          places: [
            {
              duration: 1,
              location,
            },
          ],
          demand: [0],
        };
      });

    if (deliveries) jobObject.tasks.deliveries = deliveries;
    if (pickups) jobObject.tasks.pickups = pickups;

    return jobObject;
  });
};

export const generateRequestFromSolution = (file) => {
  if (!file) return null;

  return {
    fleet: {},
    plan: {
      jobs: generateJobsFromSolution(file),
    },
  };
};

export const planTour = ({
  dispatch,
  isSolution,
  cookies,
  user,
  oAuth,
  fileObj,
  stopsNoLocation,
  index,
  keepOrders,
}) => {
  if (!isSolution) {
    const apiKey = cookies.apikey;
    dispatch(submitProblem({ json: fileObj, user, oAuth, apiKey, index, keepOrders }));
  } else {
    dispatch(setSolution({ data: fileObj, stopsNoLocation, keepOrders }));
  }
};

export const integrateFileText = (
  txt,
  mode,
  filename,
  user,
  cookies,
  oAuth,
  dispatch,
  isJsonViewer,
  error,
  index,
  keepOrders,
) => {
  let fileObj;
  try {
    fileObj = JSON.parse(txt);
  } catch {
    dispatch(setError(error.invalidFileFormat));
    return;
  }

  const isSolution = !!fileObj.tours;
  if (!isSolution) dispatch(setSolutionJson({ json: true }));

  if (!isSolution && isProdEnv() && (!cookies.apikey || cookies.apikey === '')) {
    dispatch(setError(error.apiKey));
    return;
  }

  let stopsNoLocation;
  if (isSolution) stopsNoLocation = createStopsNoLocation(fileObj.tours);

  const orderList = generateOrdersFromFileJSON(fileObj, stopsNoLocation);
  if (isEmpty(orderList)) {
    dispatch(setError(error.invalidFileFormat));
    dispatch(setSolutionJson({ jsonUserChange: false, jsonPaste: false }));
    return;
  }

  if (!isSolution) {
    const fleet = fileObj.fleet;
    const vehicleProfiles = fleet.profiles.map((profile) => {
      return {
        name: profile.name,
        avoid: profile.avoid,
        exclude: profile.exclude,
        fleetType: profile.type,
        departureTime: profile.departureTime,
        options: profile.options,
      };
    });
    const vehicles = fleet.types.map(({ shifts, ...item }) => {
      return {
        ...item,
        limits: {
          maxDistance: {
            enabled: item.limits?.maxDistance !== undefined,
            value: item.limits?.maxDistance ?? maxDistance,
          },
          shiftTime: {
            enabled: item.limits?.shiftTime !== undefined,
            value: item.limits?.shiftTime ?? shiftTime,
          },
        },
      };
    });
    const startDate = fleet.types[0].shifts[0].start.time;

    dispatch(
      setTourParameter({
        vehicles,
        vehicleProfiles,
        traffic: fleet.traffic,
        offset: moment.parseZone(startDate).utcOffset(),
      }),
    );
  } else {
    dispatch(clearVehicleProfiles(index));
  }
  dispatch(setTourParameter({ fileType: 'json' }));
  dispatch(setOrders(orderList));

  const useGroups = getAppMode().includes(APP_MODES.GROUPS);
  const useAvoidAreas = getAppMode().includes(APP_MODES.AVOID_AREAS);

  let polygons;
  if (useGroups) {
    const { territories = [], groups = [] } =
      !isSolution && generatePolygonsFromFileProblem(fileObj);
    dispatch(setTerritoriesFromProblemFile({ territories, groupAreas: groups }));
    polygons = [...territories, ...groups];
  } else if (useAvoidAreas) {
    const { territories = [], avoidAreas = [] } =
      !isSolution && generatePolygonsFromFileProblem(fileObj);
    dispatch(setTerritoriesFromProblemFile({ territories, avoidAreas }));
    polygons = [...territories, ...avoidAreas];
  } else {
    const { territories = [] } = !isSolution && generatePolygonsFromFileProblem(fileObj);
    dispatch(setTerritoriesFromProblemFile({ territories }));
    polygons = [...territories];
  }

  const problemObj = isSolution ? generateRequestFromSolution(fileObj) : fileObj;
  const geo = isSolution
    ? convertToGeoJSON(fileObj, stopsNoLocation, problemObj.plan.jobs, orderList, null, undefined)
        .geo
    : convertProblemToGeoJSON(problemObj, null, null, orderList, polygons);
  if (geo) dispatch(setMapData({ geo }));
  else dispatch(clearMapData());

  const toSet = {
    territories: polygons,
    orders: orderList,
    json: problemObj,
    fileObj,
    uploaded: true,
    geo,
    filename,
  };
  dispatch(setProblem(toSet));

  dispatch(recordUsageEvent({ event: USAGE_EVENTS.ORDERS_ADD, mode }));

  if (!isJsonViewer || isSolution) {
    planTour({
      dispatch,
      isSolution,
      cookies,
      user,
      oAuth,
      filename,
      fileObj,
      stopsNoLocation,
      keepOrders,
    });
  }

  dispatch(setSolutionJson({ jsonUserChange: false, jsonPaste: false }));
};

export const recalculateMapData = (dispatch, jsonObject, stopsNoLocation, territories) => {
  const isSolution = !!jsonObject.tours;
  const problemObj = isSolution ? generateRequestFromSolution(jsonObject) : jsonObject;
  const orderList = generateOrdersFromFileJSON(jsonObject, stopsNoLocation);

  const geo = isSolution
    ? convertToGeoJSON(
        jsonObject,
        stopsNoLocation,
        problemObj.plan.jobs,
        orderList,
        null,
        undefined,
      ).geo
    : convertProblemToGeoJSON(problemObj, null, null, orderList, territories);
  if (geo) {
    dispatch(setMapData({ geo }));
  } else {
    dispatch(clearMapData());
  }
  dispatch(setSolutionJson({ jsonUserChange: false }));
};

export const recalculateSolutionParams = (
  jsonObject,
  dispatch,
  error,
  filename,
  stopsNoLocation,
  index,
  isDemo,
  ext,
  isImperial,
) => {
  const isSolution = !!jsonObject.tours;
  const fileType = ext === 'json' ? ext : 'csv';

  if (!isSolution) {
    const fleet = jsonObject.fleet;
    const vehicleProfiles = fleet.profiles.map((profile) => {
      return {
        name: profile.name,
        avoid: profile.avoid,
        exclude: profile.exclude,
        fleetType: profile.type,
        departureTime: profile.departureTime,
        options: profile.options,
      };
    });

    const vehicles = fleet.types.map(({ shifts, ...item }) => {
      return {
        ...item,
        costs: convertCostsToTourPlanner({ ...item.costs, isImperial }),
        limits: {
          maxDistance: {
            enabled: item.limits?.maxDistance !== undefined,
            value: item.limits?.maxDistance ?? maxDistance,
          },
          shiftTime: {
            enabled: item.limits?.shiftTime !== undefined,
            value: item.limits?.shiftTime ?? shiftTime,
          },
        },
      };
    });
    const startDate = fleet.types[0].shifts[0].start.time;

    dispatch(
      setTourParameter({
        vehicles,
        vehicleProfiles,
        traffic: fleet.traffic,
        offset: moment.parseZone(startDate).utcOffset(),
        jsonUserChange: true,
        index,
      }),
    );
  }
  dispatch(setTourParameter({ fileType, index }));

  const orderList = generateOrdersFromFileJSON(jsonObject, stopsNoLocation);

  if (!isEmpty(orderList)) {
    dispatch(setOrders(orderList, isDemo, index));
  }

  const useGroups = getAppMode().includes(APP_MODES.GROUPS);
  const useAvoidAreas = getAppMode().includes(APP_MODES.AVOID_AREAS);

  let polygons;
  if (useGroups) {
    const { territories = [], groups = [] } =
      !isSolution && generatePolygonsFromFileProblem(jsonObject);
    dispatch(setTerritoriesFromProblemFile({ territories, groupAreas: groups }));
    polygons = [...territories, ...groups];
  } else if (useAvoidAreas) {
    const { territories = [], avoidAreas = [] } =
      !isSolution && generatePolygonsFromFileProblem(jsonObject);
    dispatch(setTerritoriesFromProblemFile({ territories, avoidAreas }));
    polygons = [...territories, ...avoidAreas];
  } else {
    const { territories = [] } = !isSolution && generatePolygonsFromFileProblem(jsonObject);
    dispatch(setTerritoriesFromProblemFile({ territories }));
    polygons = [...territories];
  }

  const problemObj = isSolution ? generateRequestFromSolution(jsonObject) : jsonObject;
  const geo = isSolution
    ? convertToGeoJSON(
        jsonObject,
        stopsNoLocation,
        problemObj.plan.jobs,
        orderList,
        null,
        undefined,
      ).geo
    : convertProblemToGeoJSON(problemObj, null, null, orderList, polygons);

  const toSet = {
    territories: polygons,
    orders: orderList,
    json: problemObj,
    fileObj: jsonObject,
    uploaded: true,
    geo,
    filename,
    index,
  };

  dispatch(setProblem(toSet));
  recalculateMapData(dispatch, jsonObject, stopsNoLocation, polygons);

  dispatch(setSolutionJson({ jsonUserChange: false }));
};

export const handleJsonDrop = (
  dispatch,
  files,
  mode,
  error,
  appMode,
  cookies,
  oAuth,
  user,
  browseEl,
  index,
  keepOrders,
) => {
  if (files.length !== 1) {
    dispatch(setError(error.invalidFileLength));
    return;
  }
  if (appMode.includes(APP_MODES.OCR)) {
    if (!/\.(csv|json|jpg|jpeg|png|gif)$/.test(files[0].name)) {
      dispatch(setError(error.invalidFileExtension));
      return;
    }
  } else if (appMode.includes(APP_MODES.OCR_AWS, APP_MODES.OCR_AWS_EDIT)) {
    if (!/\.(csv|json|jpg|jpeg|png|gif|pdf)$/.test(files[0].name)) {
      dispatch(setError(error.invalidFileExtension));
      return;
    }
  } else if (!/\.(csv|json)$/.test(files[0].name)) {
    dispatch(setError(error.invalidFileExtension));
    return;
  }

  const reader = new FileReader();
  reader.readAsText(files[0]);

  reader.addEventListener('loadend', () => {
    const { result } = reader;
    const filename = files[0].name;

    if (filename.endsWith('.json')) {
      integrateFileText(
        result,
        mode,
        filename,
        user,
        cookies,
        oAuth,
        dispatch,
        true,
        error,
        index,
        keepOrders,
      );
      browseEl.current.value = '';
    }
  });
};

export const clearAll = (dispatch, shouldClearOrders, shouldClearMap, index) => {
  dispatch(clearSolution());
  dispatch(clearVehicleProfiles(index));
  if (shouldClearOrders) dispatch(clearOrders(index));
  if (shouldClearMap) dispatch(clearMapData());
};
