import { GeoJSON } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import React, { useCallback } from 'react';
import { mapDataKey } from '../../../utils/map/MapCalculations';
import {
  ACTIVITY_DEFAULT_CLUSTER_RADIUS,
  ACTIVITY_MIN_CLUSTER_RADIUS,
  CLUSTER_BREAKPOINT_MAP_ZOOM,
} from './MapMarkers';
import { filterActivities, isActivityToDisplay } from '../../../utils/map/MapFeatureFilters';

const ActivityMarkers = ({
  mapData,
  user,
  display,
  routingRoutes,
  highlightStop,
  makeMapMarkers,
  createClusterIcon,
}) => {
  const filterActivityFeatures = (feature) => isActivityToDisplay(feature);
  const activities = useCallback(() => {
    return filterActivities(mapData, display);
  }, [mapData, display]);

  const Markers = () => (
    <GeoJSON
      key={`${mapDataKey(mapData, user, display, routingRoutes, highlightStop)}-activity-marker`}
      data={activities()}
      pointToLayer={makeMapMarkers}
      filter={filterActivityFeatures}
    />
  );

  return user.mapSettings.showClusters ? (
    <MarkerClusterGroup
      key={`${mapDataKey(mapData, user, display, routingRoutes, highlightStop)}-activity-cluster`}
      iconCreateFunction={createClusterIcon}
      showCoverageOnHover={false}
      maxClusterRadius={(level) =>
        level > CLUSTER_BREAKPOINT_MAP_ZOOM
          ? ACTIVITY_MIN_CLUSTER_RADIUS
          : ACTIVITY_DEFAULT_CLUSTER_RADIUS
      }
    >
      <Markers />
    </MarkerClusterGroup>
  ) : (
    <Markers />
  );
};

export default ActivityMarkers;
