import { isEmpty, last, size } from 'lodash';
import { getStorageValue, setStorageValue, STORAGE_IDS } from './localStorageHelpers';
import { isIterationSolution } from './SolutionHelpers';

export const shouldRememberLastState = () => {
  const cookieFromStorage = getStorageValue(STORAGE_IDS.cookieAgreement, null, true);
  const hasCookies =
    cookieFromStorage && cookieFromStorage.value && cookieFromStorage.value.siteImprovementApproved;
  if (!hasCookies) return false;

  const userFromStorage = getStorageValue(STORAGE_IDS.userState);
  return userFromStorage && userFromStorage.hasStateMemory;
};

export const getStateFromMemory = (stateID, initialValue) => {
  if (!shouldRememberLastState()) return initialValue;
  return getStorageValue(stateID, initialValue);
};

export const setStateToMemory = (stateID, state, transformFn, setAnyway = false) => {
  if (!setAnyway && !shouldRememberLastState()) return;

  const value = transformFn ? transformFn(state) : state;
  setStorageValue(stateID, value);
};

export const isSolutionStored = (sol) => {
  if (!sol) return false;

  const iteration = last(sol.requests[sol.show].iterations);
  return isIterationSolution(iteration);
};

export const isSolutionMonoTour = (solution) => {
  const sol = solution || getStateFromMemory(STORAGE_IDS.solution);
  if (!sol) return false;

  let response = sol;
  if (sol.requests) {
    const request = sol.requests[0];
    const iteration = last(request.iterations);
    const isSolution = isIterationSolution(iteration);
    if (!isSolution) return false;
    response = iteration.response;
  }

  return size(response.tours) === 1 && isEmpty(response.unassigned);
};
