import { concat, flatten, isEmpty, keys, size } from 'lodash';
import { decode } from 'utils/flexpolyline';
import { calculateBBox } from '../GeoJSONConverter';
import { AVOID_TYPES, getPolygonFromBbox } from './TerritoriesHelpers';
import { APP_MODES, getAppMode } from '../urlHelpers';

const avoidAreasFF = getAppMode().includes(APP_MODES.AVOID_AREAS);

const counterClockWise = (a, b, c) => {
  // cross product of two vectors indicate whether an angle is clockwise or counterclockwise
  return (b[0] - a[0]) * (c[1] - a[1]) - (b[1] - a[1]) * (c[0] - a[0]);
};

// get a convex hull with the Graham Scan algorithm
const getConvexHull = (points) => {
  const convexHull = [];
  const lowerMostPoint = points.reduce((prev, curr) => (prev[1] < curr[1] ? prev : curr));
  const sorted = points.sort((a, b) => {
    // sort by angle from right to left
    if (a === lowerMostPoint) return -1;
    if (b === lowerMostPoint) return 1;

    const ccw = counterClockWise(lowerMostPoint, a, b);

    if (ccw === 0) {
      if (a[0] === b[0]) return a[1] < b[1] ? -1 : 1;
      return a[0] < b[0] ? -1 : 1;
    }
    return ccw * -1;
  });

  convexHull.push(sorted[0], sorted[1]);

  for (let i = 2; i < sorted.length; i++) {
    const next = sorted[i];
    let top = convexHull.pop();

    while (
      convexHull[convexHull.length - 1] !== undefined &&
      counterClockWise(convexHull[convexHull.length - 1], top, next) <= 0
    ) {
      top = convexHull.pop();
    }

    convexHull.push(top);
    convexHull.push(sorted[i]);
  }

  const p = convexHull.pop();
  if (counterClockWise(convexHull[convexHull.length - 1], p, lowerMostPoint) > 0)
    convexHull.push(p);

  return convexHull;
};

const getPolygonFromPoints = (points) => {
  if (size(points) <= 2) return getPolygonFromBbox(calculateBBox(points, 10));

  return getConvexHull(points);
};

export const getPlacesFromJobs = (jobs) => {
  return concat(
    flatten(
      jobs.map(({ tasks }) => {
        return flatten(
          concat(tasks.deliveries, tasks.pickups)
            .filter((task) => !isEmpty(task))
            .map(({ places }) =>
              places.filter(({ location }) => !isEmpty(location)).map((place) => place),
            ),
        );
      }),
    ),
    [],
  );
};

export const getCoordsByIdFromPlaces = (places) => {
  const availableTerritories = {};
  const availableGroups = {};

  places.forEach((place) => {
    if (place.territoryIds) {
      place.territoryIds.forEach((id) => {
        if (!availableTerritories[id]) availableTerritories[id] = [];
        availableTerritories[id].push([place.location.lng, place.location.lat]);
      });
    }

    if (getAppMode().includes(APP_MODES.GROUPS) && place.groupId) {
      if (!availableGroups[place.groupId]) availableGroups[place.groupId] = [];
      availableGroups[place.groupId].push([place.location.lng, place.location.lat]);
    }
  });

  return { availableTerritories, availableGroups };
};

export const generateAvoidAreasFromFileProblem = (file) => {
  if (!file || !file.fleet || !file.fleet.profiles || isEmpty(file.fleet.profiles)) return null;
  const avoidArray = [];

  const avoidAreas = file.fleet.profiles.map((f) => f.avoid);
  avoidAreas.forEach((a) => {
    const areas = a?.areas;
    if (areas) {
      areas.forEach((area, index) => {
        let coords;
        let bbox;
        if (area.type === AVOID_TYPES.BOUNDING_BOX) {
          bbox = [area.east, area.south, area.west, area.north];
          coords = getPolygonFromBbox(bbox);
        } else if (area.type === AVOID_TYPES.POLYGON) {
          coords = area.outer.map(({ lng, lat }) => [lng, lat]);
          bbox = calculateBBox(coords);
        } else if (area.type === AVOID_TYPES.ENCODED_POLYGON) {
          const polyline = decode(area.outer).polyline;
          coords = polyline.map((coord) => coord.reverse());
          bbox = coords.reduce(
            ([minX, minY, maxX, maxY], [x, y]) => [
              Math.min(minX, x),
              Math.min(minY, y),
              Math.max(maxX, x),
              Math.max(maxY, y),
            ],
            [180, 90, -180, -90],
          );
        }
        const name = `avoid_${area.type}_${index}`;
        if (coords && bbox)
          avoidArray.push({ coords, bbox, index, name, type: AVOID_TYPES.AVOID_AREA });
      });
    }
  });
  return avoidArray;
};

export const generatePolygonsFromFileProblem = (file) => {
  if (!file || !file.plan || !file.plan.jobs || isEmpty(file.plan.jobs)) return {};

  const allPlaces = getPlacesFromJobs(file.plan.jobs);
  const { availableTerritories, availableGroups } = getCoordsByIdFromPlaces(allPlaces);

  const territories = keys(availableTerritories).map((key, index) => {
    const polygon = getPolygonFromPoints(availableTerritories[key]);
    const bbox = calculateBBox(availableTerritories[key], 10);
    return { coords: polygon, bbox, index, name: key, type: 'territory' };
  });

  if (getAppMode().includes(APP_MODES.GROUPS)) {
    const groups = keys(availableGroups).map((key, index) => {
      const polygon = getPolygonFromPoints(availableGroups[key]);
      const bbox = calculateBBox(availableGroups[key], 10);
      return { coords: polygon, bbox, index, name: key, type: 'group' };
    });

    return { territories, groups };
  }

  if (avoidAreasFF) {
    const avoidAreas = generateAvoidAreasFromFileProblem(file);
    return { territories, avoidAreas };
  }

  return { territories };
};
