import React, { useCallback } from 'react';
import { GeoJSON } from 'react-leaflet';
import { mapDataKey } from 'utils/map/MapCalculations';
import { colors } from '../../global/variables';
import { APP_MODES, getAppMode } from '../../utils/urlHelpers';

const { transparentDarkerColor, areas, avoidArea } = colors;
const useGroups = getAppMode().includes(APP_MODES.GROUPS);
const useAvoidAreas = getAppMode().includes(APP_MODES.AVOID_AREAS);

const MapAreas = ({ areaPolygon, onClick, groupsToShow }) => {
  const showName = useCallback(
    (e) => {
      if (onClick) onClick(e.target.feature.properties);
    },
    [onClick],
  );

  const onHandleSelectArea = (feature, layer) => {
    layer.on({ click: showName });
  };

  const getPolygonStyle = (polygon) => {
    const { index, name, type } = polygon.properties;
    const isAvoidPolygon = name.startsWith('avoid');
    const fillColor = useAvoidAreas && isAvoidPolygon ? avoidArea : areas[index];
    const dashArray = type === 'group' || isAvoidPolygon ? '4' : undefined;

    if (useGroups) {
      return {
        opacity: 1,
        weight: 2,
        color: transparentDarkerColor,
        fillColor: areas[index],
        dashArray: type === 'group' ? '4' : undefined,
      };
    }

    return {
      opacity: 1,
      weight: 2,
      color: transparentDarkerColor,
      fillColor,
      dashArray,
    };
  };

  return areaPolygon.features
    .filter((feature) =>
      useGroups
        ? feature.properties.type !== 'group' || groupsToShow.includes(feature.properties.name)
        : true,
    )
    .map((feature, i) => {
      return (
        <GeoJSON
          key={useGroups || useAvoidAreas ? `${mapDataKey(areaPolygon, i, groupsToShow)}-area` : i}
          data={feature}
          style={getPolygonStyle}
          onEachFeature={onHandleSelectArea}
        />
      );
    });
};
export default MapAreas;
