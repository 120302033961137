import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { head, isEmpty, join, size } from 'lodash';
import {
  getLoadPercentage,
  getNumberOfDepotStops,
  getTotalJobsInTour,
  getVehicleTypeById,
} from 'utils/SolutionHelpers';

import iconDetails from 'global/img/details.svg';
import Button from '../../Form/Button';
import { StopIconStyle, JobIconStyle, StyledIcon } from '../Global/WizardStyled';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import { getTourDemand } from '../../../utils/OrdersHelpers';
import { isMobileDevice } from '../../../utils/helpers';
import { getUrlInitialTourIndex } from '../../../utils/urlHelpers';
import { metersToKilometers, metersToMiles } from '../../../utils/converter';
import { formatPriceWithCurrency } from '../../../utils/formatter';

const initialTourIndex = getUrlInitialTourIndex();

const { secondaryBorderColor, secondaryTextColor, primaryTextColor, aqua, transparentGreyColor } =
  colors;
const { medium } = fontSize;

const StyledTour = styled.div(
  {
    padding: '0.75rem 0rem',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '2.5rem',
    borderBottom: `1px solid ${secondaryBorderColor}`,
    backgroundColor: transparentGreyColor,
    textRendering: 'geometricPrecision',
  },
  ({ isActive }) => ({
    backgroundColor: isActive && 'rgba(255,255,255,0.5)',
  }),
);

const StyledTourSpan = styled.div(
  {
    color: secondaryTextColor,
    fontSize: medium,
    position: 'relative',
  },
  ({ isActive, withButtons }) => ({
    fontWeight: isActive && '700',
    textAlign: withButtons && 'right !important',
  }),
);

const StyledButtonContainer = styled.div({
  width: '3rem',
  display: 'inline-block',
});

const StyledTimesWrapper = styled(StyledTourSpan)`
  div {
    line-height: 1.25rem;
    display: block;
    color: ${secondaryTextColor};
    white-space: nowrap;
  }

  div:last-of-type {
    color: ${primaryTextColor};
  }
`;

const StyledDoubleLineWrapper = styled(StyledTourSpan)`
  div {
    line-height: 1.25rem;
    text-align: center;
    vertical-align: center;
  }
`;

const StyledActiveBorder = styled(StyledTour)(({ isActive }) => ({
  transition: 'all 250ms cubic-bezier(0.600, 0.005, 0.320, 1.000)',
  backgroundColor: isActive && aqua,
}));

const Tour = withTranslation(
  ({
    index,
    isActive,
    onClickTour,
    onTourChange,
    request,
    tour,
    demand,
    fleet,
    translations,
    helpers: { formatDuration },
    user,
  }) => {
    const {
      tourSolution: {
        units: {
          distance: { kilo, mile },
        },
        viewTour,
      },
      wizard: {
        tours: { vehicleLabel },
      },
    } = translations;

    const formattedDuration = tour.statistic.duration
      ? formatDuration(Math.floor(tour.statistic.duration / 60))
      : 'Unknown';

    const onTourChangeClick = useCallback(
      (t) => {
        onTourChange();
        const tourInfo = {
          stopsInTour: t.stops.length - getNumberOfDepotStops(t.stops),
          ordersInTour: getTotalJobsInTour(t.stops),
          dropsInTour: getTourDemand(t, request),
          totalDistanceInTour: t.statistic.distance,
          totalDurationInTour: t.statistic.duration,
          totalCostInTour: t.statistic.cost,
        };
        AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_VIEW, tourInfo);
      },
      [onTourChange, request],
    );

    const onClick = useCallback(() => {
      if (isMobileDevice()) onTourChangeClick(tour);
      onClickTour();
    }, [onClickTour, onTourChangeClick, tour]);

    const tourLoad = !isEmpty(fleet)
      ? getLoadPercentage(demand, getVehicleTypeById(tour.typeId, fleet))
      : null;
    const isMultiDemand = size(tourLoad) > 1;
    const vehicleId = `${vehicleLabel}: ${tour.vehicleId}`;
    const isImperial = user.distance === 'imperial';
    const distanceText = isImperial
      ? `${parseInt(metersToMiles(tour.statistic.distance), 10)} ${mile}`
      : `${parseInt(metersToKilometers(tour.statistic.distance), 10)} ${kilo}`;

    return (
      <>
        <StyledTour isActive={isActive} onClick={onClick} />
        <StyledTour isActive={isActive} onClick={onClick} title={vehicleId}>
          <StyledTourSpan isActive={isActive}>{index + initialTourIndex}</StyledTourSpan>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick} />
        <StyledTour isActive={isActive} onClick={onClick} title={vehicleId}>
          <StyledTourSpan isActive={isActive}>
            <StyledIcon icon={StopIconStyle} />
            {size(tour.stops) - getNumberOfDepotStops(tour.stops)}
          </StyledTourSpan>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick}>
          {!isEmpty(fleet) ? (
            <StyledDoubleLineWrapper isActive={isActive}>
              <div>
                <StyledIcon icon={JobIconStyle} /> {getTotalJobsInTour(tour.stops)}
              </div>
              <div title={join(tourLoad, ', ')}>
                {head(tourLoad)}
                {isMultiDemand && '...'}
              </div>
            </StyledDoubleLineWrapper>
          ) : (
            <StyledTourSpan isActive={isActive} withButtons>
              <div>
                <StyledIcon icon={JobIconStyle} /> {getTotalJobsInTour(tour.stops)}
              </div>
            </StyledTourSpan>
          )}
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick}>
          <StyledTimesWrapper isActive={isActive}>
            <div>
              {formatPriceWithCurrency(parseInt(tour.statistic.cost, 10), user.currency, 0)}
            </div>
            <div>{distanceText}</div>
          </StyledTimesWrapper>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick}>
          <StyledTourSpan isActive={isActive}>{formattedDuration}</StyledTourSpan>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick}>
          <StyledTourSpan isActive={isActive} withButtons>
            {!isMobileDevice() && (
              <StyledButtonContainer>
                <Button
                  inverted={!isActive}
                  noMargin
                  id={`button-view-tour-${index}`}
                  onClick={() => onTourChangeClick(tour)}
                  icon={iconDetails}
                  title={viewTour}
                />
              </StyledButtonContainer>
            )}
          </StyledTourSpan>
        </StyledTour>
        <StyledTour isActive={isActive} onClick={onClick} />
        <StyledActiveBorder isActive={isActive} onClick={onClick} />
      </>
    );
  },
);

export default withTranslation(Tour);
