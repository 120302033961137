import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveToJsonFile } from 'utils/SolutionExport';
import { planTour } from 'utils/apiFilesUploadHelpers';
import { useCookies } from 'react-cookie';
import { last } from 'lodash';
import withTranslation from '../../../hoc/withTranslation';
import { StyledContrastPanelFooter } from '../../Wizard/Global/WizardStyledContrastPanel';
import Button from '../../Form/Button';
import { METHODS } from './DevConsoleHeader';
import { isProdEnv } from '../../../utils/helpers';
import { hasOngoingAsyncRequest } from '../../../utils/SolutionHelpers';

const isProd = isProdEnv();

const OPTIONS = {
  PROBLEM: 'problem',
  SOLUTION: 'solution',
  INTERNAL_TOUR_PLANNER: 'tourPlanner',
  INTERNAL_USER: 'user',
  INTERNAL_ITERATIONS: 'iterations',
};

const DevConsoleFooter = ({
  setIsJsonUploadClicked,
  selectedOption,
  jsonObject,
  tourPlanner,
  oAuth,
  handleSetError,
  translations: {
    devConsoleLabels,
    tourPlanner: { submitTrans, jsonApiKey },
  },
}) => {
  const dispatch = useDispatch();
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const orders = useSelector((state) => state.orders[solution.show]);
  const [cookies] = useCookies(['apikey']);
  const user = useSelector(({ user: stateUser }) => stateUser);
  const loader = useSelector(({ loader: stateLoader }) => stateLoader);
  const [fileName, seFileName] = useState();

  const hasNoApiKey = isProd && (!cookies.apikey || cookies.apikey === '');
  const isDemoMode = tourPlanner.ordersMode === 'demo';
  const hasDepot = !!(tourPlanner && tourPlanner.location && tourPlanner.location.value);
  const minOrders = hasDepot ? 1 : 2;
  const hasOrders = orders && orders.length >= minOrders;
  const isDisabled =
    (!solution.json && !solution.jsonUserChange) ||
    loader.isLoading ||
    (hasNoApiKey && !isDemoMode && !hasOrders) ||
    hasNoApiKey;
  const handleDownloadClick = useCallback(() => {
    const lastIteration = solution && last(solution.requests[solution.show].iterations);

    const toDownload = selectedOption === OPTIONS.SOLUTION ? lastIteration.response : jsonObject;
    const fileToDownload =
      selectedOption === OPTIONS.SOLUTION ? `${fileName}_solution.json` : fileName;
    if (toDownload) saveToJsonFile(toDownload, fileToDownload);
  }, [solution, selectedOption, jsonObject, fileName]);

  const handlePlanTour = useCallback(() => {
    handleSetError();
    const lastIterationRequest =
      solution && last(solution.requests[solution.show].iterations).request;

    planTour({
      dispatch,
      mode: 'upload',
      isSolution: !!lastIterationRequest.fileObj.tours,
      cookies,
      user,
      oAuth,
      fileObj: lastIterationRequest.fileObj,
      index: solution.show,
      keepOrders: tourPlanner.ordersMode !== 'json_import',
    });
  }, [dispatch, cookies, user, oAuth, solution, handleSetError, tourPlanner.ordersMode]);

  useEffect(() => {
    const lastReqName = solution && solution.requests[solution.show]?.name;
    const cut = lastReqName && lastReqName?.endsWith('.csv') ? -4 : -5;
    seFileName(
      lastReqName && !METHODS.includes(lastReqName)
        ? lastReqName.slice(0, cut)
        : tourPlanner.ordersMode,
    );
  }, [solution, tourPlanner.ordersMode]);

  return (
    <StyledContrastPanelFooter data-test-id="jsonViewerPlanTour" isDouble isFullWidth jsonViewer>
      <Button
        inverted
        contrast
        disabled={isDisabled}
        text={submitTrans}
        title={solution.json && hasNoApiKey ? jsonApiKey : ''}
        panel
        onClick={handlePlanTour}
        dataTestId="JVPlanTour"
      />
      <Button
        text={devConsoleLabels.uploadJson}
        panel
        disabled={loader.isLoading || hasOngoingAsyncRequest(solution.requests)}
        onClick={() => setIsJsonUploadClicked(true)}
        dataTestId="JVUploadJson"
      />
      <Button text={devConsoleLabels.buttonDownloadLabel} onClick={handleDownloadClick} panel />
    </StyledContrastPanelFooter>
  );
};

export default withTranslation(DevConsoleFooter);
