import { includes, isNumber } from 'lodash';

export const isRouteToDisplay = (feature, display) =>
  !display.showUnassigned && includes(display.routeIds, feature.properties.routeId);

export const isActivityToDisplay = (feature) => feature.properties.stopLocation;

export const isOriginalLine = (feature) => feature.properties.isOriginalLocationLine;

export const isPointDepot = (feature) =>
  feature.properties.isDepot ||
  (!!feature.properties.jobIds && includes(feature.properties.jobIds, 'departure'));

export const isPointReturnLocation = (feature) =>
  feature.properties.isReturnLocation ||
  (!!feature.properties.jobIds && includes(feature.properties.jobIds, 'arrival'));

export const filterDepot = (mapData, isDepot) =>
  mapData.geo.features.filter((feature) =>
    isDepot
      ? isPointDepot(feature) === isDepot || isPointReturnLocation(feature) === isDepot
      : isPointDepot(feature) === isDepot && isPointReturnLocation(feature) === isDepot,
  );

export const filterActivities = (mapData, display) =>
  mapData.geo.features.filter(
    (feature) => isActivityToDisplay(feature) && isRouteToDisplay(feature, display),
  );

export const isUnassignedJob = ({ properties: { routeId } }) => !isNumber(routeId);

export const isLineToDisplay = (feature, routingData, showStraightRouteLines) =>
  feature.properties.isRouting || routingData.requestError || showStraightRouteLines;

export const filterPoint = (feature, display) =>
  display.showProblem || isRouteToDisplay(feature, display) || isUnassignedJob(feature);

export const filterLineString = (feature, display, routingData, showStraightRouteLines) =>
  !display.showProblem &&
  !display.showUnassigned &&
  isRouteToDisplay(feature, display) &&
  isLineToDisplay(feature, routingData, showStraightRouteLines);

export const filterOriginalLocation = (feature, display) =>
  !display.showProblem &&
  !display.showUnassigned &&
  isOriginalLine(feature) &&
  isRouteToDisplay(feature, display);

export const featureFilter = (feature, display, routingData, showStraightRouteLines = false) =>
  feature.geometry.type === 'Point'
    ? filterPoint(feature, display)
    : filterLineString(feature, display, routingData, showStraightRouteLines);

export const featureFilterCluster = (feature, display, routingData, showStraightRouteLines) =>
  featureFilter(feature, display, routingData, showStraightRouteLines);

export const featureFilterUnselectedMarker = (feature, display) =>
  feature.geometry.type === 'Point' &&
  !isRouteToDisplay(feature, display) &&
  !isPointDepot(feature) &&
  !isPointReturnLocation(feature) &&
  !isActivityToDisplay(feature);

export const featureFilterUnselectedTourLine = (feature, display) =>
  !isRouteToDisplay(feature, display);

export const isPedestrianRoute = (feature) => feature.properties.mode === 'pedestrian';
