import { cloneDeep } from 'lodash';
import {
  SELECT_TOUR_BY_ID,
  CLEAR_MAP_DATA,
  GET_SOLUTION,
  CLEAR_SOLUTION,
  DELETE_SOLUTION,
  SHARE_TOUR_BY_ID,
  SELECT_UNASSIGNED,
  SET_SHOW_PROBLEM,
} from '../actions';
import { getStateFromMemory, setStateToMemory } from '../utils/MemoryHelpers';
import { STORAGE_IDS } from '../utils/localStorageHelpers';

const defaultState = {
  display: {
    showProblem: true,
    showUnassigned: false,
    routeIds: [],
    sharedRouteIds: [],
  },
};

const stateID = STORAGE_IDS.mapContainer;
const initialState = getStateFromMemory(stateID, cloneDeep(defaultState));

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TOUR_BY_ID: {
      const newState = {
        display: {
          showProblem: false,
          showUnassigned: false,
          routeIds: [action.payload],
          sharedRouteIds: [...state.display.sharedRouteIds],
        },
      };
      setStateToMemory(stateID, newState);
      return newState;
    }
    case SELECT_UNASSIGNED: {
      const newState = { ...state };
      newState.display.showUnassigned = action.payload;
      setStateToMemory(stateID, newState);
      return newState;
    }
    case SET_SHOW_PROBLEM: {
      const newState = { ...state };
      if (action.payload === true) newState.display.routeIds = [];
      newState.display.showProblem = action.payload;
      setStateToMemory(stateID, newState);
      return newState;
    }
    case SHARE_TOUR_BY_ID: {
      const sharedRouteIds = [...state.display.sharedRouteIds];
      sharedRouteIds.push(action.payload);
      const newState = {
        display: {
          showProblem: false,
          showUnassigned: state.display.showUnassigned,
          routeIds: state.display.routeIds,
          sharedRouteIds,
        },
      };
      setStateToMemory(stateID, newState);
      return newState;
    }
    case CLEAR_MAP_DATA: {
      const newState = cloneDeep(defaultState);
      setStateToMemory(stateID, newState);
      return newState;
    }
    case GET_SOLUTION: {
      const newState = action.payload.error ? state : cloneDeep(defaultState);
      newState.display.showProblem = !!action.payload.error;
      setStateToMemory(stateID, newState);
      return newState;
    }
    case CLEAR_SOLUTION: {
      const newState = cloneDeep(defaultState);
      setStateToMemory(stateID, newState);
      return newState;
    }
    case DELETE_SOLUTION: {
      const newState = cloneDeep(defaultState);
      if (newState.display.routeIds.length === 0) {
        newState.display.routeIds.push(0);
      }
      setStateToMemory(stateID, newState);
      return newState;
    }
    default:
      return state;
  }
};
