import React, { useCallback, useState, useEffect } from 'react';
import Label from 'components/Form/Label';
import { StyledTextInput } from './WizardStyled';

const NumericInput = ({
  inputLabel,
  initialValue,
  id,
  onChange,
  minValue = 0.1,
  maxValue = 10000,
  theme = 'default',
  stepValue = 0.1,
  integer = false,
  small,
}) => {
  const [updateParameter, setUpdateParameter] = useState(initialValue);
  const handleOnChange = useCallback(
    (e) => {
      const value = e.target.value && parseFloat(e.target.value);
      setUpdateParameter(value);
      e.target.focus();
    },
    [updateParameter, setUpdateParameter],
  );

  useEffect(() => {
    setUpdateParameter(initialValue);
  }, [initialValue]);

  const handleOnBlur = useCallback(
    (e) => {
      const value = parseFloat(e.target.value || 0);
      if (value >= minValue && value <= maxValue && onChange) {
        const toReturn = integer ? parseInt(value, 10) : value;
        onChange(toReturn, id);
        setUpdateParameter(toReturn);
      } else {
        setUpdateParameter(initialValue);
      }
    },
    [minValue, maxValue, onChange, initialValue, id, integer],
  );
  return (
    <div>
      <Label theme={theme}>{inputLabel}</Label>
      <StyledTextInput
        theme={theme}
        id={id}
        small={small}
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        min={minValue}
        max={maxValue}
        step={stepValue}
        value={updateParameter}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
    </div>
  );
};

export default NumericInput;
