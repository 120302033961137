import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isNaN, keys, size } from 'lodash';
import { setLoader, setUserCookies } from 'actions';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { getAsset } from 'utils/helpers';
import { StyledIcon, StyledInfoEllipsis, StyledText } from '../Global/WizardStyled';
import Button from '../../Form/Button';
import { buildSample, isCategoryConfigured } from '../../../utils/csv/ManualConfigHelpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import {
  StyledContrastPanel,
  StyledContrastPanelColumn,
  StyledContrastPanelColumnsContainer,
  StyledContrastPanelFooter,
  StyledContrastPanelHeader,
  StyledContrastPanelHeaderText,
  StyledContrastPanelTableHeader,
} from '../Global/WizardStyledContrastPanel';
import parseCSV from '../../../utils/csv/parser';
import { sanitizeOrder } from '../../../utils/csv/helper';
import iconEdit from '../../../global/img/edit.svg';
import iconArrow from '../../../global/img/shuffel.svg';
import exclamation from '../../../global/img/exclamation-fill.svg';
import { colors, fontSize } from '../../../global/variables';
import { getAddressLocation } from '../../../utils/GeoCoder';
import { getSafeValue } from '../../../utils/security';

const { aqua, white, negativeTextColor } = colors;
const { smaller } = fontSize;

const getColor = (hasValue, isValid) => {
  if (!hasValue || isValid < 0) return white;
  if (isValid === 11 || isValid === 1) return aqua;
  return white;
};

const StyledFieldValue = styled.div(({ hasValue, isValid }) => ({
  color: getColor(hasValue, isValid),
  textTransform: hasValue ? 'none' : 'uppercase',
  fontWeight: hasValue && 500,
  lineHeight: '1rem',
  marginTop: '1rem',
  marginBottom: '0.6rem',
  paddingRight: '0.5rem',
  opacity: hasValue ? 1 : 0.4,
  fontSize: hasValue ? '0.8rem' : '0.7rem',
  wordBreak: 'break-word',
}));

const StyledFieldAction = styled.div({
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  cursor: 'pointer',
  opacity: 0.5,
  '&:hover': {
    opacity: 1,
  },
});

const StyledValueInfo = styled.div({
  display: 'block',
  fontSize: smaller,
  color: negativeTextColor,
  backgroundImage: `url(${getAsset(exclamation)})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left 4px',
  padding: '0.2rem 0 0 16px',
  textTransform: 'uppercase',
});

const OrdersCSVConfigConfirm = ({
  uploadedFile,
  csvConfig,
  onConfirm,
  onClose,
  oAuth,
  user,
  tourPlanner,
  translations: {
    csv,
    wizard: {
      orders: { csvConfigTrans },
    },
  },
}) => {
  const dispatch = useDispatch();
  const handleSetLoader = useCallback((data) => dispatch(setLoader(data)), [dispatch]);
  const handleSetUserCoookies = useCallback((data) => dispatch(setUserCookies(data)), [dispatch]);
  const [parsedRecords, setParsedRecords] = useState(null);
  const [currentRecordIndex, setCurrentRecordIndex] = useState(-1);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [hasValidAddress, setHasValidAddress] = useState(-1);
  const [requireCookies, setRequireCookies] = useState(null);

  useEffect(() => {
    if (requireCookies !== null) return;
    setRequireCookies(user && !user.cookies.accept);
  }, [user, requireCookies, setRequireCookies]);

  useEffect(() => {
    if (currentRecordIndex < 0 || !parsedRecords) return;

    const categories = keys(csv).map((field) => field.replace('Trans', ''));
    const sampleRecord = sanitizeOrder(
      getSafeValue(parsedRecords, currentRecordIndex),
      csvConfig,
      currentRecordIndex,
      true,
    );
    const sampleData = buildSample(sampleRecord, categories);
    setCurrentRecord(sampleData);
    if (!isNaN(parseFloat(sampleRecord.Latitude)) && !isNaN(parseFloat(sampleRecord.Longitude))) {
      setHasValidAddress(11);
    } else if (sampleData[0].category === 'address') {
      const address = sampleData[0].value;
      getAddressLocation(address, oAuth).then((result) => {
        const isValidAddress = result && result.position;
        setHasValidAddress(isValidAddress ? 1 : 0);
      });
    } else {
      setHasValidAddress(10);
    }
  }, [
    currentRecordIndex,
    parsedRecords,
    oAuth,
    csv,
    csvConfig,
    setCurrentRecord,
    setHasValidAddress,
  ]);

  useEffect(() => {
    if (!uploadedFile) return;

    handleSetLoader({ isLoading: true });
    parseCSV(uploadedFile.file).then((sample) => {
      setParsedRecords(sample);
      handleSetLoader({ isLoading: false });
      setCurrentRecordIndex(0);
    });
  }, [uploadedFile, handleSetLoader, setCurrentRecordIndex, setParsedRecords]);

  const getFieldValue = (data) => {
    if (data.value) return data.value;
    return isCategoryConfigured(data.category, csvConfig)
      ? ''
      : csvConfigTrans.configUnassignedValue;
  };

  const onConfirmClick = useCallback(() => {
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_CSV_CONFIG, { action: 'confirm' });
    onConfirm();
  }, [onConfirm]);

  const setCookieParam = useCallback(
    (value) => {
      AmplitudeService.setOptIn(value);
      handleSetUserCoookies(value);
      if (value) {
        AmplitudeService.setFleetConfiguration(tourPlanner);
        AmplitudeService.setUserParams(user);
      }
      AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_CSV_CONFIG, {
        action: 'cookies',
        accept: value,
      });
    },
    [handleSetUserCoookies, tourPlanner, user],
  );

  const handleSwitch = useCallback(
    (e) => {
      const approved = e.target.checked;
      setCookieParam(approved);
    },
    [setCookieParam],
  );

  const onReviewNext = useCallback(() => {
    const next = currentRecordIndex >= size(parsedRecords) - 1 ? 0 : currentRecordIndex + 1;
    setCurrentRecordIndex(next);
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_CSV_CONFIG, { action: 'preview next' });
  }, [setCurrentRecordIndex, currentRecordIndex, parsedRecords]);

  const onEdit = useCallback(() => {
    AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_CSV_CONFIG, { action: 'edit' });
    onClose();
  }, [onClose]);

  const getValueTitle = useCallback(
    (index) => {
      if (index > 0) return '';
      if (hasValidAddress !== 0 && hasValidAddress !== 10) return '';
      return hasValidAddress === 0
        ? csvConfigTrans.configInvalidAddress
        : csvConfigTrans.configInvalidCoordinates;
    },
    [csvConfigTrans, hasValidAddress],
  );

  return (
    <>
      <StyledContrastPanel>
        <StyledContrastPanelHeader>
          <StyledText bold contrast>
            {csvConfigTrans.configConfirmHeaderTitle}
          </StyledText>
          <StyledContrastPanelHeaderText paddingBottom>
            {csvConfigTrans.configConfirmHeaderText}
          </StyledContrastPanelHeaderText>
          {requireCookies && (
            <div>
              <input
                id="input-cookies-enable-csv-settings"
                type="checkbox"
                checked={user.cookies.accept}
                onChange={handleSwitch}
              />{' '}
              <label className="custom-switcher" htmlFor="input-cookies-enable-csv-settings">
                T
              </label>
              <span className="label-custom-switcher inverted small">
                {csvConfigTrans.configConfirmCookies}
              </span>
            </div>
          )}
          <StyledContrastPanelTableHeader hasAction>
            <div>{csvConfigTrans.configFieldsTitleCategory}</div>
            <div>{csvConfigTrans.configFieldsTitleValue}</div>
            <StyledFieldAction>
              {hasValidAddress % 10 === 1 && (
                <StyledIcon iconUrl={iconArrow} sizeL onClick={onReviewNext} />
              )}
            </StyledFieldAction>
          </StyledContrastPanelTableHeader>
        </StyledContrastPanelHeader>
        {currentRecord && (
          <StyledContrastPanelColumnsContainer>
            {currentRecord.map((data, index) => (
              <StyledContrastPanelColumn key={index} hasAction>
                <StyledInfoEllipsis
                  alt={getSafeValue(csv, `${data.category}Trans`)}
                  title={getSafeValue(csv, `${data.category}Trans`)}
                >
                  {getSafeValue(csv, `${data.category}Trans`)}
                </StyledInfoEllipsis>
                <StyledFieldValue
                  hasValue={!!data.value}
                  isValid={index === 0 ? hasValidAddress : 1}
                  title={getValueTitle(index)}
                >
                  {getFieldValue(data)}
                  <StyledValueInfo>{getValueTitle(index)}</StyledValueInfo>
                </StyledFieldValue>
                <StyledFieldAction onClick={onEdit}>
                  <StyledIcon iconUrl={iconEdit} />
                </StyledFieldAction>
              </StyledContrastPanelColumn>
            ))}
          </StyledContrastPanelColumnsContainer>
        )}
        <StyledContrastPanelFooter>
          <Button
            id="button-csv-config-confirm"
            text={csvConfigTrans.configButtonConfirm}
            onClick={onConfirmClick}
            disabled={hasValidAddress % 10 === 0}
          />
        </StyledContrastPanelFooter>
      </StyledContrastPanel>
    </>
  );
};

export default withTranslation(OrdersCSVConfigConfirm);
