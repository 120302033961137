import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import withTranslation from 'hoc/withTranslation';
import { RETURN_LOCATION_MODE, setTourParameter } from 'actions';
import {
  isActivityToDisplay,
  isPointDepot,
  isPointReturnLocation,
  isUnassignedJob,
} from '../../../utils/map/MapFeatureFilters';
import { makeMarker } from '../../../utils/map/MapMarkersUtils';
import { createClusterCustomIcon } from '../../../utils/map/MapClusterUtils';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { getLocationAddress } from '../../../utils/GeoCoder';
import { getSafeValue } from '../../../utils/security';
import ActivityMarkers from './ActivityMarkers';
import StartEndLocations from './StartEndLocations';
import StopJobMarkers from './StopJobMarkers';
import UnselectedMarkers from './UnselectedMarkers';

export const DEFAULT_CLUSTER_RADIUS = 50;
export const MIN_CLUSTER_RADIUS = 40;
export const CLUSTER_BREAKPOINT_MAP_ZOOM = 12;

export const ACTIVITY_DEFAULT_CLUSTER_RADIUS = 100;
export const ACTIVITY_MIN_CLUSTER_RADIUS = 20;

const MapMarkers = ({
  mapData,
  map,
  user,
  display,
  routingRoutes,
  tourData,
  routingData,
  isSolution,
  activateTour,
  showUnassigned,
  translations,
  oAuth,
  tourPlanner,
}) => {
  const { routeIds } = display;

  const dispatch = useDispatch();
  const hourOffset = tourPlanner.offset;
  const displayUnselected = isSolution && tourData;

  const [highlightStop, setHighlightStop] = useState({ id: -1, tourId: 0 });
  const [highlightUnassigned, setHighlightUnassigned] = useState(false);
  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter(parameter)),
    [dispatch],
  );

  const onMarkerClick = useCallback(
    (feature) => {
      const tourId = feature.properties.routeId;
      const id = isActivityToDisplay(feature)
        ? feature.properties.stopIndex
        : feature.properties.jobOrder;
      const isUnassigned = isUnassignedJob(feature);

      setHighlightStop((prev) =>
        prev.id === id || prev.tourId !== tourId ? { id: -1, tourId } : { id, tourId },
      );
      setHighlightUnassigned((prev) => isUnassigned && !prev);

      if (isUnassigned) showUnassigned();
      if (tourId === undefined) return;
      const tour = getSafeValue(tourData.tours, tourId);
      activateTour(tourId, tour);
      AmplitudeService.log(AMPLITUDE_EVENTS.MAP_MARKER_ACTIVATE_TOUR);
    },
    [display, isSolution, tourData, activateTour, showUnassigned],
  );

  const onMarkerDragEnd = useCallback(
    (m, feature) => {
      getLocationAddress(m.getLatLng(), oAuth).then((item) => {
        const loc = {
          label: item.address.label,
          value: item.position,
        };

        if (isPointDepot(feature)) {
          const toSet = { location: loc };
          if (tourPlanner.returnLocationMode === RETURN_LOCATION_MODE.START)
            toSet.returnLocation = loc;
          handleSetTourParameter(toSet);
        } else if (isPointReturnLocation(feature)) handleSetTourParameter({ returnLocation: loc });
      });
    },
    [oAuth, tourPlanner],
  );

  const makeMapMarkers = useCallback(
    (feature, latlng) => {
      const highlight =
        routeIds[0] === highlightStop.tourId &&
        (feature.properties.jobOrder === highlightStop.id ||
          feature.properties.stopIndex === highlightStop.id);
      return makeMarker(
        feature,
        latlng,
        display,
        onMarkerClick,
        translations,
        onMarkerDragEnd,
        isSolution,
        hourOffset,
        highlight,
        highlightUnassigned,
      );
    },
    [
      display,
      translations,
      isSolution,
      hourOffset,
      highlightStop,
      onMarkerClick,
      onMarkerDragEnd,
      routeIds,
      highlightUnassigned,
    ],
  );

  const createClusterIcon = useCallback(
    (cluster) => {
      return createClusterCustomIcon(cluster, highlightStop, map, translations);
    },
    [highlightStop, translations, map, translations],
  );

  return (
    <>
      <UnselectedMarkers
        makeMapMarkers={makeMapMarkers}
        mapData={mapData}
        user={user}
        display={display}
        routingRoutes={routingRoutes}
        displayUnselected={displayUnselected}
        highlightUnassigned={highlightUnassigned}
      />

      <StopJobMarkers
        makeMapMarkers={makeMapMarkers}
        mapData={mapData}
        user={user}
        display={display}
        routingRoutes={routingRoutes}
        highlightStop={highlightStop}
        createClusterIcon={createClusterIcon}
        isSolution={isSolution}
        routingData={routingData}
      />

      {user.mapSettings.showStops && (
        <>
          <StartEndLocations
            makeMapMarkers={makeMapMarkers}
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            isSolution={isSolution}
          />
          <ActivityMarkers
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            highlightStop={highlightStop}
            makeMapMarkers={makeMapMarkers}
            createClusterIcon={createClusterIcon}
          />
        </>
      )}
    </>
  );
};

export default withTranslation(MapMarkers);
