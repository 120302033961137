import React, { useCallback, useEffect, useState } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { convertCostsFromTourPlanner } from 'utils/cost';
import { first, head, join, last } from 'lodash';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { fontSize } from 'global/variables';
import { getDiffInDays } from 'utils/timeFormatHelpers';
import {
  StyledFullWidthContainer,
  StyledText,
  StyledWizardContent,
  StyledWizardH1,
} from '../Global/WizardStyled';
import Stops from './Stops';
import Label from '../../Form/Label';
import {
  getDemand,
  getLastValidIteration,
  getLoadPercentage,
  getNumberOfDepotStops,
  getVehicleTypeById,
  isDemandVisible,
} from '../../../utils/SolutionHelpers';
import TourDispatch from '../TourDispatch/TourDispatch';
import { isMobileDevice } from '../../../utils/helpers';
import { getCSVConfigLastDemandLabel } from '../../../utils/csv/customConfig';
import { getRoutingTourInfo, selectTourById } from '../../../actions';
import { getSafeValue } from '../../../utils/security';
import { formatPriceWithCurrency } from '../../../utils/formatter';
import { StyledTourDispatcherContainer } from '../Wizard';
import { tourToRoutingRequest } from '../../../utils/RoutingConverter';

const { small } = fontSize;

const StyledGroup = styled.div(({ noMarginTop }) => ({
  display: 'grid',
  justifyContent: 'space-between',
  marginTop: noMarginTop || '1rem',
  gridTemplateColumns: '1.6fr 1.8fr 1fr 1fr',
  marginBottom: '1rem',
}));

const StyledFirstGroup = styled.div`
  flex: 1 1 0;
  margin-right: 1rem;
`;

const StyledSecondGroup = styled.div`
  flex: 1 1 0;
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledLabelContainer = styled.div({
  width: '50%',
});

const Step4Container = ({
  user,
  tourData: { fleet, plan, tours = [] },
  selectedTours,
  solution,
  tourPlanner,
  orders,
  helpers: { formatDuration },
  onSearchId,
  oAuth,
  translations,
}) => {
  const dispatch = useDispatch();
  const handleSelectTourById = useCallback((index) => dispatch(selectTourById(index)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest(
            { ...tour, routeId: index, solutionId: solution.show },
            tourPlanner,
          ),
        }),
      ),
    [dispatch, oAuth, tourPlanner, solution.show],
  );

  const [demandLabel, setDemandLabel] = useState('');
  const [isDemandVisibleLocal, setIsDemandVisibleLocal] = useState('');
  const {
    tourSolution: {
      vehicleType,
      startEndDates,
      units: {
        distance: { kilo, mile },
      },
    },
    wizard: {
      tours: { vehicleLabel },
      toursDetails: {
        toursDetailsTitle,
        stopsTrans,
        dropsTrans,
        durationTrans,
        costTrans,
        distanceTrans,
        loadTrans,
      },
    },
    tourSolution: { shiftExtended, day },
  } = translations;

  useEffect(() => {
    const demandVisible = isDemandVisible(orders, tourPlanner);
    let label = '';
    if (demandVisible && !isMobileDevice()) {
      label = getCSVConfigLastDemandLabel(tourPlanner, 'demand', dropsTrans);
    }

    setIsDemandVisibleLocal(demandVisible);
    setDemandLabel(label);
  }, [orders, tourPlanner, dropsTrans, setDemandLabel, setIsDemandVisibleLocal]);

  const tourId = first(selectedTours) ?? 0;
  const tour = getSafeValue(tours, tourId);
  const isImperial = user.distance === 'imperial';
  const formattedDetails = convertCostsFromTourPlanner({
    distance: tour.statistic.distance,
    isImperial,
  });
  const formattedDuration = tour.statistic.duration
    ? formatDuration(Math.floor(tour.statistic.duration / 60))
    : 'Unknown';

  const startingDate = moment(head(tour.stops).time.departure)
    .utc()
    .utcOffset(tourPlanner.offset)
    .format('LT');
  const endingDate = moment(last(tour.stops).time.departure)
    .utc()
    .utcOffset(tourPlanner.offset)
    .format('LT');

  const diffDays = getDiffInDays(
    last(tour.stops).time.departure,
    head(tour.stops).time.arrival,
    tourPlanner.offset,
  );
  const diffDaysTitle = diffDays
    ? `${shiftExtended} ${diffDays} ${day}${diffDays > 1 ? 's' : ''}`
    : '';
  const totalDetails = {
    distance: formattedDetails.distance ? formattedDetails.distance.toFixed(2) : 0,
    time: formattedDuration,
    cost: tour.statistic.cost.toFixed(2),
  };
  const request = getLastValidIteration(solution.requests[solution.show]).request;
  const tourDemand = getDemand({ tour, plan });
  const tourLoad = getLoadPercentage(tourDemand, getVehicleTypeById(tour.typeId, fleet));

  useEffect(() => {
    handleSelectTourById(tourId);
    handleGetRoutingTourInfo(tourId, tour);
  }, [tour]);

  return (
    <StyledWizardContent>
      <StyledWizardH1 noMargin marginTop>
        {toursDetailsTitle}
      </StyledWizardH1>
      <StyledLabelContainer>
        <Label>{`${vehicleLabel}: ${tour.vehicleId}`}</Label>
      </StyledLabelContainer>
      {tour && request && (
        <StyledTourDispatcherContainer step4>
          <TourDispatch
            user={user}
            selectedTours={selectedTours}
            solution={solution}
            tourPlanner={tourPlanner}
            tour={tour}
            request={request}
            origin="tour details"
            openIdSearch={() => onSearchId()}
            fleet={fleet}
            oAuth={oAuth}
            orders={orders}
          />
        </StyledTourDispatcherContainer>
      )}
      <StyledGroup>
        <StyledFirstGroup>
          <Label>{distanceTrans}</Label>
          <StyledText>
            {totalDetails.distance} {isImperial ? mile : kilo}
          </StyledText>
        </StyledFirstGroup>
        <StyledFirstGroup>
          <Label>{durationTrans}</Label>
          <StyledText>{totalDetails.time}</StyledText>
        </StyledFirstGroup>
        <StyledFirstGroup>
          <Label>{costTrans}</Label>
          <StyledText>
            {formatPriceWithCurrency(Math.round(totalDetails.cost), user.currency, 0)}
          </StyledText>
        </StyledFirstGroup>
        <StyledFirstGroup>
          <Label>{stopsTrans}</Label>
          <StyledText>{tour.stops.length - getNumberOfDepotStops(tour.stops)}</StyledText>
        </StyledFirstGroup>
      </StyledGroup>
      <StyledGroup noMarginTop="1rem">
        <StyledSecondGroup title={tour.typeId}>
          <Label>{vehicleType}</Label>
          <StyledText overflowControl title={tour.typeId}>
            {tour.typeId}
          </StyledText>
        </StyledSecondGroup>
        <StyledFirstGroup>
          <Label>{startEndDates}</Label>
          <StyledText
            title={diffDaysTitle}
            fontSize={small}
            diffDays={diffDays >= 1 ? `+${diffDays}` : ''}
          >{`${startingDate}/${endingDate}`}</StyledText>
        </StyledFirstGroup>
        {isDemandVisibleLocal && (
          <StyledFirstGroup>
            <Label>{isDemandVisibleLocal ? demandLabel : dropsTrans}</Label>
            <StyledText>{join(tourDemand, ', ')}</StyledText>
          </StyledFirstGroup>
        )}
        {tourLoad && (
          <StyledFirstGroup>
            <Label>{loadTrans}</Label>
            <StyledText>{join(tourLoad, ', ')}</StyledText>
          </StyledFirstGroup>
        )}
      </StyledGroup>
      <StyledFullWidthContainer>
        <Stops
          stops={tour.stops}
          request={request}
          tourPlanner={tourPlanner}
          user={user}
          demandLabel={demandLabel}
          isDemandVisible={isDemandVisibleLocal}
          tourId={tourId}
          typeId={tour.typeId}
        />
      </StyledFullWidthContainer>
    </StyledWizardContent>
  );
};

export default withTranslation(Step4Container);
