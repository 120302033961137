function getBreakIcon() {
  return `<svg id="breakIcon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22 32" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
    <path d="" fill="none" stroke="#3f5787" stroke-width="0.5"/>
      <g transform="matrix(1.659827 0 0 1.533335-8.438331-6.881853)">
        <path d="M-1.593911,-0.967322c2.061493-.122052.953363.579795-.425621,1.150348" transform="matrix(.999982 0 0 1.785767 15.441892 13.717235)" fill="none" stroke="#fff" stroke-width="0.3"/>
        <path d="M-0.271486,-0.679835h2.0151c.000001,1.01516-.000003,2.461452-1.439357,2.461452h-1.151485c-1.437046,0-1.437048-1.446292-1.437047-2.461454l2.012789.000002Z" transform="matrix(1.368168 0 0 1.90575 11.561466 12.295599)" fill="#fff" stroke-width="0"/>
        <path d="M-0.016264,-1.435604c.705338,1.545116-.943336,1.545116,0,3.090233" transform="translate(9.829381 8.686118)" fill="none" stroke="#fff" stroke-width="0.3"/>
        <path d="M-0.016264,-1.435604c.705338,1.545116-.943336,1.545116,0,3.090233" transform="translate(12.301569 8.686118)" fill="none" stroke="#fff" stroke-width="0.3"/>
        <path d="M-0.016264,-1.435604c.705338,1.545116-.943336,1.545116,0,3.090233" transform="translate(11.073141 8.686118)" fill="none" stroke="#fff" stroke-width="0.3"/>
      </g>
    <path d="" fill="none" stroke="#3f5787" stroke-width="0.5"/>
  </svg>`;
}

function getReloadIcon() {
  return `<svg id="reloadIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 32">
    <path d="M355.936917,314.118636l-1.84705,1.433685c-.971396-.795804-2.218889-1.279203-3.573865-1.279203-3.062358,0-5.579002,2.458819-5.579002,5.450375c0,2.991754,2.516846,5.394807,5.579002,5.394807c2.400659,0,4.463688-1.474144,5.243801-3.553363l-1.998863-.475825c-.534926,1.232356-1.774544,2.082501-3.244938,2.082501-1.968318,0-3.529738-1.525322-3.529738-3.448357s1.561319-3.503926,3.529738-3.503926c.730299,0,1.401329.220906,1.96091.593249l-1.828064,1.421325l5.28813.95786v-5.073464Z" transform="translate(-339.937-309.1183)" fill="#fff"/>
  </svg>`;
}

export function createMarkerSvg(
  innerFill,
  stroke,
  width,
  height,
  text,
  highPriority = false,
  isReload = false,
  isBreak = false,
  insideColor,
  strokeWidth,
) {
  const highPriorityIcon = highPriority
    ? `<circle id="path-3" cx="11" cy="11" r="6" fill="#F5BE45" />`
    : '';
  const textColor = highPriority ? 'black' : insideColor;
  const reloadIcon = isReload ? getReloadIcon() : '';
  const breakIcon = isBreak ? getBreakIcon() : '';
  return `<svg width="${width}px" height="${height}px" viewBox="0 0 22 32" preserveAspectRatio="none" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
    <defs>
      <filter id="e0wqtBZX1mF4-filter" x="-150%" width="400%" y="-150%" height="400%">
        <feGaussianBlur id="e0wqtBZX1mF4-filter-blur-0" stdDeviation="1,1" result="result"/>
      </filter>
    </defs>
    <ellipse rx="4.05975" ry="0.982198" transform="matrix(1.239743 0 0 1.03625 11.5 27.078485)" opacity="0.5" filter="url(#e0wqtBZX1mF4-filter)"/>
    <line x1="11.5" y1="21" x2="11.5" y2="26.65" fill="none" fill-rule="evenodd" stroke="#000" stroke-linecap="square"/>
    <g>
      <circle r="10.5" transform="translate(11 11)" fill="${innerFill}" fill-rule="evenodd" stroke="${stroke}" stroke-width="${
    strokeWidth && 2
  }"/>
      ${highPriorityIcon}
      ${reloadIcon}
      ${breakIcon}
      <text x="50%" y="45%" text-anchor="middle" style="font-size:10px;font-family:FiraGO;" fill="${textColor}">${text}</text>
    </g>
    <circle r="9.75" transform="translate(11 11)" fill="none" fill-rule="evenodd" stroke="#fff" stroke-width="0.5" stroke-opacity="0.25"/>
  </svg>`;
}

export function createOrderUnassignedSvg(width, height, highlightUnassigned) {
  return `<svg width="${width}px" height="${height}px" viewBox="0 0 15 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M8,1.28867513 L13.4951905,4.46132487 C13.8045916,4.63995766 13.9951905,4.97008468 13.9951905,5.32735027 L13.9951905,11.6726497 C13.9951905,12.0299153 13.8045916,12.3600423 13.4951905,12.5386751 L8,15.7113249 C7.69059892,15.8899577 7.30940108,15.8899577 7,15.7113249 L1.50480947,12.5386751 C1.19540839,12.3600423 1.00480947,12.0299153 1.00480947,11.6726497 L1.00480947,5.32735027 C1.00480947,4.97008468 1.19540839,4.63995766 1.50480947,4.46132487 L7,1.28867513 C7.30940108,1.11004234 7.69059892,1.11004234 8,1.28867513 Z" id="mini-order-unassigned-map" stroke="${
        highlightUnassigned ? 'yellow' : '#9098B3'
      }" stroke-width="2" fill="#9A0002"  fill-opacity="0.9"></path>
    </g>
  </svg>`;
}

export function createDepotSvg(highlight) {
  return `<svg width="34px" height="48px" viewBox="0 0 34 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="depot-lui" transform="translate(1.000000, 1.000000)">
            <g id="Maker">
                <path d="M15.9845791,42 L8.49340403,34.3944256 C0.0541686689,25.9109255 0,20.6386553 0,16.1726969 C0,7.24073635 7.17554591,0 15.9845791,0 C24.7936123,0 32,7.24073635 32,16.1726969 C32,20.6386553 31.9458748,25.9109255 23.5066394,34.3944256 L15.9845791,42 Z" id="---↳-🌈-Background" fill="#2BDACD"></path>
                <g id="Shadow" opacity="0.3" fill="black" fill-opacity="1">
                    <use filter="url(#filter-2)" xlink:href="#path-1"></use>
                </g>
                <g id="Border">
                    <path stroke="#FFFFFF" stroke-width="0.5" d="M15.9845791,-0.25 C11.5084411,-0.25 7.44735583,1.59030423 4.5064092,4.56586199 C1.56967825,7.53715446 -0.25,11.6404553 -0.25,16.1726969 C-0.25,20.6802987 -0.204727972,26.0051527 8.31616555,34.5707393 L15.9842258,42.3559047 L24.046067,34.2033792 C31.488778,26.5854659 32.1775668,21.5573247 32.2432638,17.3615154 L32.249996,16.1741116 C32.25,11.6425757 30.4243258,7.54093566 27.4822069,4.56992432 C24.5332063,1.5919637 20.4626767,-0.25 15.9845791,-0.25 Z"></path>
                    <path stroke-opacity="0.35" stroke="${
                      highlight ? 'yellow' : '#233459'
                    }" stroke-width="${
    highlight ? '4' : '1'
  }" d="M15.9845791,0.5 C11.7161759,0.5 7.84410499,2.25580557 5.03983256,5.09308066 C2.23649708,7.92940776 0.5,11.8464682 0.5,16.1726969 C0.5,20.5553685 0.57196195,25.722471 8.847881,34.0417982 L15.9852853,41.288191 L23.5127792,33.6760234 C30.726148,26.2928317 31.4295648,21.4238512 31.4933557,17.3497735 L31.500008,16.1698673 C31.5,11.8486838 29.7575935,7.93353674 26.9492919,5.09765684 C24.1369775,2.25772468 20.2554308,0.5 15.9845791,0.5 Z" stroke-linejoin="square"></path>
                </g>
            </g>
            <g id="Icons-24-/-Solid-/-Others-/-home_" transform="translate(8.000000, 8.000000)">
                <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
                <path d="M7.44666667,1.57333333 C7.59219052,1.42428444 7.7916907,1.34024686 8,1.34024686 C8.2083093,1.34024686 8.40780948,1.42428444 8.55333333,1.57333333 L14.6666667,7.33333333 L14.6666667,14.6666667 L10,14.6666667 L10,10 L6,10 L6,14.6666667 L1.33333333,14.6666667 L1.33333333,7.33333333 L7.44666667,1.57333333 Z" id="---↳-🌈-Color" fill-opacity="0.8" fill="#ffffff" fill-rule="evenodd"></path>
            </g>
        </g>
    </g>
</svg>`;
}

export function createTourUnselectedSvg(innerFill, stroke, strokeWidth) {
  return `<svg width="9px" height="9px" viewBox="0 0 10 10">
    <circle r="4.5" transform="translate(5 5)" fill="${innerFill}" fill-rule="evenodd" stroke="${stroke}" stroke-width="${
    strokeWidth && 2
  }"/>
  </svg>`;
}

export function createOrderClusterMarkerDoubleSvg(width, height, text) {
  return `<svg width="${width}px" height="${height}px" viewBox="0 0 28 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <desc>Created with Sketch.</desc>
    <defs>
        <ellipse id="path-1" cx="17" cy="23.5" rx="8.5" ry="6.5"></ellipse>
        <filter x="-35.3%" y="-46.2%" width="170.6%" height="192.3%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-3-Copy-6" transform="translate(-1.000000, -1.000000)">
            <g id="Shadow" opacity="0.3" transform="translate(17.000000, 23.500000) rotate(-38.000000) translate(-17.000000, -23.500000) " fill="black" fill-opacity="1">
                <use filter="url(#filter-2)" xlink:href="#path-1"></use>
            </g>
            <path d="M20.2419355,0.288675135 L29.6901129,5.74358293 C29.999514,5.92221572 30.1901129,6.25234274 30.1901129,6.60960833 L30.1901129,17.5194239 C30.1901129,17.8766895 29.999514,18.2068165 29.6901129,18.3854493 L20.2419355,23.8403571 C19.9325344,24.0189899 19.5513366,24.0189899 19.2419355,23.8403571 L9.79375803,18.3854493 C9.48435696,18.2068165 9.29375803,17.8766895 9.29375803,17.5194239 L9.29375803,6.60960833 C9.29375803,6.25234274 9.48435696,5.92221572 9.79375803,5.74358293 L19.2419355,0.288675135 C19.5513366,0.11004234 19.9325344,0.11004234 20.2419355,0.288675135 Z" id="Polygon-Copy-11" stroke="#324380" fill="#4157A6" opacity="0"></path>
            <path d="M16.9516129,2.48222352 L26.3997904,7.93713132 C26.7091914,8.11576411 26.8997904,8.44589113 26.8997904,8.80315672 L26.8997904,19.7129723 C26.8997904,20.0702379 26.7091914,20.4003649 26.3997904,20.5789977 L16.9516129,26.0339055 C16.6422118,26.2125383 16.261014,26.2125383 15.9516129,26.0339055 L6.50343545,20.5789977 C6.19403437,20.4003649 6.00343545,20.0702379 6.00343545,19.7129723 L6.00343545,8.80315672 C6.00343545,8.44589113 6.19403437,8.11576411 6.50343545,7.93713132 L15.9516129,2.48222352 C16.261014,2.30359073 16.6422118,2.30359073 16.9516129,2.48222352 Z" id="Polygon-Copy-12" stroke="#324380" fill="#4157A6" opacity="0.7"></path>
            <path d="M12.5645161,4.67577191 L22.0126936,10.1306797 C22.3220947,10.3093125 22.5126936,10.6394395 22.5126936,10.9967051 L22.5126936,21.9065207 C22.5126936,22.2637863 22.3220947,22.5939133 22.0126936,22.7725461 L12.5645161,28.2274539 C12.2551151,28.4060867 11.8739172,28.4060867 11.5645161,28.2274539 L2.11633868,22.7725461 C1.8069376,22.5939133 1.61633868,22.2637863 1.61633868,21.9065207 L1.61633868,10.9967051 C1.61633868,10.6394395 1.8069376,10.3093125 2.11633868,10.1306797 L11.5645161,4.67577191 C11.8739172,4.49713911 12.2551151,4.49713911 12.5645161,4.67577191 Z" id="Polygon-Copy-13" stroke="#324380" fill="#4157A6"></path>
            <text x="40%" y="60%" text-anchor="middle" style="font-size:10px;font-family:FiraGO;" fill="white">${text}</text>
        </g>
    </g>
</svg>`;
}

export function createOrderClusterMarkerMultipleSvg(width, height, text) {
  return `<svg width="${width}px" height="${height}px" viewBox="0 0 30 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
    <desc>Created with Sketch.</desc>
    <defs>
        <ellipse id="path-1" cx="17" cy="23.5" rx="8.5" ry="6.5"></ellipse>
        <filter x="-35.3%" y="-46.2%" width="170.6%" height="192.3%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-3" transform="translate(-1.000000, 1.000000)">
            <text x="50%" y="45%" text-anchor="middle" style="font-size:10px;font-family:FiraGO;" fill="white">${text}</text>
            <g id="Shadow" opacity="0.3" transform="translate(17.000000, 23.500000) rotate(-38.000000) translate(-17.000000, -23.500000) " fill="black" fill-opacity="1">
                <use filter="url(#filter-2)" xlink:href="#path-1"></use>
            </g>
            <path d="M20.2419355,0.288675135 L29.6901129,5.74358293 C29.999514,5.92221572 30.1901129,6.25234274 30.1901129,6.60960833 L30.1901129,17.5194239 C30.1901129,17.8766895 29.999514,18.2068165 29.6901129,18.3854493 L20.2419355,23.8403571 C19.9325344,24.0189899 19.5513366,24.0189899 19.2419355,23.8403571 L9.79375803,18.3854493 C9.48435696,18.2068165 9.29375803,17.8766895 9.29375803,17.5194239 L9.29375803,6.60960833 C9.29375803,6.25234274 9.48435696,5.92221572 9.79375803,5.74358293 L19.2419355,0.288675135 C19.5513366,0.11004234 19.9325344,0.11004234 20.2419355,0.288675135 Z" id="Polygon-Copy-11" stroke="#324380" fill="#4157A6" opacity="0.6"></path>
            <path d="M16.9516129,2.48222352 L26.3997904,7.93713132 C26.7091914,8.11576411 26.8997904,8.44589113 26.8997904,8.80315672 L26.8997904,19.7129723 C26.8997904,20.0702379 26.7091914,20.4003649 26.3997904,20.5789977 L16.9516129,26.0339055 C16.6422118,26.2125383 16.261014,26.2125383 15.9516129,26.0339055 L6.50343545,20.5789977 C6.19403437,20.4003649 6.00343545,20.0702379 6.00343545,19.7129723 L6.00343545,8.80315672 C6.00343545,8.44589113 6.19403437,8.11576411 6.50343545,7.93713132 L15.9516129,2.48222352 C16.261014,2.30359073 16.6422118,2.30359073 16.9516129,2.48222352 Z" id="Polygon-Copy-12" stroke="#324380" fill="#4157A6" opacity="0.7"></path>
            <path d="M12.5645161,4.67577191 L22.0126936,10.1306797 C22.3220947,10.3093125 22.5126936,10.6394395 22.5126936,10.9967051 L22.5126936,21.9065207 C22.5126936,22.2637863 22.3220947,22.5939133 22.0126936,22.7725461 L12.5645161,28.2274539 C12.2551151,28.4060867 11.8739172,28.4060867 11.5645161,28.2274539 L2.11633868,22.7725461 C1.8069376,22.5939133 1.61633868,22.2637863 1.61633868,21.9065207 L1.61633868,10.9967051 C1.61633868,10.6394395 1.8069376,10.3093125 2.11633868,10.1306797 L11.5645161,4.67577191 C11.8739172,4.49713911 12.2551151,4.49713911 12.5645161,4.67577191 Z" id="Polygon-Copy-13" stroke="#324380" fill="#4157A6"></path>
            <text x="40%" y="55%" text-anchor="middle" style="font-size:10px;font-family:FiraGO;" fill="white">${text}</text>
        </g>
    </g>
</svg>`;
}

export function createStopClusterMarkerDoubleSvg(
  innerFill,
  stroke,
  width,
  height,
  text,
  textColor,
) {
  return `<svg width="${width}px" height="${height}px" viewBox="0 0 25 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <ellipse id="path-1" cx="11" cy="22" rx="5" ry="1"></ellipse>
        <filter x="-60.0%" y="-300.0%" width="220.0%" height="700.0%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <circle id="path-3" cx="11" cy="11" r="11"></circle>
        <ellipse id="path-5" cx="11.5" cy="27" rx="5" ry="1"></ellipse>
        <filter x="-60.0%" y="-300.0%" width="220.0%" height="700.0%" filterUnits="objectBoundingBox" id="filter-6">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <circle id="path-7" cx="11" cy="11" r="11"></circle>
        <circle id="path-9" cx="11" cy="11" r="11"></circle>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="cluster-stop-double">
            <g id="OnMap-/-Markers-/-Small-Copy-8" transform="translate(3.000000, 0.000000)">
                <g id="Shadow" opacity="0.3" fill="black" fill-opacity="1">
                    <use filter="url(#filter-2)" xlink:href="#path-1"></use>
                </g>
                <g id="Shape">
                    <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                    </mask>
                    <use id="Mask" fill="#FFA472" xlink:href="#path-3"></use>
                    <g id="Group" mask="url(#mask-4)" fill="${stroke}">
                        <g id="Color-🎨">
                            <rect id="Rectangle" x="0" y="0" width="22" height="22"></rect>
                        </g>
                    </g>
                    <circle id="Border" stroke-opacity="0.177556818" stroke="#000000" mask="url(#mask-4)" cx="11" cy="11" r="10.5"></circle>
                </g>
            </g>
            <g id="Group-3-Copy" transform="translate(0.000000, 2.000000)">
                <line x1="11.5" y1="21" x2="11.5" y2="26.65" id="Line" stroke="#000000" stroke-linecap="square"></line>
                <g id="OnMap-/-Markers-/-Small-Copy-8">
                    <g id="Shadow" opacity="0.517833891" fill="black" fill-opacity="1">
                        <use filter="url(#filter-6)" xlink:href="#path-5"></use>
                    </g>
                    <g id="Shape">
                        <mask id="mask-8" fill="white">
                            <use xlink:href="#path-7"></use>
                        </mask>
                        <use id="Mask" fill="#FFA472" xlink:href="#path-7"></use>
                        <g id="Color-🎨" mask="url(#mask-8)">
                            <rect id="Rectangle" fill="${innerFill}" x="0" y="0" width="22" height="22"></rect>
                            <g id="Border">
                                <use stroke-opacity="0.206840035" stroke="#FFFFFF" stroke-width="3" xlink:href="#path-9"></use>
                                <use stroke="${innerFill}" stroke-width="2" xlink:href="#path-9"></use>
                            </g>
                            <text x="45%" y="45%" text-anchor="middle" style="font-size:10px;font-family:FiraGO;" fill="${textColor}">${text}</text>
                            <circle id="Border" stroke-opacity="0.341264205" stroke="#000000" cx="11" cy="11" r="10.5"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`;
}

export function createStopClusterMarkerMultipleSvg(
  innerFill,
  stroke,
  width,
  height,
  text,
  textColor,
) {
  return `<svg width="${width}px" height="${height}px" viewBox="0 0 28 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <ellipse id="path-1" cx="11" cy="22" rx="5" ry="1"></ellipse>
        <filter x="-60.0%" y="-300.0%" width="220.0%" height="700.0%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <circle id="path-3" cx="11" cy="11" r="11"></circle>
        <ellipse id="path-5" cx="11" cy="22" rx="5" ry="1"></ellipse>
        <filter x="-60.0%" y="-300.0%" width="220.0%" height="700.0%" filterUnits="objectBoundingBox" id="filter-6">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <circle id="path-7" cx="11" cy="11" r="11"></circle>
        <ellipse id="path-9" cx="11.5" cy="27" rx="5" ry="1"></ellipse>
        <filter x="-60.0%" y="-300.0%" width="220.0%" height="700.0%" filterUnits="objectBoundingBox" id="filter-10">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <circle id="path-11" cx="11" cy="11" r="11"></circle>
        <circle id="path-13" cx="11" cy="11" r="11"></circle>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="cluster-stop.svg">
            <g id="OnMap-/-Markers-/-Small-Copy-13" transform="translate(6.000000, 0.000000)">
                <g id="Shadow" opacity="0.3" fill="black" fill-opacity="1">
                    <use filter="url(#filter-2)" xlink:href="#path-1"></use>
                </g>
                <g id="Shape">
                    <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                    </mask>
                    <use id="Mask" fill="#FFA472" xlink:href="#path-3"></use>
                    <g id="Group" mask="url(#mask-4)" fill="${stroke}">
                        <g id="Color-🎨">
                            <rect id="Rectangle" x="0" y="0" width="22" height="22"></rect>
                        </g>
                    </g>
                    <circle id="Border" stroke-opacity="0.196787587" stroke="#000000" mask="url(#mask-4)" cx="11" cy="11" r="10.5"></circle>
                </g>
            </g>
            <g id="OnMap-/-Markers-/-Small-Copy-8" transform="translate(3.000000, 2.000000)">
                <g id="Shadow" opacity="0.3" fill="black" fill-opacity="1">
                    <use filter="url(#filter-6)" xlink:href="#path-5"></use>
                </g>
                <g id="Shape">
                    <mask id="mask-8" fill="white">
                        <use xlink:href="#path-7"></use>
                    </mask>
                    <use id="Mask" fill="#FFA472" xlink:href="#path-7"></use>
                    <g id="Group" mask="url(#mask-8)" fill="${stroke}">
                        <g id="Color-🎨">
                            <rect id="Rectangle" x="0" y="0" width="22" height="22"></rect>
                        </g>
                    </g>
                    <circle id="Border" stroke-opacity="0.177556818" stroke="#000000" mask="url(#mask-8)" cx="11" cy="11" r="10.5"></circle>
                </g>
            </g>
            <g id="Group-3-Copy" transform="translate(0.000000, 4.000000)">
                <line x1="11.5" y1="21" x2="11.5" y2="26.65" id="Line" stroke="#000000" stroke-linecap="square"></line>
                <g id="OnMap-/-Markers-/-Small-Copy-8">
                    <g id="Shadow" opacity="0.517833891" fill="black" fill-opacity="1">
                        <use filter="url(#filter-10)" xlink:href="#path-9"></use>
                    </g>
                    <g id="Shape">
                        <mask id="mask-12" fill="white">
                            <use xlink:href="#path-11"></use>
                        </mask>
                        <use id="Mask" fill="#FFA472" xlink:href="#path-11"></use>
                        <g id="Color-🎨" mask="url(#mask-12)">
                            <rect id="Rectangle" fill="${innerFill}" x="0" y="0" width="22" height="22"></rect>
                            <g id="Border">
                                <use stroke-opacity="0.206840035" stroke="#FFFFFF" stroke-width="3" xlink:href="#path-13"></use>
                                <use stroke="${innerFill}" stroke-width="2" xlink:href="#path-13"></use>
                            </g>
                            <text x="40%" y="40%" text-anchor="middle" style="font-size:10px;font-family:FiraGO;" fill="${textColor}">${text}</text>
                            <circle id="Border" stroke-opacity="0.341264205" stroke="#000000" cx="11" cy="11" r="10.5"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>`;
}
