import React from 'react';
import { Font, Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { colors } from 'global/variables';
import moment from 'moment';
import { getAsset } from 'utils/helpers';
import { isEmpty, size } from 'lodash';
import hereLogo from 'global/img/logo.png';
import fontRegular from 'global/fonts/FiraGO-Regular.woff';
import fontBold from 'global/fonts/FiraGO-Bold.woff';
import fontHindi from 'global/fonts/NotoSansDevanagari-Regular.ttf';
import config from 'config';
import {
  getNumberOfDepotStops,
  getLastValidIteration,
  getDemand,
} from '../../../utils/SolutionHelpers';
import StopsPDF from './StopsPDF';
import { getTourOrders } from '../../../utils/OrdersHelpers';
import { getPDFFileName } from '../../../utils/SolutionExport';

const { white } = colors;
const fontSizeFactor = 10;

Font.register({
  family: 'FiraGo',
  src: getAsset(fontRegular),
  fontStyle: 'normal',
  fontWeight: 400,
});

Font.register({
  family: 'FiraGo',
  src: getAsset(fontBold),
  fontStyle: 'normal',
  fontWeight: 700,
});

Font.register({
  family: 'Hindi',
  src: getAsset(fontHindi),
  fontStyle: 'normal',
  fontWeight: 400,
});

const styles = StyleSheet.create({
  section: {
    margin: 20,
    marginBottom: 0,
    marginTop: 10,
    flexDirection: 'row',
  },

  title: {
    fontSize: 1.5 * fontSizeFactor,
    margin: 20,
    marginTop: 10,
    marginBottom: 0,
    fontWeight: 700,
  },
  uppercased: {
    textTransform: 'uppercase',
    fontSize: 7,
    fontWeight: 400,
    color: '#545961',
  },
  bold: {
    fontSize: 12,
    fontWeight: 700,
    color: '#1A222E',
  },
  logo: {
    position: 'absolute',
    right: 100,
    top: 30,
    width: 20,
    height: 18,
  },
  wegologo: {
    position: 'absolute',
    left: 20,
    top: 30,
    width: 200,
  },
  summary: {
    flexGrow: 1,
    marginBottom: 20,
    fontSize: 1.2 * fontSizeFactor,
  },
  pagecount: {
    position: 'absolute',
    fontSize: 9,
    fontWeight: 400,
    textAlign: 'center',
    left: -20,
    top: 820,
    margin: 'auto',
    color: '#545961',
  },
});

const TourPDF = ({
  tour,
  tourID,
  solution,
  tourPlanner,
  translations,
  formattedDuration,
  user,
  qrCodes,
  printDemand,
}) => {
  const {
    tourSolution: {
      tourDetails: { ordersTrans },
    },
    wizard: {
      tours: { vehicleLabel },
      toursDetails: {
        toursDetailsTitle,
        stopsTrans,
        durationTrans,
        dropsTrans,
        pdf: { creationDateTrans },
      },
    },
  } = translations;
  const lastIteration = getLastValidIteration(solution.requests[0]);
  const request = lastIteration.request;
  const today = moment().format('LL');
  const titleArr = [toursDetailsTitle];
  if (tourPlanner && !isEmpty(tourPlanner.companyName)) titleArr.unshift(tourPlanner.companyName);
  const title = titleArr.join(' - ');
  const filename = getPDFFileName(user, tourID);
  const pageStylesRegular = {
    page: {
      backgroundColor: white,
      marginLeft: 40,
      marginRight: 40,
      fontSize: 1.3 * fontSizeFactor,
      paddingBottom: 30,
      paddingTop: 50,
      fontFamily: 'FiraGo',
    },
  };

  const pageStylesHindi = {
    page: {
      backgroundColor: white,
      marginLeft: 40,
      marginRight: 40,
      fontSize: 1.3 * fontSizeFactor,
      paddingBottom: 30,
      paddingTop: 50,
      fontFamily: 'Hindi',
    },
  };

  const pageStyles = user.language === 'hi' ? pageStylesHindi : pageStylesRegular;
  const demand = getDemand({ tour, orders: request.orders });
  const orders = getTourOrders(tour, request);
  const showOrders = size(orders) !== demand;

  return (
    <Document>
      <Page size="A4" style={pageStyles.page}>
        <Image style={styles.logo} src={getAsset(hereLogo)} />
        <View style={styles.wegologo}>
          <Text style={styles.uppercased}>{config.name}</Text>
        </View>
        <Text style={styles.title}>{title}</Text>
        <Text
          style={styles.pagecount}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}          ${filename}`
          }
          fixed
        />
        <View style={styles.section}>
          <View style={styles.summary}>
            <Text style={styles.uppercased}>{vehicleLabel}</Text>
            <Text style={styles.bold}>{tour.vehicleId}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.summary}>
            <Text style={styles.uppercased}>{creationDateTrans}</Text>
            <Text style={styles.bold}>{today}</Text>
          </View>
          <View style={styles.summary}>
            <Text style={styles.uppercased}>{stopsTrans}</Text>
            <Text style={styles.bold}>{size(tour.stops) - getNumberOfDepotStops(tour.stops)}</Text>
          </View>
          {showOrders && (
            <View style={styles.summary}>
              <Text style={styles.uppercased}>{ordersTrans}</Text>
              <Text style={styles.bold}>{size(orders)}</Text>
            </View>
          )}
          <View style={styles.summary}>
            <Text style={styles.uppercased}>{dropsTrans}</Text>
            <Text style={styles.bold}>{demand}</Text>
          </View>
          <View style={styles.summary}>
            <Text style={styles.uppercased}>{durationTrans}</Text>
            <Text style={styles.bold}>{formattedDuration}</Text>
          </View>
        </View>
        <StopsPDF
          stops={tour.stops}
          request={request}
          translations={translations}
          tourPlanner={tourPlanner}
          user={user}
          qrCodes={qrCodes}
          printDemand={printDemand}
        />
      </Page>
    </Document>
  );
};

export default TourPDF;
