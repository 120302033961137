import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrders, setTourParameter, setTerritoryParameter } from 'actions';
import withTranslation from 'hoc/withTranslation';
import config from 'config';
import { find, isEqual, map, isEmpty } from 'lodash';
import styled from '@emotion/styled';
import TerritoryPlanningContainer from 'components/FloatingPanels/TerritoryPlanning/TerritoryPlanningContainer';
import Checkbox from '../../Form/Checkbox';
import TimeRangeSlider from './TimeRangeSlider';
import { isMobileDevice } from '../../../utils/helpers';
import {
  StyledDoublePanel,
  StyledLine,
  StyledTextInput,
  StyledWizardContent,
  StyledWizardH1,
  StyledSelectBigInput,
  StyledIcon,
  StyledSelectedInputLabel,
  StyledTriplePanel,
  StyledInformationNormalIcon,
  StyledCheckboxDoublePanel,
  StyledSpace,
} from '../Global/WizardStyled';
import InputLocation from '../Global/InputLocation';
import Section from '../../Form/Section';
import Label from '../../Form/Label';
import Selector from '../../Form/Selector';
import iconCapacity from '../../../global/img/shipment.svg';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import { getFleetTypeIcon } from '../../../utils/FleetHelpers';
import NumericInput from '../Global/NumericInput';
import FleetEndAddress from './FleetEndAddress';
import { APP_MODES, getAppMode } from '../../../utils/urlHelpers';
import { getSafeValue } from '../../../utils/security';
import { createRandomTerritories } from '../../../utils/territories/TerritoriesHelpers';
import { getAtLocation } from '../../../utils/GeoCoder';
import { getBoundingCoordinates } from '../../../utils/MapHelpers';
import {
  hoursToSeconds,
  kilometersToMeters,
  metersToKilometers,
  secondsToHours,
} from '../../../utils/converter';

const appMode = getAppMode();

const isMobile = isMobileDevice();
const {
  scenario: { times: timesConfig },
  maxValues,
} = config;

const StyledFleetSelector = styled.div(({ align }) => ({
  height: '2rem',
  justifySelf: align,
  width: '100%',
}));

const StyledTerritoryPanel = styled.div`
  display: grid;
  grid-template-columns: fit-content(50%) fit-content(10%) fit-content(40%);
  justify-content: start;
  grid-gap: 2rem;
`;

const Step1Container = ({
  user,
  oAuth,
  tourPlanner,
  mapData,
  onTerritoryPlanningPanelToggle,
  solution,
  translations: {
    error: { depotNotFound },
    global: {
      timesTrans,
      costTrans: { timeLabel, distanceKmLabel, distanceMileLabel, fixedLabel },
    },
    map: { depotMarkerTrans },
    tourPlanner: {
      depotLabelTrans,
      depotPlaceholderTrans,
      configurationLabelTrans,
      costLabelTrans,
      timeLabelTrans,
    },
    territoryPlanning: {
      territoryLabelTrans,
      territorySwitchLabel,
      territoryPlanningInformation,
      territoryStrictSwitchLabel,
    },
    wizard: {
      fleet: {
        vehiclesAmountLabel,
        vehiclesCapacityLabel,
        vehiclesAmountLabelMobile,
        vehiclesCapacityLabelMobile,
        fleetTypeTitle,
        fleetTypes,
        limitMaxDistanceLabel,
        limitShiftTimeLabel,
      },
    },
  },
}) => {
  const territories = useSelector(({ areas: stateAreas }) => stateAreas.territories);
  const dispatch = useDispatch();
  const handleClearOrders = useCallback((data) => dispatch(clearOrders(data)), [dispatch]);
  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter({ ...parameter, index: solution.show })),
    [dispatch, solution.show],
  );
  const handleSetTerritoryParameter = useCallback(
    (parameter) => dispatch(setTerritoryParameter(parameter)),
    [dispatch],
  );

  const {
    time: { key: timeTourParamKey, value: timeTourParamValue },
    vehicles: [
      {
        amount,
        capacity,
        costs,
        limits: { maxDistance, shiftTime },
      },
    ],
    location,
    vehicleProfiles: [{ fleetType }],
  } = tourPlanner || {};
  const { time, distance, fixed } = costs;

  const [times, setTimes] = useState(timeTourParamValue);
  const [fleetAmount, setFleetAmount] = useState(amount);
  const [fleetCapacity, setFleetCapacity] = useState(capacity);
  const [maxDistanceLimit, setMaxDistanceLimit] = useState(metersToKilometers(maxDistance.value));
  const [shiftTimeLimit, setShiftTimeLimit] = useState(secondsToHours(shiftTime.value));
  const [noDepotFound, setNoDepotFound] = useState(false);
  const [showTerritoryPlanning, setShowTerritoryPlanning] = useState(false);

  const cleanJSONFile = useCallback(() => {
    if (tourPlanner.fileType === 'json') handleClearOrders(solution.show);
  }, [tourPlanner, solution.show]);

  useEffect(() => cleanJSONFile(), []);

  useEffect(() => {
    setFleetCapacity(capacity);
    setFleetAmount(amount);
    setMaxDistanceLimit(metersToKilometers(maxDistance.value));
    setShiftTimeLimit(secondsToHours(shiftTime.value));
  }, [amount, capacity, maxDistance, shiftTime]);

  useEffect(() => {
    onTerritoryPlanningPanelToggle(showTerritoryPlanning);
  }, [showTerritoryPlanning, onTerritoryPlanningPanelToggle]);

  const timeOptions = useMemo(
    () => map(timesConfig, ({ key }) => ({ key, value: getSafeValue(timesTrans, key) })),
    [timesTrans],
  );

  const costLabels = [
    timeLabel,
    user.distance === 'imperial' ? distanceMileLabel : distanceKmLabel,
    fixedLabel,
  ];

  const onHandleSetCost = useCallback(
    (value, id) => {
      if (id === 'time-cost')
        handleSetTourParameter({
          vehicles: [
            { ...tourPlanner.vehicles[0], costs: { time: parseFloat(value), distance, fixed } },
          ],
        });
      if (id === 'distance-cost')
        handleSetTourParameter({
          vehicles: [
            { ...tourPlanner.vehicles[0], costs: { time, distance: parseFloat(value), fixed } },
          ],
        });
      if (id === 'fixed-cost')
        handleSetTourParameter({
          vehicles: [
            { ...tourPlanner.vehicles[0], costs: { time, distance, fixed: parseFloat(value) } },
          ],
        });

      AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_VEHICLE_COSTS);
    },
    [handleSetTourParameter, capacity, amount, costs, tourPlanner],
  );

  const onSliderChange = useCallback(
    ({ start, end }) => {
      const newTimes = { start, end };

      if (!isEqual(newTimes, times)) {
        const { key } = find(timesConfig, ({ value }) => isEqual(newTimes, value)) || { key: null };
        setTimes(newTimes);
        handleSetTourParameter({
          time: {
            key,
            value: newTimes,
          },
        });
        AmplitudeService.log(AMPLITUDE_EVENTS.TIME_CONFIG_CHANGED);
      }
    },
    [handleSetTourParameter, setTimes, times],
  );

  const onTimeChange = useCallback(
    (timeKey) => {
      const { value } = find(timesConfig, ({ key }) => timeKey === key) || {};
      if (value) {
        setTimes(value);
        handleSetTourParameter({ time: { key: timeKey, value } });
      }
      AmplitudeService.log(AMPLITUDE_EVENTS.TIME_CONFIG_CHANGED);
    },
    [handleSetTourParameter, setTimes],
  );

  const onAmountChange = useCallback(
    (e) => {
      const value = e.target.value && parseInt(e.target.value, 10);
      setFleetAmount(value);
      e.target.focus();
    },
    [setFleetAmount],
  );

  const onAmountBlur = useCallback(() => {
    const value = parseInt(fleetAmount, 10);
    if (!Number.isNaN(value) && value >= 1 && value <= maxValues.vehicles) {
      handleSetTourParameter({ vehicles: [{ ...tourPlanner.vehicles[0], amount: value }] });
    } else {
      setFleetAmount(amount);
    }
    AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_VEHICLE_NUMBER);
  }, [handleSetTourParameter, setFleetAmount, amount, capacity, fleetAmount, tourPlanner]);

  const onMaxDistanceChange = useCallback(
    (e) => {
      const value = e.target.value && parseInt(e.target.value, 10);
      setMaxDistanceLimit(value);
      e.target.focus();
    },
    [setMaxDistanceLimit],
  );

  const onMaxDistanceBlur = useCallback(() => {
    const value = parseInt(maxDistanceLimit, 10);
    if (
      !Number.isNaN(value) &&
      value >= 1 &&
      value <= metersToKilometers(maxValues.vehicleMaxDistanceLimit)
    ) {
      handleSetTourParameter({
        vehicles: [
          {
            ...tourPlanner.vehicles[0],
            limits: {
              ...tourPlanner.vehicles[0].limits,
              maxDistance: {
                ...tourPlanner.vehicles[0].limits.maxDistance,
                value: kilometersToMeters(value),
              },
            },
          },
        ],
      });
    } else {
      setMaxDistanceLimit(metersToKilometers(maxDistance.value));
    }
    AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_CAPACITY);
  }, [handleSetTourParameter, setMaxDistanceLimit, maxDistance, maxDistanceLimit, tourPlanner]);

  const onShiftTimeChange = useCallback(
    (e) => {
      const value = e.target.value && parseInt(e.target.value, 10);
      setShiftTimeLimit(value);
      e.target.focus();
    },
    [setShiftTimeLimit],
  );

  const onShiftTimeBlur = useCallback(() => {
    const value = parseInt(shiftTimeLimit, 10);
    if (
      !Number.isNaN(value) &&
      value >= 1 &&
      value <= secondsToHours(maxValues.vehicleShiftTimeLimit)
    ) {
      handleSetTourParameter({
        vehicles: [
          {
            ...tourPlanner.vehicles[0],
            limits: {
              ...tourPlanner.vehicles[0].limits,
              shiftTime: {
                ...tourPlanner.vehicles[0].limits.shiftTime,
                value: hoursToSeconds(value),
              },
            },
          },
        ],
      });
    } else {
      setShiftTimeLimit(secondsToHours(shiftTime.value));
    }
    AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_CAPACITY);
  }, [handleSetTourParameter, setShiftTimeLimit, shiftTime, shiftTimeLimit, tourPlanner]);

  const onCapacityChange = useCallback(
    (e) => {
      const value = e.target.value && parseInt(e.target.value, 10);
      setFleetCapacity([value]);
      e.target.focus();
    },
    [setFleetCapacity],
  );

  const onCapacityBlur = useCallback(() => {
    const value = parseInt(fleetCapacity[0], 10);
    if (!Number.isNaN(value) && value >= 1 && value <= maxValues.capacity) {
      handleSetTourParameter({ vehicles: [{ ...tourPlanner.vehicles[0], capacity: [value] }] });
    } else {
      setFleetCapacity([capacity]);
    }
    AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_CAPACITY);
  }, [handleSetTourParameter, setFleetCapacity, capacity, amount, fleetCapacity, tourPlanner]);

  const onLocationFound = useCallback(
    (loc) => {
      setNoDepotFound(!loc.value && !isEmpty(loc.label));
      handleSetTourParameter({ location: { ...loc } });
      if (loc && loc.value) AmplitudeService.log(AMPLITUDE_EVENTS.DEPOT_SET);
    },
    [handleSetTourParameter, setNoDepotFound],
  );

  const handleVehicleTypeChange = useCallback(
    (e) => {
      handleSetTourParameter({
        vehicleProfiles: [{ ...tourPlanner.vehicleProfiles[0], fleetType: e.target.value }],
      });
      AmplitudeService.log(AMPLITUDE_EVENTS.FLEET_CONFIG_CHANGE_FLEET_TYPE, {
        fleetType: e.target.value,
      });
    },
    [handleSetTourParameter, tourPlanner],
  );

  const handleOnTerritoriesEnabled = useCallback(
    (val) => {
      const loc = getAtLocation(user, tourPlanner);
      const bbox =
        mapData && mapData.geo && mapData.geo.features.length >= 2
          ? mapData.geo.bbox
          : getBoundingCoordinates(loc, 300);

      let territoryAreas;
      if (appMode.includes(APP_MODES.TERRA)) {
        setShowTerritoryPlanning(val);
      } else if (val) {
        territoryAreas = createRandomTerritories(bbox);
      }
      handleSetTerritoryParameter({ isEnabled: val, areaDetails: territoryAreas, bbox });
      handleSetTerritoryParameter({ strict: true });
    },
    [tourPlanner, user, mapData],
  );

  const handleOnCloseTerritoryPlanning = useCallback(
    (territoryPlanningState) => {
      setShowTerritoryPlanning(false);
      handleSetTerritoryParameter({ isEnabled: territoryPlanningState });
    },
    [setShowTerritoryPlanning],
  );

  const handleStrictChange = useCallback(
    (val) => {
      handleSetTerritoryParameter({ strict: val });
    },
    [handleSetTerritoryParameter],
  );

  const handleMaxDistanceEnabledChange = useCallback(
    (enabled) => {
      handleSetTourParameter({
        vehicles: [
          {
            ...tourPlanner.vehicles[0],
            limits: {
              ...tourPlanner.vehicles[0].limits,
              maxDistance: {
                ...maxDistance,
                enabled,
              },
            },
          },
        ],
      });
    },
    [handleSetTourParameter, tourPlanner],
  );

  const handleShiftTimeEnabledChange = useCallback(
    (enabled) => {
      handleSetTourParameter({
        vehicles: [
          {
            ...tourPlanner.vehicles[0],
            limits: {
              ...tourPlanner.vehicles[0].limits,
              shiftTime: {
                ...shiftTime,
                enabled,
              },
            },
          },
        ],
      });
    },
    [handleSetTourParameter, tourPlanner],
  );

  // only temporary, resolve multiple fleet.types when having territories, then remove
  const show = false;
  // only temporary due to adding limits for vehicles
  const showDriverSchedule = false;

  return (
    <StyledWizardContent>
      {showTerritoryPlanning ? (
        <TerritoryPlanningContainer
          onClose={handleOnCloseTerritoryPlanning}
          user={user}
          tourPlanner={tourPlanner}
          oAuth={oAuth}
        />
      ) : (
        <>
          <StyledWizardH1>{depotMarkerTrans}</StyledWizardH1>
          <Section>
            {!noDepotFound && <Label>{depotLabelTrans}</Label>}
            {noDepotFound && <Label error>{depotNotFound}</Label>}
            <InputLocation
              id="input-start"
              placeholder={depotPlaceholderTrans}
              value={location.label}
              oAuth={oAuth}
              onLocationFound={onLocationFound}
              user={user}
              tourPlanner={tourPlanner}
              hasCurrentLocationControl
            />
            <FleetEndAddress oAuth={oAuth} user={user} tourPlanner={tourPlanner} />
          </Section>
          <StyledLine />
          {show && appMode.includes(APP_MODES.WIP, APP_MODES.TERRA) && (
            <>
              <StyledWizardH1>{territoryLabelTrans}</StyledWizardH1>
              <Section>
                <StyledTerritoryPanel>
                  <Checkbox
                    id="service-area-enabler"
                    label={territorySwitchLabel}
                    value={territories.isEnabled}
                    onChange={handleOnTerritoriesEnabled}
                  />
                  <StyledIcon
                    id="information-btn"
                    icon={StyledInformationNormalIcon}
                    title={territoryPlanningInformation}
                    mini
                  />
                  {territories.isEnabled && (
                    <Checkbox
                      id="service-area-strict"
                      label={territoryStrictSwitchLabel}
                      value={territories.strict}
                      onChange={handleStrictChange}
                    />
                  )}
                </StyledTerritoryPanel>
              </Section>
              <StyledLine />
            </>
          )}
          <StyledWizardH1>{configurationLabelTrans}</StyledWizardH1>
          <StyledDoublePanel hasControls>
            <StyledFleetSelector align="start">
              <StyledSelectedInputLabel asBlock>{fleetTypeTitle}</StyledSelectedInputLabel>
              <StyledSelectBigInput
                fullWidth
                onChange={handleVehicleTypeChange}
                value={fleetType}
                icon={getFleetTypeIcon(fleetType)}
                id="input-fleet-type"
              >
                {Object.keys(fleetTypes).map((typeID) => (
                  <option key={typeID} value={typeID}>
                    {getSafeValue(fleetTypes, typeID)}
                  </option>
                ))}
              </StyledSelectBigInput>
            </StyledFleetSelector>
          </StyledDoublePanel>
          <>
            <Section>
              <StyledDoublePanel>
                <div>
                  <Label>{isMobile ? vehiclesAmountLabelMobile : vehiclesAmountLabel}</Label>
                  <StyledTextInput
                    id="input-fleet-amount"
                    icon={getFleetTypeIcon(fleetType)}
                    type="number"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    min="1"
                    max={maxValues.vehicles}
                    onChange={onAmountChange}
                    value={fleetAmount}
                    onBlur={onAmountBlur}
                  />
                </div>
                <div>
                  <Label>{isMobile ? vehiclesCapacityLabelMobile : vehiclesCapacityLabel}</Label>
                  <StyledTextInput
                    id="input-fleet-capacity"
                    icon={iconCapacity}
                    type="number"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    min="1"
                    max={maxValues.capacity}
                    onChange={onCapacityChange}
                    value={fleetCapacity[0]}
                    onBlur={onCapacityBlur}
                  />
                </div>
              </StyledDoublePanel>
              <StyledSpace />
              <StyledDoublePanel>
                <div>
                  <Label>{limitMaxDistanceLabel}</Label>
                  <StyledCheckboxDoublePanel>
                    <Checkbox
                      id="checkbox-maximum-distance"
                      value={maxDistance.enabled}
                      onChange={handleMaxDistanceEnabledChange}
                    />
                    <StyledTextInput
                      id="input-maximum-distance"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      min="1"
                      max={metersToKilometers(maxValues.vehicleMaxDistanceLimit)}
                      onChange={onMaxDistanceChange}
                      value={maxDistanceLimit}
                      onBlur={onMaxDistanceBlur}
                      disabled={!maxDistance.enabled}
                    />
                  </StyledCheckboxDoublePanel>
                </div>
                <div>
                  <Label>{limitShiftTimeLabel}</Label>
                  <StyledCheckboxDoublePanel>
                    <Checkbox
                      id="checkbox-shift-time"
                      value={shiftTime.enabled}
                      onChange={handleShiftTimeEnabledChange}
                    />
                    <StyledTextInput
                      id="input-shift-time"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      min="1"
                      max={secondsToHours(maxValues.vehicleShiftTimeLimit)}
                      onChange={onShiftTimeChange}
                      value={shiftTimeLimit}
                      onBlur={onShiftTimeBlur}
                      disabled={!shiftTime.enabled}
                    />
                  </StyledCheckboxDoublePanel>
                </div>
              </StyledDoublePanel>
            </Section>
            <Section>
              <Section>
                <Label>{timeLabelTrans}</Label>
                {showDriverSchedule && (
                  <Selector
                    id="button-time-window"
                    initialValue={timeTourParamKey}
                    options={timeOptions}
                    onChange={onTimeChange}
                  />
                )}
              </Section>
              <TimeRangeSlider
                id="slider-time-window"
                onChange={onSliderChange}
                timeAggregation={3}
                times={times}
              />
            </Section>
            <Section>
              <Label>{costLabelTrans}</Label>
              <StyledTriplePanel>
                {Object.entries(costs).map((data, index) => {
                  const costValue = Object.values(data);
                  const inputId = `${costValue[0]}-${'cost'}`;
                  return (
                    <NumericInput
                      inputLabel={getSafeValue(costLabels, index)}
                      initialValue={costValue[1]}
                      id={inputId}
                      onChange={onHandleSetCost}
                      key={costValue[0]}
                      maxValue={maxValues.costs}
                      minValue={0}
                    />
                  );
                })}
              </StyledTriplePanel>
            </Section>
          </>
        </>
      )}
    </StyledWizardContent>
  );
};

export default withTranslation(Step1Container);
