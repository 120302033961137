import React, { useCallback, useEffect, useState } from 'react';
import withTranslation from 'hoc/withTranslation';
import { first, isEmpty, keys, size } from 'lodash';
import styled from '@emotion/styled';
import Label from '../../Form/Label';
import UnassignedOrder from './UnassignedOrder';
import { sortOrdersBy } from '../../../utils/OrdersHelpers';
import { fontSize } from '../../../global/variables';
import { StyledIcon } from '../Global/WizardStyled';
import expandIcon from '../../../global/img/accordion-expand.svg';
import collapseIcon from '../../../global/img/accordion-collapse.svg';
import { getSafeValue, setSafeValue } from '../../../utils/security';

const StyledReasonContainer = styled.div({
  padding: '0.5rem 0',
  fontSize: fontSize.medium,
});

const StyledReasonTitle = styled.div({
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: '1fr 2rem',
  gridGap: '2rem',
  width: '100%',
});

const StyledOrdersContainer = styled.div(({ displayDetails }) => ({
  padding: '0.5rem',
  height: displayDetails ? 'auto' : 0,
  animation: 'EntryAnimation .3s 1',
  animationTimingFunction: 'ease-out',
  overflow: 'hidden',
}));

const UnassignedList = ({
  lastIterationTourData,
  orders,
  translations: {
    wizard: {
      tours: { unassignedCodes, unassignedReasonsTrans },
    },
  },
}) => {
  const [reasons, setReasons] = useState();
  const [codeInView, setCodeInView] = useState();
  const [orderInView, setOrderInView] = useState();
  const { unassigned } = lastIterationTourData;

  useEffect(() => {
    setOrderInView();
    setCodeInView();
    if (isEmpty(unassigned)) {
      setReasons();
      return;
    }

    const grouped = {};
    unassigned.forEach((item) => {
      item.reasons.forEach((reason) => {
        if (!getSafeValue(grouped, reason.code)) setSafeValue(grouped, reason.code, []);
        const order = orders.find((o) => o.InternalID === item.jobId);
        if (!order) return;
        getSafeValue(grouped, reason.code).push(order);
      });
    });
    keys(grouped).forEach((key) => {
      setSafeValue(grouped, key, sortOrdersBy(getSafeValue(grouped, key), 'Address'));
    });
    setReasons(grouped);
    if (size(keys(grouped)) === 1) setCodeInView(first(keys(grouped)));
  }, [unassigned, orders]);

  useEffect(() => {
    setOrderInView();
    if (!codeInView) return;
    if (size(getSafeValue(reasons, codeInView)) === 1)
      setOrderInView(first(getSafeValue(reasons, codeInView)).InternalID);
  }, [codeInView]);

  const handleOnClickReason = useCallback(
    (reasonCode) => {
      setOrderInView();
      const code = reasonCode === codeInView ? null : reasonCode;
      setCodeInView(code);
    },
    [codeInView],
  );

  const handleOnClickOrder = useCallback(
    (order) => {
      const newOrder = orderInView === order.InternalID ? null : order.InternalID;
      setOrderInView(newOrder);
    },
    [orderInView],
  );

  if (!reasons) return null;

  return (
    <div>
      <Label>{unassignedReasonsTrans}</Label>
      {keys(reasons).map((reasonCode) => (
        <StyledReasonContainer key={reasonCode}>
          <StyledReasonTitle onClick={() => handleOnClickReason(reasonCode)}>
            <div>
              {getSafeValue(unassignedCodes, reasonCode)} ({size(getSafeValue(reasons, reasonCode))}
              )
            </div>
            <StyledIcon iconUrl={codeInView === reasonCode ? collapseIcon : expandIcon} />
          </StyledReasonTitle>
          <StyledOrdersContainer displayDetails={codeInView === reasonCode}>
            {codeInView === reasonCode &&
              getSafeValue(reasons, reasonCode).map((order) => (
                <UnassignedOrder
                  key={order.InternalID}
                  unassignedOrder={order}
                  onClick={handleOnClickOrder}
                  showDetail={orderInView === order.InternalID}
                />
              ))}
          </StyledOrdersContainer>
        </StyledReasonContainer>
      ))}
    </div>
  );
};

export default withTranslation(UnassignedList);
