import React, { useCallback } from 'react';
import withTranslation from 'hoc/withTranslation';
import { StyledContrastPanelColumn } from '../../Wizard/Global/WizardStyledContrastPanel';
import { StyledInfoEllipsis } from '../../Wizard/Global/WizardStyled';
import NumericInput from '../../Wizard/Global/NumericInput';
import config from '../../../config/index';
import { getSafeValue } from '../../../utils/security';
import Selector from '../../Form/Selector';
import { OBJECTIVE_ACTIONS } from '../../../utils/solverConfig/config';
import { StyledAction } from '../StyledComponents';

const { maxValues, minValues } = config;

const ObjectiveFunctionsPriorityList = ({
  item,
  index,
  onClick,
  onChange,
  onDrag,
  onDrop,
  limitReached,
  onActionChange,
  translations: {
    solverConfigLabels: {
      objectiveFunctionsLabels: { functionLabels, objectiveActions },
    },
  },
}) => {
  const handleOnEnableItem = useCallback(
    (i) => {
      if (onClick) onClick(i);
    },
    [onClick],
  );

  const handleOnChangeInput = useCallback(
    (i, value) => {
      if (onChange) onChange(i, value);
    },
    [onChange],
  );

  const handleOnActionChange = useCallback(
    (i, value) => {
      if (onActionChange) onActionChange(i, value);
    },
    [onActionChange],
  );

  const isClickable = item.optional && (item.enabled || !limitReached);
  const actions = Object.entries(OBJECTIVE_ACTIONS).map(([key, value]) => ({
    value,
    key: getSafeValue(objectiveActions, key),
  }));

  return (
    <StyledContrastPanelColumn
      draggable="true"
      id={item.key}
      key={item.key}
      isEnabled={item.enabled}
      isDisabled={!item.enabled}
      actionsCount={2}
      inputSize="M"
      onDragOver={(ev) => ev.preventDefault()}
      onDrag={onDrag}
      onDrop={onDrop}
    >
      <StyledInfoEllipsis
        alt={getSafeValue(functionLabels, item.key)}
        title={getSafeValue(functionLabels, item.key)}
        onClick={() => handleOnEnableItem(index)}
        clickable={isClickable}
        hasMarginTop
      >
        {getSafeValue(functionLabels, item.key)}
      </StyledInfoEllipsis>
      <StyledAction>
        {item.threshold !== undefined && item.enabled && (
          <NumericInput
            theme="contrast"
            stepValue="0.01"
            initialValue={item.threshold}
            minValue={minValues.objectiveFunctionThreshold}
            maxValue={maxValues.objectiveFunctionThreshold}
            small
            onChange={(value) => handleOnChangeInput(index, value)}
          />
        )}
        {item.action !== undefined && item.enabled && (
          <Selector
            id="objective-action-selector"
            initialValue={item.action}
            options={actions}
            onChange={(value) => handleOnActionChange(index, value)}
            inverted
          />
        )}
      </StyledAction>
    </StyledContrastPanelColumn>
  );
};

export default withTranslation(ObjectiveFunctionsPriorityList);
