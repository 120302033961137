import React, { useState } from 'react';
import styled from '@emotion/styled';
import { fontSize } from '../../../global/variables';
import withTranslation from '../../../hoc/withTranslation';
import Pagination from './Pagination';

const { small, normal } = fontSize;

const StyledSummary = styled.div`
  margin-bottom: 0.5rem;
`;

const StyledDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  margin-right: 1rem;
  margin-top: -0.8rem;
  margin-bottom: 2rem;
`;

const StyledTitle = styled.p`
  text-transform: uppercase;
  margin: 0 0.6rem 0 0;
  font-size: ${small};
  opacity: 0.75;
  display: inline-block;
`;

const StyledDetailsText = styled.strong`
  font-size: ${normal};
  line-height: 2rem;
  display: block;
`;

const IdSearchDetails = ({
  activities,
  translations: {
    idSearchContainer: {
      sequenceNumber,
      tourIdLabel,
      vehicleIdLabel,
      locationLabel,
      timeLabel,
      unassignedJobTitle,
      reasonTitle,
      jobTypeLabel,
    },
  },
}) => {
  const [currentItem, setCurrentItem] = useState(0);

  const activity = activities[currentItem];

  return (
    <div>
      <Pagination
        numberOfPages={activities.length}
        currentItem={currentItem}
        setCurrentItem={(val) => setCurrentItem(val)}
      />
      {activity?.reasons ? (
        <StyledSummary>
          <StyledDetailContainer>
            <StyledTitle>{unassignedJobTitle}</StyledTitle>
            <StyledDetailsText>{activity?.jobId}</StyledDetailsText>
          </StyledDetailContainer>
          <StyledDetailContainer>
            <StyledTitle>{reasonTitle}</StyledTitle>
            {activity?.reasons.map((reason) => (
              <StyledDetailsText>{reason.description}</StyledDetailsText>
            ))}
          </StyledDetailContainer>
        </StyledSummary>
      ) : (
        <StyledSummary>
          <StyledDetailContainer>
            <StyledTitle>{sequenceNumber}</StyledTitle>
            <StyledDetailsText>{activity?.sequenceNo}</StyledDetailsText>
          </StyledDetailContainer>
          <StyledDetailContainer>
            <StyledTitle>{tourIdLabel}</StyledTitle>
            <StyledDetailsText>{activity?.typeId}</StyledDetailsText>
          </StyledDetailContainer>
          <StyledDetailContainer>
            <StyledTitle>{vehicleIdLabel}</StyledTitle>
            <StyledDetailsText>{activity?.vehicleId}</StyledDetailsText>
          </StyledDetailContainer>
          <StyledDetailContainer>
            <StyledTitle>{jobTypeLabel}</StyledTitle>
            <StyledDetailsText>{activity?.type}</StyledDetailsText>
          </StyledDetailContainer>
          <StyledDetailContainer>
            <StyledTitle>{locationLabel}</StyledTitle>
            <StyledDetailsText>
              {activity?.location.lat}, {activity?.location.lng}
            </StyledDetailsText>
          </StyledDetailContainer>
          <StyledDetailContainer>
            <StyledTitle>{timeLabel}</StyledTitle>
            <StyledDetailsText>
              {activity?.time.arrival} - {activity?.time.departure}
            </StyledDetailsText>
          </StyledDetailContainer>
        </StyledSummary>
      )}
    </div>
  );
};

export default withTranslation(IdSearchDetails);
