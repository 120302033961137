import { isEmpty } from 'lodash';
import L from 'leaflet';

export const AVOID_TYPES = {
  BOUNDING_BOX: 'boundingBox',
  POLYGON: 'polygon',
  ENCODED_POLYGON: 'encodedPolygon',
  AVOID_AREA: 'avoid_area',
};

export const getPolygonFromBbox = (bbox) => {
  return [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[1]],
    [bbox[2], bbox[3]],
    [bbox[0], bbox[3]],
    [bbox[0], bbox[1]],
  ];
};

export const createRandomTerritories = (bbox) => {
  const mid = (bbox[3] + bbox[1]) / 2;
  const third = (bbox[2] - bbox[0]) / 3;
  const xPoints = [bbox[0], bbox[0] + third, bbox[0] + third * 2, bbox[2]];
  const yPoints = [bbox[1], mid, bbox[3]];
  const territories = [];
  xPoints.forEach((x, iX) => {
    yPoints.forEach((y, iY) => {
      if (!xPoints[iX + 1] || !yPoints[iY + 1]) return;
      const newBbox = [x, y, xPoints[iX + 1], yPoints[iY + 1]];
      const coords = getPolygonFromBbox(newBbox);
      const index = iX * 2 + iY;
      const name = `Area${index + 1}`;
      territories.push({ coords, bbox: newBbox, index, name });
    });
  });
  return territories;
};

export const getTerritoriesGeoJSON = (...territories) => {
  const filteredTerritories = territories.flat().filter((territory) => territory);
  if (filteredTerritories.length === 0) return null;

  const features = filteredTerritories.map((territory) => ({
    type: 'Feature',
    bbox: territory.bbox,
    geometry: {
      coordinates: [territory.coords],
      type: 'Polygon',
    },
    properties: {
      index: territory.index,
      name: territory.name,
      type: territory.type,
    },
  }));

  return { features };
};

export const isJobInTerritory = (lat, lng, territory) => {
  if (!lat || !lng || !territory || !territory.coords) return false;

  const polygon = L.polygon(territory.coords);
  const m1 = L.marker([lng, lat]);
  return polygon.getBounds().contains(m1.getLatLng());
};

export const getTerritoryForLatLng = (lat, lng, territories) => {
  if (!lat || !lng || !territories || isEmpty(territories)) return null;
  return territories.find((terr) => isJobInTerritory(lat, lng, terr));
};

export const groupJobsPerTerritory = (jobs, territories, onlyWithJobs) => {
  if (isEmpty(jobs) || isEmpty(territories)) return null;

  const groups = territories.map((territory) => ({ id: territory.name, orders: [] }));
  jobs.forEach((job) => {
    const territory = getTerritoryForLatLng(job.Latitude, job.Longitude, territories);
    if (territory) {
      const group = groups.find((item) => item.id === territory.name);
      group.orders.push(job);
    }
  });
  return onlyWithJobs ? groups.filter((item) => !isEmpty(item.orders)) : groups;
};
