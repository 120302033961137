import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSolutionJson, setUserParam } from '../../../actions';
import { StyledSelectInput } from '../../Wizard/Global/WizardStyled';
import { isProdEnv } from '../../../utils/helpers';
import withTranslation from '../../../hoc/withTranslation';

const isProd = isProdEnv();
const TpaSelector = ({ user, translations }) => {
  const {
    menu: { customOption },
  } = translations;
  const dispatch = useDispatch();
  const usageContext = useSelector(({ usageContext: stateUsageContext }) => stateUsageContext);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);

  const handleSetUserParam = useCallback(
    (version) => dispatch(setUserParam({ tpaVersion: { ...user.tpaVersion, version } })),
    [dispatch, user.tpaVersion],
  );
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );

  const onChange = useCallback(
    (e) => {
      handleSetUserParam(e.target.value);
      if (e.target.value !== 'custom' && solution.json && usageContext.currentStep !== 1)
        handleSetSolutionJson({ jsonUserChange: true });
    },
    [handleSetUserParam, handleSetSolutionJson, solution.json, usageContext.currentStep],
  );

  return (
    <StyledSelectInput
      data-testid="tpVersionDropdown"
      contrast
      sizeL
      onChange={onChange}
      value={user.tpaVersion.version}
    >
      <option value="prod">TP V3</option>
      <option value="beta">TP V3 Beta</option>
      {!isProd && <option value="custom">{customOption}</option>}
    </StyledSelectInput>
  );
};

export default withTranslation(TpaSelector);
