import { getLastValidIteration } from './SolutionHelpers';
import { getTourOrders } from './OrdersHelpers';

const EARTH_RADIUS_KM = 6371.01;

export const getBoundingCoordinates = (location, distance = 10) => {
  const lat = location[1] || location.lat;
  const lng = location[0] || location.lng;
  const radDist = distance / EARTH_RADIUS_KM;
  const minLat = lat - radDist;
  const maxLat = lat + radDist;
  const minLng = lng - radDist;
  const maxLng = lng + radDist;
  return [minLng, minLat, maxLng, maxLat];
};

const getRandomNumberInBetween = (min, max) => Math.random() * (max - min) + min;

const getRandomPointInBbox = (bbox) => {
  const lat = getRandomNumberInBetween(bbox[1], bbox[3]);
  const lng = getRandomNumberInBetween(bbox[0], bbox[2]);
  return { lat, lng };
};

export const getRandomPointsAroundLocation = (location, distance, amount) => {
  const bbox = getBoundingCoordinates(location, distance);
  const positions = [location];
  while (positions.length < amount) {
    positions.push(getRandomPointInBbox(bbox));
  }
  return positions;
};

export const getGroupsPerTour = (tourData, routeIds, solution) => {
  if (!tourData || !routeIds || routeIds.length === 0 || !solution) return [];

  const lastIteration = getLastValidIteration(solution.requests[solution.show]);
  if (!lastIteration) return [];

  const request = lastIteration.request;
  const tour = tourData.tours[routeIds[0]];
  const orders = getTourOrders(tour, request);
  if (!orders || orders.length === 0) return [];

  return orders.reduce((acc, order) => {
    const group = order.Group;
    if (group && !acc.includes(group)) acc.push(group);
    return acc;
  }, []);
};
