import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recordUsageEvent, setLoader, setOrders, setOrdersFromUrl, USAGE_EVENTS } from 'actions';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, size } from 'lodash';
import { getOrdersFromDiscoverItems } from '../../../utils/GeoCoder';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';

const OrdersDemo = ({ orders, translations: { error }, oAuth, user, tourPlanner }) => {
  const dispatch = useDispatch();
  const loader = useSelector(({ loader: stateLoader }) => stateLoader);
  const handleSetLoader = useCallback((data) => dispatch(setLoader(data)), [dispatch]);
  const handleRecordUsage = useCallback((data) => dispatch(recordUsageEvent(data)), [dispatch]);
  const handleSetOrders = useCallback((items) => dispatch(setOrders(items, true)), [dispatch]);
  const handleSetOrdersFromUrl = useCallback(
    (url) => {
      dispatch(setOrdersFromUrl(url, error, orders, 'demo'));
    },
    [dispatch, error, orders],
  );

  useEffect(() => {
    if (!isEmpty(orders)) handleSetLoader({ isLoading: false });
  }, [orders, handleSetLoader]);

  const loadDemoOrders = useCallback(() => {
    if (isEmpty(orders) && !loader.isLoading && oAuth !== null) {
      handleSetLoader({ isLoading: true });
      getOrdersFromDiscoverItems('restaurant', oAuth, user, tourPlanner).then((items) => {
        if (!isEmpty(items)) {
          AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD, {
            addOrdersMode: 'demo',
            addOrdersAmount: size(items),
          });
          handleRecordUsage({ event: USAGE_EVENTS.ORDERS_ADD, mode: 'demo' });
          handleSetOrders(items);
          return;
        }

        getOrdersFromDiscoverItems('cafe', oAuth, user, tourPlanner).then((cafes) => {
          if (!isEmpty(cafes)) {
            AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD, {
              addOrdersMode: 'demo',
              addOrdersAmount: size(cafes),
            });
            handleRecordUsage({ event: USAGE_EVENTS.ORDERS_ADD, mode: 'demo' });
            handleSetOrders(cafes);
            return;
          }

          handleSetOrdersFromUrl('./resources/here-tpa-template-internal.csv');
        });
      });
    }
  }, [
    orders,
    handleSetOrders,
    handleSetLoader,
    handleSetOrdersFromUrl,
    oAuth,
    user,
    tourPlanner,
    loader,
    handleRecordUsage,
  ]);

  useEffect(() => {
    loadDemoOrders();
  }, [loadDemoOrders]);

  return <></>;
};

export default withTranslation(OrdersDemo);
