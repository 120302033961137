import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, useMap } from 'react-leaflet';
import withTranslation from 'hoc/withTranslation';
import { setTourParameter } from 'actions';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from '@emotion/styled';
import { getLocationAddress } from 'utils/GeoCoder';

const StyledLink = styled.li({
  fontSize: '14px',
  cursor: 'pointer',
  color: 'black',
  ':hover': {
    backgroundColor: '#D3D3D3',
  },
});

const StyledName = styled.span({
  display: 'block',
  color: 'black',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledList = styled.ul({
  paddingLeft: '15px',
});

const LocationSelector = ({
  oAuth,
  translations: {
    locationPopup: { addAddressAutomatically, copyToClipboard },
  },
}) => {
  const map = useMap();
  const dispatch = useDispatch();
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);

  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter(parameter)),
    [dispatch],
  );
  const [position, setPosition] = useState(null);
  const [address, setAddress] = useState('');

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(address);
    toast('Copied Address to clipboard!', {
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
    });
  };

  const onAddAddress = useCallback(() => {
    handleSetTourParameter({ customJobAddress: position, index: solution.show });
    setPosition(null);
  }, [solution.show, position]);

  useEffect(() => {
    map.on('contextmenu', (e) => {
      getLocationAddress(e.latlng, oAuth).then((location) => {
        setPosition(e.latlng);
        setAddress(location.title);
      });
    });
  }, []);

  return position ? (
    <Popup className="location-markers-popup" position={position} autoClose={false}>
      <StyledName title={address}>{address}</StyledName>
      <StyledList>
        <StyledLink onClick={onAddAddress}>{addAddressAutomatically}</StyledLink>
        <StyledLink onClick={onCopyToClipboard}>{copyToClipboard}</StyledLink>
      </StyledList>
      <ToastContainer hideProgressBar closeButton={false} />
    </Popup>
  ) : (
    <></>
  );
};

export default withTranslation(LocationSelector);
